<template>
    <div class="page page--shadow page--hehun-generating">
        <TabProgress :tabItems="[
            {
                name: '生辰信息',
                values: ['正在录入生辰信息', '正在写入生辰信息']
            },
            {
                name: '感情运数',
                values: ['正在检测感情状况', '正在分析你的正桃花、烂桃花', '正在计算你的感情指数']
            },
            {
                name: '原局姻缘',
                values: ['正在检测你的婚前、配偶性格', '正在分析你的姻缘状况', '正在分析你的婚配情况']
            }
        ]" @progressFinish="handleTabProgressFinish">
            <img width="100%" src="@/assets/hehun/images/pay_header.png" alt="Banner">
        </TabProgress>
    </div>
</template>

<script>
import TabProgress from '@/components/TabProgress.vue'

export default {
    name: 'HehunPay',
    components: {
        TabProgress
    },
    methods: {
        handleTabProgressFinish() {
            this.$router.replace({
                path: '/hehun2/pay',
                query: {
                    oid: this.$route.query.oid
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/hehun2/sass/generating.scss';
</style>