<template>
    <div class="comments">
        
    </div>
</template>

<script>
export default {
    name: 'Comments',
    props: {
        textColor: {
            type: String,
            default: "rgba(255, 255, 255, 0.8)"
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/comments.scss';
</style>