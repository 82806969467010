<template>
    <div class="scxp-info wrapper">
        <form method="get" class="form" @submit.prevent="onFormSubmit">
            <div class="bg">
                <img src="@/assets/scxp/images/form_head.png" class="form-bg">
                <img src="@/assets/scxp/images/form_body.png" class="form-bg form_body">
                <img src="@/assets/scxp/images/form_foot.png" class="form-bg">
            </div>
            <div class="title">
                <img src="@/assets/scxp/images/paipan_word1@2x.8fe28480.png" alt="请输入测算所需信息">
            </div>
            <div class="items">
                <div class="item">
                    <label>姓名</label>
                    <div class="control">
                        <input type="text" placeholder="请输入姓名（必须汉字）" v-model="name" />
                    </div>
                </div>
                <div class="item">
                    <label>性别</label>
                    <div class="control">
                        <div class="radio-wrap">
                            <input type="radio" name="gender" value="1" class="radio" id="radio1" v-model="gender" />
                            <label class="text" for="radio1">男</label>
                        </div>
                        <div class="radio-wrap">
                            <input type="radio" name="gender" value="0" class="radio" id="radio0" v-model="gender" />
                            <label class="text" for="radio0">女</label>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <label>生日</label>
                    <div class="control">
                        <input type="text" ref="datetime" id="datetime" data-toid-date="date" data-type="1"
                            placeholder="请选择出生日期" readonly="true" data-confirm="true" data-toid-hour="hour" />
                    </div>
                </div>
                <div class="commit">
                    <input type="image" :src="require('@/assets/scxp/images/paipan_btn1.59d56ef2.png')"
                        class="commit-btn" alt="提交">
                </div>
            </div>
            <input type="hidden" id="date">
            <input type="hidden" id="hour">
        </form>
        <div class="info-bottom">
            <img src="@/assets/scxp/images/ali_logo.16a574ee.png" alt="提交">
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScxpInfo',
    props: {
        msg: String
    },
    mounted: function () {
        document.title = '生辰综合详批';
        new window.ruiDatepicker().init('#datetime');
    },
    data() {
        return {
            name: '',
            gender: '1'
        }
    },
    methods: {
        isValidInput(text) {
            const chineseRegex = /^[\u4e00-\u9fa5]{2,4}$/
            return chineseRegex.test(text)
        },
        onFormSubmit() {
            if (!this.isValidInput(this.name)) {
                alert('请正确输入姓名')
                return false
            }
            const datetime = this.$refs.datetime;
            if (datetime.value.trim() === '') {
                alert('请选择出生日期')
                return false
            }
            const data = window.suanming(datetime.getAttribute("data-date"), datetime.getAttribute("data-hour"));
            data.username = this.name;
            data.gender = this.gender;
            let params = {
                name: this.name,
                gender: this.gender,
                datetime: datetime.value,
                date: datetime.getAttribute("data-date"),
                lunar_date: datetime.getAttribute("data-sdate"),
                hour: datetime.getAttribute("data-hour"),
                data: JSON.stringify(data),
                logid_url: localStorage.getItem("logid_url"),
                type: 'scxp',
                return_url: this.$options.filters.getDomain() + '/scxp/pay?oid=__OID__'
            }
            let that = this;
            this.$api.post('order/save', params).then(function (res) {
                if (res.data.success) {
                    that.$router.push({
                        path: '/scxp/pay',
                        query: {
                            oid: res.data.data.oid
                        }
                    })
                } else if (res.data.err_code) {
                    alert(res.data.err_msg);
                }
            }).catch(function (res) {
                console.log(res);
            })
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scxp/sass/info.scss';
</style>