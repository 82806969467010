<template>
    <div div class="page page--shadow page--bazi2-index">
        <MarqueeText />
        <div class="bazi2-index__header">
            <div class="bazi2-index__banner">
                <img width="100%" src="@/assets/bazi2/images/img_index_01.jpg" alt="Image01">
            </div>
            <div class="padding-l">
                <MarqueeScore text-color="#333333" highlight-color="#ff0000" highlight-text="财运报告" />
                <BaziForm :buttonImage="require('@/assets/bazi2/images/manner_01.png')" form-type="bazi2" return-url="/bazi2/pay?oid=__OID__"
                    @saveSuccess="handelSaveSuccess" />
            </div>
        </div>
        <FixedLinks />
        <div class="bazi2-index__intro">
            <ThreePart :headingImage="require('@/assets/bazi2/images/img_index_02.jpg')"
                componentClass="margin-top-l margin-bottom-m margin-horizontal-m"
                :contentImage="require('@/assets/bazi2/images/img_index_03.jpg')"
                :footingImage="require('@/assets/bazi2/images/img_index_04.jpg')">
                <template #title>
                    <h3 class="three-part-title" :style="{ color: '#542218' }">运势测算，帮你分析你的困惑</h3>
                </template>
                <div class="padding-l">
                    <img width="100%" src="@/assets/bazi2/images/img_index_05.jpg" alt="Image01">
                    <img width="100%" src="@/assets/bazi2/images/img_index_06.jpg" alt="Image02">
                </div>
            </ThreePart>
            <ThreePart :headingImage="require('@/assets/bazi2/images/img_index_02.jpg')"
                componentClass="margin-top-l margin-bottom-m margin-horizontal-m"
                :contentImage="require('@/assets/bazi2/images/img_index_03.jpg')"
                :footingImage="require('@/assets/bazi2/images/img_index_04.jpg')">
                <template #title>
                    <h3 class="three-part-title" :style="{ color: '#542218' }">老师分析，找到成功契机</h3>
                </template>
                <div class="padding-l">
                    <img width="100%" src="@/assets/bazi2/images/img_index_08.jpg" alt="Image02">
                </div>
            </ThreePart>
            <ThreePart :headingImage="require('@/assets/bazi2/images/img_index_02.jpg')"
                componentClass="margin-top-l margin-bottom-m margin-horizontal-m"
                :contentImage="require('@/assets/bazi2/images/img_index_03.jpg')"
                :footingImage="require('@/assets/bazi2/images/img_index_04.jpg')">
                <template #title>
                    <h3 class="three-part-title" :style="{ color: '#542218' }">绍禹老师介绍</h3>
                </template>
                <div class="padding-l">
                    <img width="100%" src="@/assets/bazi2/images/img_index_07.jpg" alt="Image02">
                </div>
            </ThreePart>
        </div>
        <Comments />
        <Copyright text-color="#333333" />
    </div>
</template>

<script>
import ThreePart from '@/components/ThreePart.vue'

// 浮动链接
import FixedLinks from '@/components/FixedLinks.vue'

// 顶部滚动提示
import MarqueeText from '@/components/MarqueeText.vue'

// 评论打分
import MarqueeScore from '@/components/MarqueeScore.vue'

// 提交表单
import BaziForm from '@/components/BaziForm.vue'

// 评论
import Comments from '@/components/Comments.vue'
import Copyright from '@/components/Copyright.vue'

export default {
    name: 'BaziIndex',
    components: {
        FixedLinks,
        MarqueeText,
        MarqueeScore,
        BaziForm,
        Comments,
        Copyright,
        ThreePart,
    },
    methods: {
        handelSaveSuccess(oid) {
            this.$router.push({
                path: '/bazi2/pay',
                query: {
                    oid: oid
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/bazi2/sass/index.scss';
</style>