<template>
  <div class="page page--shadow" id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapState(['webInfo'])
  },
  created() {
    this.setHtmlSize()
  },
  mounted() {
    window.addEventListener("resize", this.setHtmlSize)
    window.addEventListener("DOMContentLoaded", this.setHtmlSize)
    this.fetchWebInfo()
  },
  beforeDestroy() {
    window.removeEventListener("DOMContentLoaded", this.setHtmlSize)
    window.removeEventListener("resize", this.setHtmlSize)
  },
  methods: {
    ...mapMutations(['setWebInfo']),
    setHtmlSize() {
      let width = document.body.clientWidth
      let fontsize
      if (width <= 240) {
        fontsize = 12.8
      } else if (width >= 640) {
        fontsize = 34.1333
      } else {
        fontsize = (width / 18.75).toFixed(4)
      }
      document.getElementsByTagName("html")[0].style.fontSize = `${fontsize}px`
    },
    fetchWebInfo() {
      this.$api.post('/web/info', { url: location.href, logid_url: localStorage.getItem("logid_url") }).then(res => {
        if (res.data.success) {
          this.setWebInfo(res.data.data)
          if (res.data.data.stat_code) {
            let scriptContent = res.data.data.stat_code
            scriptContent = scriptContent.replace(/<script[^>]*>/i, '')
            scriptContent = scriptContent.replace(/<\/script>/i, '')
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.text = scriptContent
            document.head.appendChild(script)
          }
        } else {
          console.error(res.data.message)
        }
      }).catch(error => {
        console.error(error)
      }).finally(() => {
      })
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
</style>
