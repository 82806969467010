<template>
  <div div class="page page--shadow page--scys1s-index" :style="{ backgroundColor: '#f8b750' }">

    <MarqueeText />
    <div class="scys1-index__header">
      <div class="scys1-index__banner">
        <img width="100%" src="@/assets/scys/images/img_index_01.jpg" alt="Image01" />
      </div>

      <div class="padding-vertical-s padding-horizontal-l">

        <MarqueeScore text-color="#ae3817" highlight-text="生辰运势" />

      </div>
      <div class="padding-vertical-s padding-horizontal-l">
        <BaziForm :buttonImage="require('@/assets/scys/images/banner_01.png')" @saveSuccess="handelSaveSuccess" form-type="scys" />
      </div>
    </div>


    <FixedLinks />
    <div class="scysw1-index__intro">
      <ThreePart :headingImage="require('@/assets/scys/images/img_index_08.jpg')"
        componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
        :contentImage="require('@/assets/scys/images/img_index_09.jpg')"
        :footingImage="require('@/assets/scys/images/img_index_10.jpg')">
        <template #title>
          <h3 class="three-part-title" :style="{ color: '#ffc95c' }">
            解答你人生各个阶级困难
          </h3>
        </template>
        <div class="padding-l">
          <img width="100%" src="@/assets/scys/images/img_index_02.jpg" alt="Image01" />
        </div>
        <FixedLinks />
      </ThreePart>
      <FixedLinks />
      <div class="scys1-index__intro">
        <div class="padding-l">

          <img width="100%" src="@/assets/scys/images/img_index_20.jpg" alt="Image01" />
        </div>
      </div>

      <ThreePart :headingImage="require('@/assets/scys/images/img_index_08.jpg')"
        componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
        :contentImage="require('@/assets/scys/images/img_index_09.jpg')"
        :footingImage="require('@/assets/scys/images/img_index_10.jpg')">
        <template #title>
          <h3 class="three-part-title" :style="{ color: '#ffc95c' }">
            老师介绍
          </h3>
        </template>
        <div class="padding-l">
          <img width="100%" src="@/assets/scys/images/img_index_04.jpg" alt="Image02" />
        </div>
      </ThreePart>
      <ThreePart :headingImage="require('@/assets/scys/images/img_index_08.jpg')"
        componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
        :contentImage="require('@/assets/scys/images/img_index_09.jpg')"
        :footingImage="require('@/assets/scys/images/img_index_10.jpg')">
        <template #title>
          <h3 class="three-part-title" :style="{ color: '#ffc95c' }">
            揭晓你的先天命格大运
          </h3>
        </template>
        <div class="padding-l">
          <img width="100%" src="@/assets/scys/images/img_index_05.jpg" alt="Image02" />
        </div>
      </ThreePart>
      <ThreePart :headingImage="require('@/assets/scys/images/img_index_08.jpg')"
        componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
        :contentImage="require('@/assets/scys/images/img_index_09.jpg')"
        :footingImage="require('@/assets/scys/images/img_index_10.jpg')">
        <template #title>
          <h3 class="three-part-title" :style="{ color: '#ffc95c' }">
            为什么你要看流年运势
          </h3>
        </template>
        <div class="padding-l">
          <img width="100%" src="@/assets/scys/images/img_index_06.jpg" alt="Image02" />
        </div>
      </ThreePart>
      <ThreePart :headingImage="require('@/assets/scys/images/img_index_08.jpg')"
        componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
        :contentImage="require('@/assets/scys/images/img_index_09.jpg')"
        :footingImage="require('@/assets/scys/images/img_index_10.jpg')">
        <template #title>
          <h3 class="three-part-title" :style="{ color: '#ffc95c' }">
            引经据典 易学大师倾心之作
          </h3>
        </template>
        <div class="padding-l">
          <img width="100%" src="@/assets/scys/images/img_index_07.jpg" alt="Image02" />
        </div>
      </ThreePart>
    </div>
    <Comments />
    <Copyright text-color="#ae3817" />

  </div>
</template>

<script>
import ThreePart from "@/components/ThreePart.vue";

// 浮动链接
import FixedLinks from "@/components/FixedLinks.vue";

// 顶部滚动提示
import MarqueeText from "@/components/MarqueeText.vue";

// 评论打分
import MarqueeScore from "@/components/MarqueeScore.vue";

// 提交表单
import BaziForm from "@/components/BaziForm.vue";

// 评论
import Comments from "@/components/Comments.vue";
import Copyright from "@/components/Copyright.vue";

export default {
  name: "BaziIndex",
  components: {
    FixedLinks,
    MarqueeText,
    MarqueeScore,
    BaziForm,
    Comments,
    Copyright,
    ThreePart,
  },
  methods: {
    handelSaveSuccess(oid) {
      this.$router.push({
        path: "/scys/pay",
        query: {
          oid: oid,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scys/sass/index.scss";
</style>
