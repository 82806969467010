<template>
  <div class="yinyuan-index wrapper">
    <DialogPrivacy v-if="isShowDialogPrivacy" @cancel="handelDialogPrivacyCancel" @done="handelDialogPrivacyDone">
    </DialogPrivacy>
    <div class="marquee-text" v-if="webInfo.marquee_text">
      <p>
        {{ webInfo.marquee_text }}
      </p>
    </div>
    <div class="header">
      <img width="100%" src="@/assets/yinyuancs/images/bn2.png" alt="Banner">
      <img class="g1" src="@/assets/yinyuancs/images/g1.png" alt="Service no.1">
      <img class="g2" src="@/assets/yinyuancs/images/g2.png" alt="Service no.2">
      <img class="g3" src="@/assets/yinyuancs/images/g3.png" alt="Service no.3">
    </div>
    <div class="page-content">
      <div class="form">
        <div class="form-control">
          <label for="Name">您的姓名：</label>
          <input id="Name" v-model="name" type="text" autocomplete="off" placeholder="选填（可匿名）">
        </div>
        <div class="form-control">
          <label>您的性别：</label>
          <div class="radio-group">
            <span :class="{ radio: true, checked: gender == 1 }" @click="handleGender(1)">男</span>
            <span :class="{ radio: true, checked: gender == 0 }" @click="handleGender(0)">女</span>
          </div>
        </div>
        <div class="form-control">
          <label for="Birthday">出生日期：</label>
          <input ref="birthday" id="Birthday" class="date-picker" type="text" autocomplete="off" placeholder="请选择出生日期"
            data-confirm="true" data-toid-date="date1" data-toid-hour="hour1" data-hour="7" readonly>
        </div>
      </div>
      <button @click="handleSubmit">
        立即测试
        <img width="100%" src="@/assets/yinyuancs/images/cz.png" alt="cz">
      </button>
      <input type="hidden" id="date1" name="date">
      <input type="hidden" id="hour1" name="hour">
      <div class="order-query">
        <small>用户您好，订单/产品问题可通过点击页面内「客服」浮窗处理，建议保存浮窗内「客服」信息。 </small>
        <router-link :to="{ path: '/', query: { ac: 'history', state: 2 } }">历史订单查询点此入口: 查询历史订单&gt;&gt;</router-link>
      </div>
      <div class="comment">
        <div class="comment-heading">已为<b>{{ ordersCount }}</b>人测试，你也来试一试</div>
        <div class="comment-content">
          <div class="comment-list">
            <div class="comment-item" v-for="(item, index) in comments" :key="index">
              <span class="time">{{ item.time }}</span>
              <span class="area">{{ item.province }}</span>
              <span class="name">{{ item.name }}</span>
              <span class="serv">{{ item.status }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="notes">
        以上信息为用户历史行为记录，并非特指用户在n秒前进行了购买<br><br>
        需付费后方可查看结果，结果纯属娱乐仅供参考<br>
        结果内容纯属虚构，无科学依据，不可预测未来
      </div>
      <div class="agreement">
        <span class="checkbox" @click="handleAgreement">
          <img src="@/assets/yinyuancs/images/checkbox_checked.png" alt="Checkbox checked" v-if="agreementChecked">
          <img src="@/assets/yinyuancs/images/checkbox_unchecked.png" alt="Checkbox unchecked" v-else>
          提交即视为同意收集相关信息
        </span>，查看 <router-link to="/agreement/privacy">&lt;隐私协议&gt;</router-link>
      </div>
      <strong class="team">资深团队倾力打造</strong>
      <div class="copy" v-if="webInfo && webInfo.company_name">
        -- 版权信息 --
        <br>{{ webInfo.company_name }}
        <br>{{ webInfo.foot_text }}
        <p v-if="webInfo.tel">电话：{{ webInfo.tel }}</p>
        <p v-if="webInfo.address">地址：{{ webInfo.address }}</p>
      </div>
      <div class="online-time">客服在线时间：10:00-22:00</div>
    </div>
    <a class="cs" :href="liveChatUrl" target="_blank">
      <img src="@/assets/yinyuancs/images/cs.png" alt="Online service">客服
    </a>
    <a class="complain" :href="reportIssusUrl" target="_blank">
      <img src="@/assets/yinyuancs/images/complain.png" alt="complain">投诉
    </a>
    <div class="pan-wrap" v-if="isSubmitting">
      <div class="pan">
        <div class="images">
          <img class="mask" src="@/assets/yinyuancs/images/mask.png" alt="Banner">
          <img class="mask1" src="@/assets/yinyuancs/images/mask1.png" alt="Banner">
          <img class="mask2" src="@/assets/yinyuancs/images/mask2.png" alt="Banner">
          <img class="mask3" src="@/assets/yinyuancs/images/mask3.png" alt="Banner">
          <img class="mask4" src="@/assets/yinyuancs/images/mask4.png" alt="Banner">
        </div>
        <h3>正在为您生成专属报告</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DialogPrivacy from '@/components/DialogPrivacy.vue'

export default {
  name: 'YinyuancsIndex',
  components: {
    DialogPrivacy
  },
  computed: {
    ...mapState(['webInfo'])
  },
  data() {
    return {
      name: '',
      gender: 0,
      comments: [],
      ordersCount: 69736,
      ordersCountIntervalId: null,
      isSubmitting: false,
      agreementChecked: false,
      isShowDialogPrivacy: false,
      liveChatUrl: process.env.VUE_APP_LIVE_CHAT_URL,
      reportIssusUrl: process.env.VUE_APP_REPORT_ISSUS_URL,
    }
  },
  created() {
    const provinces = ['河北', '山西', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '海南', '四川', '贵州', '云南', '陕西', '甘肃', '青海', '台湾', '内蒙古', '广西', '西藏', '宁夏', '新疆', '北京', '天津', '上海', '重庆', '香港', '澳门']
    const names = ['王', '李', '张', '赵', '郭', '韩', '曹', '田', '杜', '魏', '任', '姚', '范', '石', '贾', '付', '秦', '侯', '薛', '闫', '史', '常', '陈', '黄', '吴', '林', '郑', '梁', '谢', '许', '邓', '冯', '曾', '蔡', '叶', '苏', '卢', '钟', '廖', '邱', '江', '黎', '刘', '孙', '高', '宋', '董', '于', '吕', '姜', '崔', '孟', '邵', '孔', '周', '唐', '彭', '肖', '蒋', '谭', '尹', '龙', '贺', '龚', '向', '徐', '朱', '丁', '沈', '夏', '顾', '钱', '戴', '严', '潘', '陆', '韦', '覃', '莫', '杨', '何', '罗', '袁', '雷', '程', '汪', '方', '陶', '邹', '熊', '万', '白', '郝', '金', '毛', '胡', '余', '武', '段', '马']
    const statuses = ['正在填写姓名', '成功获取结果']
    let now = Date.now() / 1000
    for (let i = 0; i < 30; i++) {
      now -= Math.floor(Math.random() * 10)
      this.comments.push({
        time: this.timeAgoInChinese(now),
        province: provinces[Math.floor(Math.random() * provinces.length)],
        name: names[Math.floor(Math.random() * names.length)] + '*'.repeat(Math.floor(Math.random() * 2) + 1),
        status: statuses[Math.floor(Math.random() * statuses.length)]
      })
    }
  },
  mounted() {
    this.ordersCountIntervalId = setInterval(() => {
      this.ordersCount += Math.floor(Math.random() * 5) + 1
    }, 1000)
    new window.ruiDatepicker().init('#Birthday')
  },
  beforeDestroy() {
    if (this.ordersCountIntervalId) {
      clearInterval(this.ordersCountIntervalId)
    }
  },
  methods: {
    handelDialogPrivacyCancel() {
      this.isShowDialogPrivacy = false
    },
    handelDialogPrivacyDone() {
      this.isShowDialogPrivacy = false
      this.agreementChecked = true
      this.handleSubmit()
    },
    handleAgreement() {
      this.agreementChecked = !this.agreementChecked
    },
    handleGender(gender) {
      this.gender = gender
    },
    isValidInput(text) {
      const chineseRegex = /^[\u4e00-\u9fa5]{2,4}$/
      return chineseRegex.test(text)
    },
    delayRedirect(options, delay) {
      setTimeout(() => {
        this.isSubmitting = false;
        this.$router.push(options);
      }, delay);
    },
    handleSubmit() {
      if (this.name.trim() !== '' && !this.isValidInput(this.name)) {
        alert('请正确输入姓名')
        return
      }
      const birthday = this.$refs.birthday
      if (birthday.value.trim() === '') {
        alert('请选择出生日期')
        return
      }
      if (!this.agreementChecked) {
        this.isShowDialogPrivacy = true
        return
      }
      this.isSubmitting = true;
      const date = birthday.getAttribute("data-date")
      let data = window.suanming(date, birthday.getAttribute("data-hour"))
      data.username = this.name
      data.gender = this.gender
      if (process.env.NODE_ENV === "development") {
        this.delayRedirect({
          path: '/yinyuancs/pay',
          query: {
            oid: 'C12407171132119572'
          }
        }, 2000);
      } else {
        this.$api.post('/order/save', {
          'name': this.name,
          'gender': this.gender,
          'date': date,
          'data': JSON.stringify(data),
          'logid_url': localStorage.getItem('logid_url'),
          'type': 'yinyuancs',
          'return_url': this.$options.filters.getDomain() + '/yinyuancs/pay?oid=__OID__'
        }).then(res => {
          if (res.data.success) {
            this.delayRedirect({
              path: '/yinyuancs/pay',
              query: {
                oid: res.data.data.oid
              }
            }, 2000)
          } else {
            alert(res.data.message)
          }
        }).catch(error => {
          alert(error)
        }).finally(() => {
        })
      }
    },
    timeAgoInChinese(timestamp) {
      const now = Date.now() / 1000
      let difference = now - timestamp
      const units = [
        { label: '年', seconds: 31536000 },
        { label: '月', seconds: 2628000 },
        { label: '天', seconds: 86400 },
        { label: '小时', seconds: 3600 },
        { label: '分钟', seconds: 60 },
        { label: '秒', seconds: 1 }
      ]
      let result = []
      for (let unit of units) {
        if (difference >= unit.seconds) {
          const count = Math.floor(difference / unit.seconds)
          difference %= unit.seconds
          result.push(`${count}${unit.label}`)
          break
        }
      }
      return `${result.join('')}前`
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/yinyuancs/sass/index.scss';
</style>