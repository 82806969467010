<template>
    <div :class="['price-with-countdown', componentClass]">
        <div class="price-with-countdown__heading">
            <del>原价：¥138</del>
            <small>限时优惠倒计时</small>
        </div>
        <div class="price-with-countdown__content">
            <div class="price">
                <dfn>吉时特价</dfn>
                <span class="money">&yen;<b>{{ orderInfo.money }}</b></span>
            </div>
            <div class="countdown">
                <span class="time">{{ countdown[0] }}</span>
                <dfn>:</dfn>
                <span class="time">{{ countdown[1] }}</span>
                <dfn>:</dfn>
                <span class="time">{{ countdown[2] }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PriceWithCountdown',
    props: {
        orderInfo: Object,
        componentClass: String
    },
    data() {
        return {
            countdown: [29, 59, 99],
            payChannels: [],
            payChannelCode: null,
            hongbaoAmounts: [],
            countdownId: null
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        startCountdown() {
            this.countdownId = setInterval(() => {
                if (this.countdown[2] > 0) {
                    this.$set(this.countdown, 2, this.countdown[2] - 1);
                } else if (this.countdown[1] > 0) {
                    this.$set(this.countdown, 1, this.countdown[1] - 1);
                    this.$set(this.countdown, 2, 99);
                } else if (this.countdown[0] > 0) {
                    this.$set(this.countdown, 0, this.countdown[0] - 1);
                    this.$set(this.countdown, 1, 59);
                    this.$set(this.countdown, 2, 99);
                }
            }, 10);
        },
        destroyCountdown() {
            if (this.countdownId) {
                clearInterval(this.countdownId)
            }
        },
        fetchData() {
            this.$api.post('/order/info', { oid: this.$route.query.oid }).then(res => {
                if (res.data.success) {
                    this.$emit('orderInfo', res.data.data.order)
                    this.payChannels = res.data.data.pay_channels
                    this.hongbaoAmounts = res.data.data.hongbao_amounts
                    this.payChannelCode = Object.keys(this.payChannels)[0]
                    this.startCountdown()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/price-with-countdown.scss';
</style>