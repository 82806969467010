<template>
    <div class="marquee-score">
        <div class="comments-list" ref="scrollContainer">
            <ul>
                <li v-for="(comment, index) in virtualComments" :key="index"
                    :class="{ 'comment-item': true, animated: isAnimating }" :style="{ color: textColor }">
                    <img :src="require(`@/assets/avatars/${comment.id}.jpg`)">
                    <p><strong>{{ comment.name }}</strong><br>
                        {{ comment.minute }}分钟前测算，打了{{ comment.score }}分好评</p>
                </li>
            </ul>
        </div>
        <div class="statistics-box">
            <div class="statistics" :style="{ color: textColor }">
                已有 <b :style="{ color: highlightColor }">228966</b> 人<br>
                领取{{ highlightText }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MarqueeScore',
    props: {
        textColor: {
            type: String,
            default: "#FFFFFF"
        },
        highlightColor: {
            type: String,
            default: "rgb(255, 255, 0)"
        },
        highlightText: {
            type: String,
            default: "合婚分析书"
        }
    },
    data() {
        return {
            comments: [
                { "id": 18, "name": "繁花似锦", "minute": "37", "score": "93" },
                { "id": 11, "name": "青石小巷", "minute": "15", "score": "91" },
                { "id": 17, "name": "风铃草", "minute": "52", "score": "95" },
                { "id": 5, "name": "星辰", "minute": "28", "score": "92" },
                { "id": 10, "name": "彩云追月", "minute": "41", "score": "94" },
                { "id": 2, "name": "梦幻", "minute": "19", "score": "97" },
                { "id": 13, "name": "水墨丹青", "minute": "8", "score": "96" },
                { "id": 1, "name": "晨曦", "minute": "59", "score": "90" },
                { "id": 9, "name": "雪舞", "minute": "33", "score": "98" },
                { "id": 20, "name": "寒梅傲雪", "minute": "21", "score": "99" },
                { "id": 7, "name": "梦回江南", "minute": "44", "score": "92" },
                { "id": 4, "name": "静思", "minute": "3", "score": "94" },
                { "id": 16, "name": "笑看红尘", "minute": "50", "score": "91" },
                { "id": 12, "name": "琴瑟和鸣", "minute": "12", "score": "93" },
                { "id": 6, "name": "悠然", "minute": "24", "score": "95" },
                { "id": 19, "name": "梦里花开", "minute": "30", "score": "96" },
                { "id": 14, "name": "花影流年", "minute": "47", "score": "97" },
                { "id": 8, "name": "琉璃月", "minute": "27", "score": "98" },
                { "id": 3, "name": "清风", "minute": "10", "score": "99" },
                { "id": 15, "name": "墨流苏", "minute": "6", "score": "90" }
            ],
            scrollInterval: null,
            isAnimating: false
        };
    },
    computed: {
        virtualComments() {
            return [...this.comments, this.comments[0]];
        }
    },
    methods: {
        startScrolling() {
            const container = this.$refs.scrollContainer;
            const ul = container.querySelector('ul');
            const itemHeight = ul.children[0].clientHeight;
            let count = 0;
            this.scrollInterval = setInterval(() => {
                this.isAnimating = true;
                setTimeout(() => {
                    if (count >= this.comments.length - 1) {
                        container.scrollTop = 0;
                        count = 0;
                    } else {
                        container.scrollTop += itemHeight;
                        count++;
                    }
                    this.isAnimating = false;
                }, 500);
            }, 3000);
        }
    },
    mounted() {
        this.startScrolling();
    },
    beforeDestroy() {
        clearInterval(this.scrollInterval);
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/marquee-score.scss';
</style>