<template>
    <div class="privacy">
        <div class="privacy__content">
            尊敬的用户：<br />
            <br />
            为说明在线测算平台（以下简称平台）会如何收集、使用和存储您的个人信息及您享有何种权利，我们将通过本协议向您阐述相关事宜，其中要点如下：<br />
            <br />
            1、我们将逐一说明我们收集的您的个人信息类型及其对应的用途，以便您了解我们针对某一特定功能所收集的具体个人信息的类别、使用理由及收集方式。<br />
            <br />
            2、
            当您使用一些功能时，我们会在获得您的同意后，收集您的一些隐私信息，例如提供测算服务时，我们需要您的姓名和生辰八字进行分析；为了保存您的分析报告结果，我们需要获取您的手机或邮箱。如拒绝提供这些信息会使您无法使用相关特定功能。<br />
            <br />
            3、目前，在线测算平台不会主动共享或转让您的个人信息至本平台以外的第三方，如存在其他共享或转让您的个人信息情形时，我们会征得您的明示同意，此外我们会对对外提供信息的行为进行风险评估。<br />
            <br />
            4、您可以通过本声明所列途径访问、更正、删除您的个人信息，也可以撤回同意、注销帐号、投诉举报。<br />
            <br />
            如您想了解更加详尽的信息，请根据以下索引阅读相应章节：<br />
            <br />
            一、 我们收集的信息<br />
            <br />
            二、信息的存储<br />
            <br />
            三、信息安全<br />
            <br />
            四、我们如何使用信息<br />
            <br />
            五、关于Cookie的使用<br />
            <br />
            六、您的权利<br />
            <br />
            七、免责声明<br />
            <br />
            八、对外提供<br />
            <br />
            九、变更<br />
            <br />
            十、其他<br />
            <br />
            十一、联系我们<br />
            <br />
            一、我们收集的信息<br />
            <br />
            在您使用在线测算服务的过程中，本平台会按照如下方式收集您在使用服务时主动提供或因为使用服务而产生的信息，用以向您提供服务、优化我们的服务以及保障您的帐号安全：<br />
            <br />
            1、当您进入某些测算服务前（较少），我们可能会获取您的微信头像、微信昵称信息，收集微信头像和昵称信息是为了帮助您完成测算报告的归属认证，保障您的报告评测结果与您的账号相关联并保留。<br />
            <br />
            2、在您体验测算服务时，我们需要获取您的姓名和生辰八字（出生日期），为了保证数据准确性，我们建议您提供真实的个人信息，若您提供的信息有错误，会对测算结果的准确性造成偏差。我们保证您的所有个人信息除了用于生成测算数据以外，不会用于其他用途。<br />
            <br />
            3、当您体验完测算服务后，您可以主动保存分析报告结果，这时候我们需要获取您的手机或邮箱，用来发送报告结果，方便你进行查看。<br />
            <br />
            二、信息的存储<br />
            <br />
            1、信息存储的地点<br />
            <br />
            我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。<br />
            <br />
            2、信息存储的期限<br />
            <br />
            一般而言，我们仅为实现目的所必需的时间保留您的个人信息，例如：微信头像和微信昵称，若您需要使用测算服务，我们需要一直保存您的微信头像和微信昵称，以保证您正常使用该服务，当您一段时间未进入网页或程序后，我们将删除相应的信息；当我们的产品或服务发生停止运营的情形时，我们将以推送微信服务消息通知、短信等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。<br />
            <br />
            三、信息安全<br />
            <br />
            我们努力为用户的信息安全提供保障，以防止信息的丢失、不当使用、未经授权访问或披露。<br />
            <br />
            我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（例如，SSL）、匿名化处理等手段来保护您的个人信息。
            我们通过不断提升的技术手段加强我们产品的安全能力，以防止您的个人信息泄露。例如，我们为了安全传输会在您的设备本地完成部分信息加密的工作。
            我们建立专门的管理制度、流程和组织以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。
            若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以公告、短信等形式告知您。<br />
            <br />
            四、 我们如何使用信息<br />
            <br />
            在现行法律法规允许的范围内，我们可能将通过某些功能所收集的信息用于我们的其他服务。例如，本平台可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：在网页或者app平台中向您展示或提供广告和促销资料，向您通告或推荐服务或产品信息，使用电子邮件，短信等方式推送其他此类根据您使用本平台服务或产品的情况所认为您可能会感兴趣的信息。<br />
            <br />
            如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。<br />
            <br />
            五、关于Cookie的使用<br />
            <br />
            为确保网页正常运转,为您提供更为便捷的访问体验,我们会在您的计算机或移动设备上存储名为 Cookie
            B的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于cookie,网页或客户端能够存储您的偏好或历史报告等数据。我们不会将Cookie用于本协议所述目的之外的任何用途。您可根据自己的偏好管理或清除移动设备（或计算机）上保存的所有
            cookie。<br />
            <br />
            但如果您这么做则会影响我们为您提供更好的用户使用体验以及更方便的服务。<br />
            <br />
            六、您的权利<br />
            <br />
            在您使用在线测算期间，为了您可以更加便捷地访问、更正、删除您的个人信息，同时保障您撤回对个人信息使用的同意的权利，我们在产品设计中为您提供了相应的操作设置，您可以参考下面的指引进行操作，此外，我们还设置了投诉举报渠道，您的意见将会得到及时的处理。<br />
            <br />
            1、删除个人信息和报告<br />
            <br />
            （1）进入测算首页，点击“查询历史订单”；<br />
            <br />
            （2）选择报告，点击删除按钮；<br />
            <br />
            （3）在提示弹窗中点击确认按钮即完成报告删除。<br />
            <br />
            2、投诉举报<br />
            <br />
            您可以通过客服反馈您的问题，我们将在第一时间回复并尽我们最大的努力解决您的问题。<br />
            <br />
            七、免责声明<br />
            <br />
            1、本平台与其他的在线使用的互联网网站一样,也会受到各种不良信息、网络安全和网络故障问题的困扰,包括但不限于：<br />
            <br />
            （1）其他用户可能会发布诈骗或虚假信息,或者发表有谩骂、诅咒、诋毁、攻击内容的,或者含有淫秽、色情、下流、反动、煽动民族仇恨等让人反感、厌恶的内容的非法言论；<br />
            <br />
            （2）其他用户可能会发布一些侵犯您或者其他第三方知识产权、肖像权、姓名权、名誉权、隐私权和/或其他合法权益的图片、照片、文字等资料；<br />
            <br />
            （3）面临着诸如黑客攻击、计算机病毒困扰、系统崩溃、网络掉线、网速缓慢、程序漏洞等问题的困扰和威胁。例如，可能存在的百度快照抓取测算结果行为，这个是百度收录机制造成，并非我们主动泄露测算结果。同时我们已在技术上防止搜索引擎收录测算结果，严格保护您的测算数据，请您放心，如您发现自己的测算结果被百度快照收录，请点击：http://help.baidu.com/webmaster/add
            提交反馈，百度方确认后会进行删除。<br />
            <br />
            2、您充分理解到：本协议上述的各种不良信息、网络安全和网络故障问题,并不是本平台所导致的问题,由此可能会造成您感到反感、恶心、呕吐等精神损害,或者给您造成其他的损失,概由您自行承担,本平台无须向您承担任何责任。<br />
            <br />
            八、对外提供<br />
            <br />
            除按法律法规规定外，我们不会主动对外公开披露所收集的个人信息，如须主动公开披露时，我们会向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。<br />
            <br />
            本平台也不会主动共享或转让您的个人信息至本平台以外的第三方，如存在其他共享或转让您的个人信息情形时，我们会征得您的明示同意，此外我们会对对外提供信息的行为进行风险评估。<br />
            <br />
            随着我们业务的持续发展，我们将按照法律法规及不低于本声明所要求的标准继续保护或要求新的控制者继续保护您的个人信息。<br />
            <br />
            九、变更<br />
            <br />
            我们可能会适时对本声明进行修订。当声明的条款发生变更时，将会在网页的重要位置上提示修改内容。请您注意，只有在您勾选同意按钮后，我们才会按照更新后的声明收集、使用、存储您的个人信息。<br />
            <br />
            十、其他<br />
            <br />
            在线测算用户隐私条款中所规定的用户权利及信息安全保障措施均适用于在线测算平台全体用户。<br />
            <br />
            十一、与我们联系<br />
            <br />
            当您有其他的投诉、建议、未成年人个人信息相关问题时，请通过平台客服与我们联系，我们将尽快审核所涉问题，并在验证您的用户身份后的3-5个工作日内予以回复。
        </div>
    </div>
</template>

<script>
export default {
    name: 'Privacy'
}
</script>

<style lang="scss" scoped>
.privacy {
    &__content {
        padding: 1rem .8rem;
        font-size: 0.8rem;
    }
}
</style>