<template>
    <div div class="page page--shadow page--hehun2-index">
        <MarqueeText />
        <div class="hehun2-index__header">
            <div class="hehun2-index__banner">
                <img width="100%" src="@/assets/hehun2/images/bg_banner.png">
                <img width="100%" src="@/assets/hehun2/images/couple_01.png" class="floating">
                <img width="100%" src="@/assets/hehun2/images/fg_banner.png" class="floating">
            </div>
            <div class="hehun2-index__form">
                <MarqueeScore />
                <HehunForm :buttonImage="require('@/assets/hehun2/images/img_btn.png')" formType="hehun2" return-url="/hehun2/pay?oid=__OID__"
                    @saveSuccess="handelSaveSuccess" />
            </div>
        </div>
        <FixedLinks />
        <div class="hehun2-index__intro">
            <img width="100%" src="@/assets/hehun2/images/img_index_01.jpg" alt="Image01">
            <img width="100%" src="@/assets/hehun2/images/img_index_02.jpg" alt="Image02">
            <img width="100%" src="@/assets/hehun2/images/img_index_03.jpg" alt="Image03">
            <img width="100%" src="@/assets/hehun2/images/img_index_04.jpg" alt="Image04">
            <img width="100%" src="@/assets/hehun2/images/img_index_05.jpg" alt="Image05">
        </div>
        <Comments />
        <Copyright />
    </div>
</template>

<script>
import FixedLinks from '@/components/FixedLinks.vue'
import MarqueeText from '@/components/MarqueeText.vue'
import MarqueeScore from '@/components/MarqueeScore.vue'
import HehunForm from '@/components/HehunForm.vue'
import Comments from '@/components/Comments.vue'
import Copyright from '@/components/Copyright.vue'

export default {
    name: 'Hehun2Index',
    components: {
        FixedLinks,
        MarqueeText,
        MarqueeScore,
        HehunForm,
        Comments,
        Copyright
    },
    methods: {
        handelSaveSuccess(oid) {
            this.$router.push({
                path: '/hehun2/generating',
                query: {
                    oid: oid
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/hehun2/sass/index.scss';
</style>