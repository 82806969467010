<template>
    <div class="tab-progress">
        <div class="tab-progress__header">
            <slot>HeaderImage</slot>
        </div>
        <div class="tab-progress__content">
            <div class="tab-progress__progress">
                <div class="tab-progress__tabs">
                    <ul>
                        <li v-for="(tabItem, index) in tabData" :key="index" :class="{ active: tabIndex == index }">
                            {{ tabItem.name }}<u></u>
                        </li>
                    </ul>
                </div>
                <div class="tab-progress__items" v-if="tabData[tabIndex]">
                    <ul>
                        <li v-for="(item, index) in tabData[tabIndex].items" :key="index">
                            <strong>{{ item.name }}</strong>
                            <div class="progress-bar"><i :style="{ width: `${item.progress}%` }"></i></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Comments',
    props: {
        tabItems: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            tabIndex: 0,
            tabData: []
        }
    },
    mounted() {
        for (let i = 0; i < this.tabItems.length; i++) {
            let items = []
            for (let n = 0; n < this.tabItems[i].values.length; n++) {
                items.push({
                    name: this.tabItems[i].values[n],
                    progress: 0
                })
            }
            this.tabData.push({
                name: this.tabItems[i].name,
                items: items
            })
        }
        console.info(this.tabData)
        this.startProgress()
    },
    methods: {
        startProgress() {
            this.progressIntervalId = setInterval(() => {
                const items = this.tabData[this.tabIndex].items
                for (let i = 0; i < items.length; i++) {
                    if (items[i].progress < 100) {
                        let progress = items[i].progress + Math.floor(Math.random() * 40) + 1
                        if (progress > 100) {
                            progress = 100
                        }
                        items[i].progress = progress
                        break
                    }
                }
                if (items[items.length - 1].progress == 100) {
                    this.tabIndex++
                    if (this.tabIndex >= this.tabData.length) {
                        this.destroyProgress()
                        this.$emit("progressFinish")
                    }
                }
            }, 200)
        },
        destroyProgress() {
            if (this.progressIntervalId != null) {
                clearInterval(this.progressIntervalId)
                this.progressIntervalId = null
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/tab-progress.scss';
</style>