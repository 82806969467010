<template>
    <form class="bazi-form" @submit.prevent="handleSubmit">
        <div class="form">
            <div class="form-control">
                <label for="Name">您的姓名：</label>
                <input id="Name" v-model="name" type="text" autocomplete="off" placeholder="选填（可匿名）">
            </div>
            <div class="form-control">
                <label>您的性别：</label>
                <div class="radio-group">
                    <span :class="{ radio: true, checked: gender == 1 }" @click="handleGender(1)">男</span>
                    <span :class="{ radio: true, checked: gender == 0 }" @click="handleGender(0)">女</span>
                </div>
            </div>
            <div class="form-control">
                <label for="Birthday">出生日期：</label>
                <input ref="birthday" id="Birthday" class="date-picker" type="text" autocomplete="off"
                    placeholder="请选择出生日期" data-confirm="true" data-toid-date="date1" data-toid-hour="hour1"
                    data-hour="7" readonly>
            </div>
        </div>
        <input type="image" class="btn btn-zoom" width="100%" :src="buttonImage">
        <input type="hidden" id="date1" name="date">
        <input type="hidden" id="hour1" name="hour">
        <CheckBoxAgreement />
    </form>
</template>

<script>
import CheckBoxAgreement from '@/components/CheckboxAgreement.vue'

export default {
    name: 'BaziForm',
    props: {
        formType: {
            type: String,
            default: "bazi"
        },
        buttonImage: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        returnUrl: {
            type: String,
            default: "/bazi/pay?oid=__OID__"
        }
    },
    components: {
        CheckBoxAgreement
    },
    data() {
        return {
            gender: 1,
            name: ''
        }
    },
    mounted() {
        new window.ruiDatepicker().init('#Birthday')
    },
    methods: {
        isValidInput(text) {
            const chineseRegex = /^[\u4e00-\u9fa5]{2,4}$/
            return chineseRegex.test(text)
        },
        handleGender(gender) {
            this.gender = gender
        },
        handleSubmit() {
            if (this.name.trim() !== '' && !this.isValidInput(this.name)) {
                alert('请正确输入姓名')
                return false
            }
            const birthday = this.$refs.birthday
            if (birthday.value.trim() === '') {
                alert('请选择出生日期')
                return false
            }
            const date = birthday.getAttribute("data-date")
            let data = window.suanming(date, birthday.getAttribute("data-hour"))
            data.username = this.name
            data.gender = this.gender
            if (process.env.NODE_ENV === "development") {
                this.$emit("saveSuccess", '241030140358851337')
            } else {
                this.$api.post('/order/save', {
                    'name': this.name,
                    'gender': this.gender,
                    'date': date,
                    'data': JSON.stringify(data),
                    'logid_url': localStorage.getItem('logid_url'),
                    'type': this.formType,
                    'return_url': this.$options.filters.getDomain() + this.returnUrl
                }).then(res => {
                    if (res.data.success) {
                        this.$emit("saveSuccess", res.data.data.oid)
                    } else {
                        alert(res.data.message)
                    }
                }).catch(error => {
                    alert(error)
                }).finally(() => {
                })
            }
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/bazi-form.scss';
</style>
