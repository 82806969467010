<template>
    <div class="scxp-index wrapper">
        <div class="head-marquee" v-if="webInfo.marquee_text">
            <div class="marquee-text">{{ webInfo.marquee_text }}</div>
        </div>
        <div class="banner">
            <div class="pan-anime">
                <div class="anime-item shiergong"></div>
                <div class="anime-item dizhi"></div>
                <div class="anime-item tiangan"></div>
                <div class="anime-item taiji"></div>
            </div>
            <div class="stars">
                <img ref="star0" class="pojun star" src="@/assets/scxp/images/star.png">
                <img ref="star1" class="pojun text" src="@/assets/scxp/images/pojun_text.png">
                <div ref="star2" class="line pojun_to_wuqu"><span></span></div>
                <img ref="star3" class="wuqu star" src="@/assets/scxp/images/star.png">
                <img ref="star4" class="wuqu text" src="@/assets/scxp/images/wuqu_text.png" />
                <div ref="star5" class="line wuqu_to_lianzhen"><span></span></div>
                <img ref="star6" class="lianzhen star" src="@/assets/scxp/images/star.png">
                <img ref="star7" class="lianzhen text" src="@/assets/scxp/images/lianzhen_text.png">
                <div ref="star8" class="line lianzhen_to_wenqu"><span></span></div>
                <img ref="star9" class="wenqu star" src="@/assets/scxp/images/star.png">
                <img ref="star10" class="wenqu text" src="@/assets/scxp/images/wenqu_text.png">
                <div ref="star11" class="line wenqu_to_lucun"><span></span></div>
                <img ref="star12" class="lucun star" src="@/assets/scxp/images/star.png">
                <img ref="star13" class="lucun text" src="@/assets/scxp/images/lucun_text.png">
                <div ref="star14" class="line lucun_to_jumen"><span></span></div>
                <img ref="star15" class="jumen star" src="@/assets/scxp/images/star.png">
                <img ref="star16" class="jumen text" src="@/assets/scxp/images/jumen_text.png">
                <div ref="star17" class="line jumen_to_tanlang"><span></span></div>
                <img ref="star18" class="tanlang star" src="@/assets/scxp/images/star.png">
                <img ref="star19" class="tanlang text" src="@/assets/scxp/images/tanlang_text.png">
                <div ref="star20" class="line tanlang_to_dixing"><span></span></div>
                <img ref="star21" class="dixing star" src="@/assets/scxp/images/star.png">
                <img ref="star22" class="dixing text" src="@/assets/scxp/images/dixing_text.png">
            </div>
        </div>
        <div class="zf-mid-follow" v-if="isComment">
            <div class="reports">
                已有 <b>2186968</b> 人<br />
                领取生辰测算报告
            </div>
            <div class="comments">
                <div class="comment">
                    <img class="avatar" src="@/assets/avatars/1.jpg" />
                    <div class="text">
                        初夏<br>
                        <small>8分钟前测试 打了100分好评</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="form" v-if="isForm">
            <div class="bg">
                <img src="@/assets/scxp/images/form_head.png" class="form-bg">
                <img src="@/assets/scxp/images/form_body.png" class="form-bg form_body">
                <img src="@/assets/scxp/images/form_foot.png" class="form-bg">
            </div>
            <div class="items">
                <div class="item">
                    <label>姓名</label>
                    <div class="control">
                        <input type="text" placeholder="请输入姓名（必须汉字）" v-model="name" />
                    </div>
                </div>
                <div class="item">
                    <label>性别</label>
                    <div class="control">
                        <div class="radio-wrap">
                            <input type="radio" name="gender" value="1" class="radio" id="radio1" v-model="gender" />
                            <label class="text" for="radio1">男</label>
                        </div>
                        <div class="radio-wrap">
                            <input type="radio" name="gender" value="0" class="radio" id="radio0" v-model="gender" />
                            <label class="text" for="radio0">女</label>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <label>生日</label>
                    <div class="control">
                        <input type="text" ref="datetime" id="datetime" data-toid-date="date" data-type="1"
                            placeholder="请选择出生日期" readonly="true" data-confirm="true" data-toid-hour="hour" />
                    </div>
                </div>
            </div>
            <input type="hidden" id="date">
            <input type="hidden" id="hour">
        </div>
        <div class="button">
            <input type="image" :src="require('@/assets/scxp/images/index_btn.0ee9cf0e.png')" class="btn"
                @click="onClick()" />
        </div>
        <div class="agt-wrap">
            <div class="agreement">
                <div class="check-wrap">
                    <input type="checkbox" class="checkbox" id="checkbox" :checked="isAgtChecked" />
                    <label for="checkbox" class="label">同意</label>
                </div>
                <router-link to="/agreement/privacy" class="agt-text">《隐私协议》</router-link>
            </div>
        </div>
        <div class="history">
            <router-link :to="{ path: '/', query: { ac: 'history', state: 2 } }">历史订单查询点此入口: 查询历史订单&gt;&gt;</router-link>
        </div>
        <div class="content">
            <img src="@/assets/scxp/images/home_1p.0f3e58f4.png" />
            <img src="@/assets/scxp/images/home_2p.08d8248c.png" />
            <img src="@/assets/scxp/images/home_3p.3eb30647.png" />
            <img src="@/assets/scxp/images/home_4p.c486ad60.png" />
        </div>
        <div class="foot">
            <div class="slogan">大师匠心打造精品在线测算</div>
            <p>订单问题如需帮助 <img class="cs" src="@/assets/ziwei/images/ic_cs.png" /> <a :href="liveChatUrl"
                    class="to_serv" target="_blank">请点击此处联系专属客服</a></p>
            <div class="copy" v-if="webInfo && webInfo.company_name">
                -- 版权信息 --
                <br>{{ webInfo.company_name }}
                <br>{{ webInfo.foot_text }}
                <p v-if="webInfo.tel">电话：{{ webInfo.tel }}</p>
                <p v-if="webInfo.address">地址：{{ webInfo.address }}</p>
            </div>
            <img class="honesty" src="@/assets/ziwei/images/honesty.6a9b09d6.png" />
        </div>
        <div class="floating-bottom" v-if="isShowButtom">
            <dl>
                <dt><router-link to="/scxp/info">立即测算</router-link></dt>
            </dl>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ScxpIndex',
    computed: {
        ...mapState(['webInfo'])
    },
    mounted: function () {
        document.title = '生辰综合详批';
        if (this.$route.query.form != undefined) {
            this.isForm = this.$route.query.form === '1'
        }
        if (this.isForm) {
            this.$nextTick(() =>
                new window.ruiDatepicker().init('#datetime')
            );
        }
        this.onPlay();
        window.addEventListener("scroll", this.handleScroll, true)
    },
    beforeDestroy () {
        window.removeEventListener("scroll", this.handleScroll, true)
        this.destroyInterval();
    },
    data() {
        return {
            liveChatUrl: process.env.VUE_APP_LIVE_CHAT_URL,
            isForm: false,
            star: -1,
            intervalID: null,
            isComment: true,
            headText: process.env.VUE_APP_MARQUEE_TEXT,
            footText: process.env.VUE_APP_FOOT_TEXT,
            companyName: process.env.VUE_APP_COMPANY_NAME,
            icp: process.env.VUE_APP_ICP,
            isAgtChecked: process.env.VUE_APP_IS_AGT_CHECKED === 'true',
            isShowButtom: false,
            name: '',
            gender: '1'
        }
    },
    methods: {
        handleScroll() {
            let scrolltop = document.documentElement.scrollTop || document.body.scrollTop
            scrolltop > 150 ? (this.isShowButtom = true) : (this.isShowButtom = false)
        },
        destroyInterval() {
            this.star = -1;
            clearInterval(this.intervalID);
            this.intervalID = null;
        },
        onPlay() {
            for (let i = 0; i <= 22; i++) {
                this.$refs['star' + i].classList.remove('show');
            }
            this.intervalID = setInterval(() => { this.onStarAnim(); }, 600);
        },
        onStarAnim() {
            this.star++;
            this.$refs['star' + this.star].classList.add('show');
            if (this.star >= 22) {
                this.star = -1;
                this.destroyInterval();
                setTimeout(() => { this.onPlay(); }, 5000);
            }
        },
        onClick() {
            if (this.isForm) {
                this.onFormSubmit()
            } else {
                this.$router.push('/scxp/info')
            }
        },
        isValidInput(text) {
            const chineseRegex = /^[\u4e00-\u9fa5]{2,4}$/
            return chineseRegex.test(text)
        },
        onFormSubmit() {
            if (!this.isValidInput(this.name)) {
                alert('请正确输入姓名')
                return false
            }
            const datetime = this.$refs.datetime;
            if (datetime.value.trim() === '') {
                alert('请选择出生日期')
                return false
            }
            const data = window.suanming(datetime.getAttribute("data-date"), datetime.getAttribute("data-hour"));
            data.username = this.name;
            data.gender = this.gender;
            let params = {
                name: this.name,
                gender: this.gender,
                datetime: datetime.value,
                date: datetime.getAttribute("data-date"),
                lunar_date: datetime.getAttribute("data-sdate"),
                hour: datetime.getAttribute("data-hour"),
                data: JSON.stringify(data),
                logid_url: localStorage.getItem("logid_url"),
                type: 'scxp',
                return_url: this.$options.filters.getDomain() + '/scxp/pay?oid=__OID__'
            }
            let that = this;
            this.$api.post('order/save', params).then(function (res) {
                if (res.data.success) {
                    that.$router.push({
                        path: '/scxp/pay',
                        query: {
                            oid: res.data.data.oid
                        }
                    })
                } else if (res.data.err_code) {
                    alert(res.data.err_msg);
                }
            }).catch(function (res) {
                console.log(res);
            })
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scxp/sass/index.scss';
</style>