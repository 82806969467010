<template>
    <div class="checkbox-agreement">
        <label :style="{ color: textColor }">
            <input type="checkbox" name="agree" value="agree"> 我同意<router-link to="/agreement/privacy"
                :style="{ color: linkColor }">&lt;隐私协议&gt;</router-link>
        </label>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'CheckBoxAgreement',
    props: {
        textColor: {
            type: String,
            default: "#ffffff"
        },
        linkColor: {
            type: String,
            default: "#ffff00"
        }
    },
    computed: {
        ...mapState(['webInfo'])
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/checkbox-agreement.scss';
</style>
