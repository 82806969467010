<template>
    <div div class="page page--shadow page--hehun2-pay padding-bottom-l">
        <HongbaoDialog ref="hongbaoDialog" />
        <ThreePart :headingImage="require('@/assets/hehun2/images/pay_section_heading.png')"
            componentClass="margin-top-l margin-bottom-l margin-horizontal-m"
            :contentImage="require('@/assets/hehun2/images/pay_section_content.png')"
            :footingImage="require('@/assets/hehun2/images/pay_section_footing.png')">
            <template #title>
                <h3 class="padding-top-s text-size-m" :style="{ color: '#8D3301' }">你们的合婚书已生成</h3>
            </template>
            <div class="padding-vertical-l padding-horizontal-l">
                <img width="100%" src="@/assets/hehun2/images/pay_img_01.png">
                <p class="padding-vertical-l">资深合婚老师根据你们的生辰八字量身定制的专属合婚书，一人仅此一份，内容媲美千元亲测，涵盖婚姻方方面面，已有1286968人购买，好评率高达
                    人购买，好评率高达98.86%！
                </p>
                <PriceWithCountdown :orderInfo="orderInfo" componentClass="margin-bottom-l" />
                <PayChannels @orderInfo="handleOrderInfo" />
            </div>
        </ThreePart>
        <ThreePart :headingImage="require('@/assets/hehun2/images/pay_section_heading.png')"
            componentClass="margin-horizontal-m"
            :contentImage="require('@/assets/hehun2/images/pay_section_content.png')"
            :footingImage="require('@/assets/hehun2/images/pay_section_footing.png')">
            <template #title>
                <h3 class="padding-top-s text-size-xs" :style="{ color: '#8D3301' }">解锁后将获得以下内容</h3>
            </template>
            <div class="padding-vertical-l padding-horizontal-l">
                <img width="100%" src="@/assets/hehun2/images/pay_intro_01.png">
                <img width="100%" src="@/assets/hehun2/images/pay_intro_02.png">
            </div>
        </ThreePart>
    </div>
</template>

<script>
import ThreePart from '@/components/ThreePart.vue'
import PriceWithCountdown from '@/components/PriceWithCountdown.vue'
import PayChannels from '@/components/PayChannels.vue'
import HongbaoDialog from '@/components/HongbaoDialog.vue'

export default {
    name: 'Hehun2Pay',
    components: {
        ThreePart,
        PriceWithCountdown,
        PayChannels,
        HongbaoDialog
    },
    data() {
        return {
            orderInfo: {}
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.$refs.hongbaoDialog.getCanLeave()) {
            next(); // 允许离开
        } else {
            next(false); // 阻止离开
        }
    },
    methods: {
        handleOrderInfo(data) {
            this.orderInfo = data
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/hehun2/sass/pay.scss';
</style>