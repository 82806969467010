<template>
  <div class="yinyuan-pay wrapper">
    <div class="yinyuan-pay__loading" v-if="isLoading">
      <div class="loading">
        <div class="loading__header">
          <img width="100%" src="@/assets/yinyuancs/images/bn2.png" alt="Banner">
        </div>
        <div class="loading__content">
          <div class="loading__progress">
            <div class="loading__tabs">
              <ul>
                <li :class="{ active: loadingTabIndex == 0 }">生辰信息<u></u></li>
                <li :class="{ active: loadingTabIndex == 1 }">感情运数<u></u></li>
                <li :class="{ active: loadingTabIndex == 2 }">原局姻缘<u></u></li>
              </ul>
            </div>
            <div class="loading__items">
              <ul>
                <li v-for="(item, index) in loadingItems" :key="index">
                  <strong>{{ item.name }}</strong>
                  <div class="progress-bar"><i :style="{ width: `${item.progress}%` }"></i></div>
                </li>
              </ul>
            </div>
          </div>
          <div class="pan">
            <div class="images">
              <img class="mask" src="@/assets/yinyuancs/images/mask.png" alt="Banner">
              <img class="mask1" src="@/assets/yinyuancs/images/mask1.png" alt="Banner">
              <img class="mask2" src="@/assets/yinyuancs/images/mask2.png" alt="Banner">
              <img class="mask3" src="@/assets/yinyuancs/images/mask3.png" alt="Banner">
              <img class="mask4" src="@/assets/yinyuancs/images/mask4.png" alt="Banner">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="yinyuan-pay__page" v-if="ready">
      <div class="yinyuan-pay__header">
        <img width="100%" src="@/assets/yinyuancs/images/bn2.png" alt="Banner">
        <div class="tips">
          <span class="a"></span>
          <span class="b"></span>
          <p>
            亲爱的<b>{{ order.data.username }}</b><br>
            通过分析你的个人姻缘，看你一生婚姻，发展状况及婚姻幸福指数。
          </p>
          <span class="c"></span>
          <span class="d"></span>
        </div>
      </div>
      <div class="yinyuan-pay__content">
        <div class="payment">
          <div class="heading">
            <h2>您的婚姻报告已生成</h2>
          </div>
          <div class="price-wrap">
            <strong>支付后你将获得：</strong>
            <ul>
              <li>你的正缘可能<i>长什么样？</i></li>
              <li>你一生可能有<i>几段婚姻？</i></li>
              <li>你和Ta能否<i>走到最后？</i></li>
              <li>额外赠：<i>365每天吉凶报告！</i></li>
            </ul>
            <div class="countdown">
              <span class="time">{{ countdown[0] }}</span>
              <dfn>:</dfn>
              <span class="time">{{ countdown[1] }}</span>
              <dfn>:</dfn>
              <span class="time">{{ countdown[2] }}</span>
            </div>
            <div class="price">
              <span class="amount">&yen; <b>{{ order.money }}</b> 元</span>
              <del class="original">原价：59.9 元</del>
            </div>
          </div>
          <div class="pay">
            <a v-for="(item, code) in pay_channels" :key="code" :class="`pay-item ${code}`" :href="item.pay_url">
              <img width="100%" :src="require(`@/assets/yinyuancs/images/${code}_dark.png`)" class="icon"
                :alt="`${code} payment`">
              {{ item.name }}
              <img width="100%" src="@/assets/yinyuancs/images/tj.png" class="tj" alt="tj" v-if="code == 'Alipay'">
            </a>
          </div>
          <p ref="pay_tips">
            <strong>已有<b>68989</b>人查看结果，你也来试一试</strong>
            <small>报告纯属娱乐结果仅供参考无科学依据</small>
            <dfn>已通过工业和信息化部ICP备案，答案仅自己可见</dfn>
          </p>
        </div>
        <div class="section">
          <div class="section__header">
            <h3>您的个人姻缘是怎样的</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、你是适合早婚还是晚婚<br>
                2、你未来的感情幸福还是痛苦<br>
                3、哪些年份容易遇到对的人
              </div>
            </div>
            <div class="section__button">
              <img width="80%" src="@/assets/yinyuancs/images/g4.63044826.png" alt="Lock" @click="handlePay">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section__header">
            <h3>您的另一半长什么样</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、Ta是高富帅/白富美吗<br>
                2、Ta的性格和为人怎样<br>
                3、在哪里能遇到真正爱你的人
              </div>
            </div>
            <div class="section__button">
              <img width="80%" src="@/assets/yinyuancs/images/g4.63044826.png" alt="Lock" @click="handlePay">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section__header">
            <h3>您性格对婚姻的影响</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、你的基本性格和爱情观念<br>
                2、导致你单身或感情不稳定的原因<br>
                3、做出哪些改变可快速脱单或复合
              </div>
            </div>
            <div class="section__button">
              <img width="80%" src="@/assets/yinyuancs/images/g4.63044826.png" alt="Lock" @click="handlePay">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section__header">
            <h3>您一生会有几段婚姻</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、你会有多少段感情经历<br>
                2、什么样的感情你能走到最后<br>
                3、会遇到烂桃花吗
              </div>
            </div>
            <div class="section__button">
              <img width="80%" src="@/assets/yinyuancs/images/g4.63044826.png" alt="Lock" @click="handlePay">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section__header">
            <h3>如何稳定婚姻中的关系</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、如何与另一半相处<br>
                2、自身有什么缺点需要注意
              </div>
            </div>
            <div class="section__button">
              <img width="80%" src="@/assets/yinyuancs/images/g4.63044826.png" alt="Lock" @click="handlePay">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section__header">
            <h3>最适合您的婚配建议</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、能跟您长相厮守的人特征<br>
                2、什么时候更适合结婚<br>
                3、做出什么改变婚姻能更幸福
              </div>
            </div>
            <div class="section__button">
              <img width="80%" src="@/assets/yinyuancs/images/g4.63044826.png" alt="Lock" @click="handlePay">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section__header">
            <h3>追爱攻略和幸福攻略</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、如何增加好桃花，脱单攻略<br>
                2、如何把握住幸福，复合办法<br>
                3、提升婚姻质量、感情甜蜜指数
              </div>
            </div>
            <div class="section__button">
              <img width="80%" src="@/assets/yinyuancs/images/g4.63044826.png" alt="Lock" @click="handlePay">
            </div>
          </div>
        </div>
        <div class="comments">
          <div class="comments__header">
            <h2>Ta们都领取了婚姻报告</h2>
          </div>
          <div class="comments__content">
            <div class="comments-list">
              <div class="comment" id="Comment1">
                <div class="comment__card">
                  你的命中正桃花有：玉门桃花、正缘桃花。<br>
                  玉门桃花：代表极富浪漫情怀，特别看重感绩对感情期盼很高，也愿意为爱情不顾一切称牲，
                </div>
                <div class="comment__info">
                  <img width="100%" class="comment__avatar" src="@/assets/avatars/2.jpg" alt="Avatar">
                  <div class="comment__details">
                    <small>1分钟 张*</small>
                    <p class="comment__content">
                      17年资不抵债后离婚，离婚带着孩子六年了。老师说我有福命，说让我照顾好老婆，老婆会养活家，对老婆不好，老婆走了就啥都没了。说的真对!
                    </p>
                  </div>
                </div>
                <div class="comment__button">
                  <img width="80%" src="@/assets/yinyuancs/images/g12.d1997d54.png" alt="Lock" @click="handlePay">
                </div>
              </div>
              <!-- <div class="comment" id="Comment2">
                            <div class="comment__card">
                                你的命中正桃花有：玉门桃花、正缘桃花。<br>
                                玉门桃花：代表极富浪漫情怀，特别看重感绩对感情期盼很高，也愿意为爱情不顾一切称牲，
                            </div>
                            <div class="comment__info">
                                <img width="100%" class="comment__avatar" src="@/assets/avatars/2.jpg" alt="Avatar">
                                <div class="comment__details">
                                    <small>1分钟 张*</small>
                                    <p class="comment__content">
                                        17年资不抵债后离婚，离婚带着孩子六年了。老师说我有福命，说让我照顾好老婆，老婆会养活家，对老婆不好，老婆走了就啥都没了。说的真对!
                                    </p>
                                </div>
                            </div>
                        </div> -->
            </div>
            <div class="comments-pagination">
              <ul>
                <li class="active" id="Dot1"></li>
                <li id="Dot2"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="floating-bottom" v-if="isFloatingBottom">
        <div class="wrapper">
          <div class="floating-bottom__header">
            <img src="@/assets/yinyuancs/images/top2.2b262def.png" alt="Header">
          </div>
          <div class="floating-bottom__content">
            <div class="floating-bottom__unlock">
              <div class="title">
                <h3>{{ order.data.username }}的姻缘分析报告已生成！</h3>
                <small>姻缘簿显示：今年你的感情可能会有重要情况！</small>
              </div>
              <div class="footer-payment">
                <div class="features">
                  <img src="@/assets/yinyuancs/images/tag2.5ccea1a1.png" alt="Feature" width="100%">
                </div>
                <span class="line"><i></i></span>
                <div class="pay">
                  <div class="price">
                    <dfn>结缘价</dfn>
                    <span class="amount">&yen; <b>{{ order.money }}</b> 元</span>
                  </div>
                  <div class="original-countdown">
                    <del>原价：59.9 元</del>
                    <div class="countdown">
                      <span class="time">{{ countdown[0] }}</span>
                      <dfn>:</dfn>
                      <span class="time">{{ countdown[1] }}</span>
                      <dfn>:</dfn>
                      <span class="time">{{ countdown[2] }}</span>
                    </div>
                  </div>
                  <div class="pay__channels">
                    <ul>
                      <li v-for="(item, code) in pay_channels" :key="code" :class="{ active: payChannelCode == code }"
                        @click="handlePayChannelItem(code)">
                        <img width="100%" :src="require(`@/assets/yinyuancs/images/${code}.png`)" class="icon"
                          :alt="`${code} payment`">{{ item.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="pay-btn">
                <img src="@/assets/yinyuancs/images/btn2.c5e2cf2c.png" width="80%" alt="Pay button" @click="handlePay">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="yinyuan-pay-hongbao" v-if="hongbaoNo == 0">
        <div class="wrapper">
          <div class="hongbao1">
            <div class="hongbao1__header">
              <strong>恭喜获得红包！</strong>
              <div class="amount">
                <h2>{{ getHongbaoAmount(0) }}</h2><dfn>元</dfn>
              </div>
              <span class="desc">可减价查看测算结果</span>
            </div>
            <div class="countdown">
              <span class="time">{{ hongbaoCountdown[0] }}</span>
              <dfn>:</dfn>
              <span class="time">{{ hongbaoCountdown[1] }}</span>
              <dfn>:</dfn>
              <span class="time">{{ hongbaoCountdown[2] }}</span>
              <span class="text">后失效</span>
            </div>
            <div class="actions">
              <b class="action-item done" @click="hongbaoPay(0)">点击立即使用</b>
              <b class="action-item cancel" @click="closeHongbao(0)">残忍拒绝</b>
            </div>
          </div>
          <div class="close">
            <img src="@/assets/yinyuancs/images/close.png" alt="Close" @click="closeHongbao(0)">
          </div>
        </div>
      </div>
      <div class="yinyuan-pay-hongbao" v-if="hongbaoNo == 1">
        <div class="wrapper">
          <div class="hongbao2">
            <div class="hongbao2__header">
              <strong>恭喜获得红包！</strong>
              <div class="amount">
                <h2>{{ getHongbaoAmount(1) }}</h2><dfn>元</dfn>
              </div>
            </div>
            <p class="desc">查看测试记过进仅需<b>{{ getHongbaoPayAmount(1) }}</b>元<br>错过没有了哦</p>
            <div class="actions">
              <b class="action-item done" @click="hongbaoPay(1)">点击领取结果</b>
              <b class="action-item cancel" @click="closeHongbao(1)">浏览更多</b>
            </div>
          </div>
          <div class="close">
            <img src="@/assets/yinyuancs/images/close.png" alt="Close" @click="closeHongbao(1)">
          </div>
        </div>
      </div>
      <div class="yinyuan-pay-hongbao" v-if="hongbaoNo == 2">
        <div class="wrapper">
          <div class="hongbao3">
            <strong>你有可能发生的<br><b>感情危机</b></strong>
            <div class="tags">
              <span class="tag">脱单困难</span>
              <span class="tag">真爱难寻</span>
              <span class="tag">感情不顺</span>
            </div>
            <div class="master-intro">
              <p>
                {{ masterName }}预测：<br>
                你2025年感情可能会有<br>
                较大变化<br>
                建议你<b>马上查看</b><br>
                2025年姻缘分析<br>
                <b>了解危机</b>，知己<br>
                知彼，百战不殆
              </p>
              <img src="@/assets/yinyuancs/images/rb3-4.19e26a9b.png" alt="Close">
            </div>
            <div class="actions">
              <b class="action-item done" @click="hongbaoPay(2)">仅需<dfn>{{ getHongbaoPayAmount(2) }}</dfn>元立即解锁</b>
            </div>
            <p class="desc">你在错过姻缘报告<br>你的号姻缘可能还要<b>在等一年</b></p>
          </div>
          <div class="close">
            <img src="@/assets/yinyuancs/images/close.png" alt="Close" @click="closeHongbao(2)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YinyuancsPay',
  data() {
    return {
      hongbaoNo: -1,
      order: {
        data: {}
      },
      pay_channels: [],
      isLoading: false,
      ready: false,
      loadingTabIndex: -1,
      loadingItems: [],
      loadingIntervalId: null,
      payStateIntervalId: null,
      countdownId: null,
      countdown: [29, 59, 99],
      hongbaoCountdownId: null,
      hongbaoCountdown: [29, 59, 99],
      payChannelCode: '',
      masterName: process.env.VUE_APP_MASTER_NAME,
      isFloatingBottom: false,
      hongbaoAmounts: null
    }
  },
  filters: {
    padZero(value) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    }
  },
  mounted() {
    this.fetchData()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true)
    this.destroyLoadingInterval();
    this.destroyCheckPayState();
    this.destroyCountdown();
    this.destroyHongbaoCountdown();
  },
  beforeRouteLeave(to, from, next) {
    if (this.hongbaoNo < this.getHongbaoCount() - 1) {
      this.hongbaoNo++
      this.showHongbao();
      next(false)
    } else {
      next()
    }
  },
  methods: {
    handleScroll() {
      0 > this.$refs.pay_tips.getBoundingClientRect().top ? (this.isFloatingBottom = true) : (this.isFloatingBottom = false)
    },
    handlePayChannelItem(code) {
      this.payChannelCode = code
    },
    handlePay() {
      location.href = this.pay_channels[this.payChannelCode].pay_url;
    },
    hongbaoPay(hongbaoIndex) {
      location.href = this.pay_channels[this.payChannelCode].pay_url + '&hongbao_amount=' + this.hongbaoAmounts[hongbaoIndex];
    },
    getHongbaoCount() {
      if (this.hongbaoAmounts && this.hongbaoAmounts.length > 0) {
        return this.hongbaoAmounts.length
      }
      return 0
    },
    getHongbaoPayAmount(index) {
      return (this.order.money - this.hongbaoAmounts[index]).toFixed(2)
    },
    getHongbaoAmount(index) {
      return this.hongbaoAmounts[index].toFixed(2)
    },
    showHongbao() {
      if (this.hongbaoNo == 0) {
        this.startHongbaoCountdown();
      } else {
        this.destroyHongbaoCountdown();
      }
    },
    startLoading() {
      this.isLoading = true
      this.loadingIntervalId = setInterval(() => {
        if (this.loadingItems.length == 0) {
          this.loadingTabIndex++
          if (this.loadingTabIndex == 0) {
            this.loadingItems = [
              { name: '正在录入生辰信息', progress: 0 },
              { name: '正在写入生辰信息', progress: 0 }
            ]
          } else if (this.loadingTabIndex == 1) {
            this.loadingItems = [
              { name: '正在检测感情状况', progress: 0 },
              { name: '正在分析你的正桃花、烂桃花', progress: 0 },
              { name: '正在计算你的感情指数', progress: 0 }
            ]
          } else if (this.loadingTabIndex == 2) {
            this.loadingItems = [
              { name: '正在检测你的婚前、配偶性格', progress: 0 },
              { name: '正在分析你的姻缘状况', progress: 0 },
              { name: '正在分析你的婚配情况', progress: 0 }
            ]
          }
        } else {
          for (let i = 0; i < this.loadingItems.length; i++) {
            if (this.loadingItems[i].progress < 100) {
              let progress = this.loadingItems[i].progress + Math.floor(Math.random() * 20) + 1
              if (progress > 100) {
                progress = 100
              }
              this.loadingItems[i].progress = progress
              break
            }
          }
          if (this.loadingItems[this.loadingItems.length - 1].progress == 100) {
            this.loadingItems = []
            if (this.loadingTabIndex == 2) {
              localStorage.setItem('oid', this.order.oid)
              this.destroyLoadingInterval()
              this.isLoading = false
              this.startCheckPayState()
            }
          }
        }
      }, 100)
    },
    startCountdown() {
      this.countdownId = setInterval(() => {
        if (this.countdown[2] > 0) {
          this.$set(this.countdown, 2, this.countdown[2] - 1);
        } else if (this.countdown[1] > 0) {
          this.$set(this.countdown, 1, this.countdown[1] - 1);
          this.$set(this.countdown, 2, 99);
        } else if (this.countdown[0] > 0) {
          this.$set(this.countdown, 0, this.countdown[0] - 1);
          this.$set(this.countdown, 1, 59);
          this.$set(this.countdown, 2, 99);
        }
      }, 10);
    },
    destroyCountdown() {
      if (this.countdownId) {
        clearInterval(this.countdownId)
      }
    },
    startHongbaoCountdown() {
      this.hongbaoCountdownId = setInterval(() => {
        if (this.hongbaoCountdown[2] > 0) {
          this.$set(this.hongbaoCountdown, 2, this.hongbaoCountdown[2] - 1);
        } else if (this.hongbaoCountdown[1] > 0) {
          this.$set(this.hongbaoCountdown, 1, this.hongbaoCountdown[1] - 1);
          this.$set(this.hongbaoCountdown, 2, 99);
        } else if (this.hongbaoCountdown[0] > 0) {
          this.$set(this.hongbaoCountdown, 0, this.hongbaoCountdown[0] - 1);
          this.$set(this.hongbaoCountdown, 1, 59);
          this.$set(this.hongbaoCountdown, 2, 99);
        }
      }, 10);
    },
    destroyHongbaoCountdown() {
      if (this.hongbaoCountdownId) {
        clearInterval(this.hongbaoCountdownId)
      }
    },
    destroyLoadingInterval() {
      if (this.loadingIntervalId) {
        clearInterval(this.loadingIntervalId)
      }
    },
    closeHongbao(num) {
      if (num >= this.getHongbaoCount() - 1) {
        this.hongbaoNo = 3
      } else {
        this.hongbaoNo = num + 1
      }
      this.showHongbao()
    },
    startCheckPayState() {
      this.ready = true
      window.addEventListener("scroll", this.handleScroll, true)
      this.startCountdown()
      this.payStateIntervalId = setInterval(() => {
        this.fetchPayState();
      }, 3000)
    },
    fetchData() {
      this.$api.post('/order/info', { oid: this.$route.query.oid }).then(res => {
        if (res.data.success) {
          this.order = res.data.data.order
          this.pay_channels = res.data.data.pay_channels
          this.hongbaoAmounts = res.data.data.hongbao_amounts
          this.payChannelCode = Object.keys(this.pay_channels)[0]
          if (localStorage.getItem("oid") == this.order.oid) {
            this.startCheckPayState()
          } else {
            this.startLoading()
          }
        } else {
          alert(res.data.message)
        }
      }).catch(error => {
        alert(error)
      }).finally(() => {
      })
    },
    destroyCheckPayState() {
      if (this.payStateIntervalId) {
        clearInterval(this.payStateIntervalId)
      }
    },
    fetchPayState() {
      this.$api.post('/order/pay_state', { oid: this.$route.query.oid }).then(res => {
        if (res.data.success) {
          if (res.data.data.status) {
            window.location.href = res.data.data.redirect_url;
            this.destroyCheckPayState()
          }
        } else {
          alert(res.data.message)
        }
      }).catch(error => {
        alert(error)
      }).finally(() => {
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/yinyuancs/sass/pay.scss';
</style>