export function gender(g) {
    if (g == 0) {
        return '女'
    } else if (g == 1) {
        return '男'
    }
    return '未知'
}

export function getDomain() {
    let protocol = window.location.protocol; // "http:" 或 "https:"
    let hostname = window.location.hostname; // "www.yourdomain.com"
    let port = window.location.port; // 可能为空，如 "8080"
    return `${protocol}//${hostname}${port ? ':' + port : ''}`;
}