<template>
    <div class="pay-channels">
        <a v-for="(item, code) in payChannels" :key="code" :class="`pay-channel ${code}`" :href="item.pay_url">
            <img width="100%" :src="require(`@/assets/yinyuancs/images/${code}_dark.png`)" class="icon"
                :alt="`${code} payment`">
            {{ item.name }}
        </a>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'PayChannels',
    props: {
        headingImage: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        contentImage: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        footingImage: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        componentClass: String
    },
    data() {
        return {
            payChannels: [],
            payChannelCode: null,
            payStateIntervalId: null,
            hongbaoAmounts: []
        }
    },
    mounted() {
        this.fetchData()
    },
    beforeDestroy() {
        this.destroyCheckPayState()
    },
    methods: {
        ...mapMutations(['setOrderInfo', 'setPayChannels', 'setPayChannelCode', 'setHongbaoAmounts']),
        fetchData() {
            this.$api.post('/order/info', { oid: this.$route.query.oid }).then(res => {
                if (res.data.success) {
                    this.$emit('orderInfo', res.data.data.order)
                    this.setOrderInfo(res.data.data.order)
                    this.payChannels = res.data.data.pay_channels
                    this.setPayChannels(res.data.data.pay_channels)
                    this.setHongbaoAmounts(res.data.data.hongbao_amounts)
                    const payChannelCode = Object.keys(this.payChannels)[0]
                    this.payChannelCode = payChannelCode
                    this.setPayChannelCode(payChannelCode)
                    this.startCheckPayState()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        fetchPayState() {
            this.$api.post('/order/pay_state', { oid: this.$route.query.oid }).then(res => {
                if (res.data.success && res.data.data.status) {
                    window.location.href = res.data.data.redirect_url
                    this.destroyCheckPayState()
                }
            })
        },
        startCheckPayState() {
            this.payStateIntervalId = setInterval(() => {
                this.fetchPayState()
            }, 3000)
        },
        destroyCheckPayState() {
            if (this.payStateIntervalId) {
                clearInterval(this.payStateIntervalId)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pay-channels.scss';
</style>