<template>
    <div class="bazijp-pay wrapper">
        <div class="main">
            <div class="mingpan">
                <table>
                    <tr>
                        <td ref="gong5">
                            <ul ref="stars5" class="stars"></ul>
                            <div ref="tgdz5" class="tgdz"></div>
                            <div ref="name5" class="name"></div>
                        </td>
                        <td ref="gong6">
                            <ul ref="stars6" class="stars"></ul>
                            <div ref="tgdz6" class="tgdz"></div>
                            <div ref="name6" class="name"></div>
                        </td>
                        <td ref="gong7">
                            <ul ref="stars7" class="stars"></ul>
                            <div ref="tgdz7" class="tgdz"></div>
                            <div ref="name7" class="name"></div>
                        </td>
                        <td ref="gong8">
                            <ul ref="stars8" class="stars"></ul>
                            <div ref="tgdz8" class="tgdz"></div>
                            <div ref="name8" class="name"></div>
                        </td>
                    </tr>
                    <tr>
                        <td ref="gong4">
                            <ul ref="stars4" class="stars"></ul>
                            <div ref="tgdz4" class="tgdz"></div>
                            <div ref="name4" class="name"></div>
                        </td>
                        <th rowspan="2" colspan="2" class="middle" ref="gong12">
                            <div ref="info0" class="info"><i>姓名：</i>{{ order.data.username }}</div>
                            <div ref="info1" class="info"><i>性别：</i>{{ order.data.gender | gender }}</div>
                            <div ref="info2" class="info"><i>出生日期：</i><br>{{ order.datetime }}</div>
                            <div class="more">
                                <div ref="info3" class="info"><i>命局：</i><u>{{ order.data.wuXingJu }}</u></div>
                                <div ref="info4" class="info"><i>命主：</i><u>{{ order.data.mingZhu }}</u></div>
                                <div ref="info5" class="info"><i>身主：</i><u>{{ order.data.shenZhu }}</u></div>
                            </div>
                        </th>
                        <td ref="gong9">
                            <ul ref="stars9" class="stars"></ul>
                            <div ref="tgdz9" class="tgdz"></div>
                            <div ref="name9" class="name"></div>
                        </td>
                    </tr>
                    <tr>
                        <td ref="gong3">
                            <ul ref="stars3" class="stars"></ul>
                            <div ref="tgdz3" class="tgdz"></div>
                            <div ref="name3" class="name"></div>
                        </td>
                        <td ref="gong10">
                            <ul ref="stars10" class="stars"></ul>
                            <div ref="tgdz10" class="tgdz"></div>
                            <div ref="name10" class="name"></div>
                        </td>
                    </tr>
                    <tr>
                        <td ref="gong2">
                            <ul ref="stars2" class="stars"></ul>
                            <div ref="tgdz2" class="tgdz"></div>
                            <div ref="name2" class="name"></div>
                        </td>
                        <td ref="gong1">
                            <ul ref="stars1" class="stars"></ul>
                            <div ref="tgdz1" class="tgdz"></div>
                            <div ref="name1" class="name"></div>
                        </td>
                        <td ref="gong0">
                            <ul ref="stars0" class="stars"></ul>
                            <div ref="tgdz0" class="tgdz"></div>
                            <div ref="name0" class="name"></div>
                        </td>
                        <td ref="gong11">
                            <ul ref="stars11" class="stars"></ul>
                            <div ref="tgdz11" class="tgdz"></div>
                            <div ref="name11" class="name"></div>
                        </td>
                    </tr>
                </table>
                <div ref="biandong" class="badge"><img ref="bianDongImage" src="@/assets/bazijp/images/biandong.png"
                       >
                </div>
                <div ref="tupo" class="badge"><img ref="tuPoImage" src="@/assets/bazijp/images/tupo.png">
                </div>
                <div ref="jiyu" class="badge"><img ref="jiYuImage" src="@/assets/bazijp/images/jiyu.png">
                </div>
            </div>
            <div class="process-box" v-if="processText">
                <strong>{{ processText }}</strong>
                <div class="process-out">
                    <div class="process-in" v-bind:style="{ width: processValue }"></div>
                </div>
            </div>
            <div class="commit" v-if="isShowCommitButton">
                <div class="tips">根据命盘显示，你2025年的感情财富事业会出现重要情况。</div>
                <input type="image" class="btn-xiangpi" :src="require('@/assets/bazijp/images/btn_xiangpi.png')"
                    @click="onShowPayDialog">
                <small @click="onBackInfo">生成信息填写错误？前往重新填写</small>
            </div>
        </div>
        <div class="content" v-if="isShowContent">
            <div class="section" @click="onShowPayDialog">
                <img src="@/assets/bazijp/images/pay_content1.png" width="100%">
            </div>
            <div class="section" @click="onShowPayDialog">
                <img src="@/assets/bazijp/images/pay_content2.png" width="100%">
            </div>
        </div>
        <div class="footer-float" v-if="shouldShowBackToTop">
            <div class="wrapper">
                <input type="image" class="btn-xiangpi" :src="require('@/assets/bazijp/images/btn_xiangpi.png')"
                    @click="onShowPayDialog">
            </div>
        </div>
        <div class="coupon-popup" v-if="couponFlag == 1">
            <div class="coupon">
                <i>恭喜您获得</i>
                <b>{{ hongbaoAmount() }}元</b>
                <strong>无门槛券</strong>
                <button @click="onClaimCoupon">立即解锁</button>
                <small>仅需{{ discountAmount() }}元即可解锁报告</small>
            </div>
        </div>
        <div class="popup-wrap" v-if="isShowPayPopup">
            <div class="mask"></div>
            <div class="popup">
                <form class="wrapper" @submit.prevent="onPay">
                    <div class="title">
                        <div class="back" @click="closePayPopup()">
                            <img src="@/assets/bazijp/images/return_arrow.png">
                        </div>
                        <strong class="name">{{ order.data.username }}的八字精批</strong>
                        <div class="close" @click="closePayPopup()">
                            <img src="@/assets/bazijp/images/close.png">
                        </div>
                    </div>
                    <div class="intro">
                        <div class="image">
                            <img :src="require('../../assets/images/master.png')" width="100%">
                        </div>
                        <div class="order-info">
                            <p><u>{{ masterName }}</u>根据你的生日量身定制的<u>专属紫微命书，一人仅此一份</u>，内容<u>娉美千元亲测，全面涵盖感情、财富、事业、大运、流年等运势</u>，已有<u>2186968</u>人购买，好评率高达<u>98.88%</u>！
                            </p>
                            <div class="info-item">姓名：{{ order.data.username }}</div>
                            <div class="info-item">性别：{{ order.data.gender | gender }}</div>
                            <div class="info-item">生日：{{ order.data.lDate }}</div>
                        </div>
                    </div>
                    <div class="pricing">
                        <div class="row">
                            <u class="original">原价：¥138</u>
                            <div class="countdown-title">限时优惠倒计时</div>
                        </div>
                        <div class="row">
                            <div class="price"><dfn>结缘价</dfn><i>&yen;</i><b v-html="orderAmount()"></b></div>
                            <div class="countdown">
                                <span class="countdown-item">{{ countdownMin }}</span>
                                <span class="countdown-colon">:</span>
                                <span class="countdown-item">{{ countdownSec }}</span>
                                <span class="countdown-colon">:</span>
                                <span class="countdown-item">{{ countdownMs }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="channels">
                        <div class="channel" v-for="(item, code) in payChannels" :key="code"
                            @click="onClickChannel(code)">
                            <img class="icon" src="@/assets/bazijp/images/weixin.png" v-if="code == 'Weixin'"
                               >
                            <img class="icon" src="@/assets/bazijp/images/alipay.png" v-else-if="code == 'Alipay'"
                               >
                            <div class="checkbox">
                                <input type="radio" :value="code" name="payChannelCode"
                                    v-model="checkedPayChannelCode">
                            </div>
                            <div class="name">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="pay-commit">
                        <input type="image" class="btn-xiangpi" :src="require('@/assets/bazijp/images/btn_xiangpi.png')">
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BzjpPay',
    data() {
        return {
            order: {
                data: {}
            },
            payChannels: [],
            checkedPayChannelCode: '',

            hongbaoAmounts: [],

            showIndex: -1,

            processText: '',
            processValue: 0,

            checkPayID: null,
            showID: null,
            countdownID: null,

            countdownMin: '00',
            countdownSec: '00',
            countdownMs: '00',

            isShowCommitButton: false,

            isShowContent: false,

            shouldShowBackToTop: false,

            isShowPayPopup: false,

            couponFlag: -1,

            shouldConfirmLeave: false,

            masterName: process.env.VUE_APP_MASTER_NAME,
            masterImage: process.env.VUE_APP_MASTER_IMAGE_PATH
        }
    },
    mounted() {
        document.title = '生辰八字精批';
        const params = {
            oid: this.$route.query.oid
        }
        let that = this;
        this.$api.post('order/info', params).then(function (res) {
            if (res.data.success) {
                that.order = res.data.data.order
                that.payChannels = res.data.data.pay_channels
                that.hongbaoAmounts = res.data.data.hongbao_amounts
                that.checkedPayChannelCode = Object.keys(res.data.data.pay_channels)[0]
                if (that.hongbaoAmounts != null && that.hongbaoAmounts.length > 0) {
                    that.couponFlag = 0
                } else {
                    that.couponFlag = -1
                }
                that.loopCheckPay()
                that.initUI()
            } else if (res.data.err_code) {
                console.log(res.data.err_msg)
            }
        }).catch(function (res) {
            console.log(res);
        })
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        this.destroyCheckPay()
        window.removeEventListener('scroll', this.handleScroll);
    },
    beforeRouteLeave(to, from, next) {
        if (this.order.status) {
            next()
        } else if (this.couponFlag >= 0 && (this.isShowPayPopup || this.couponFlag <= 1)) {
            this.closePayPopup()
            this.couponFlag = 1
            next(false)
        } else {
            next()
        }
    },
    methods: {
        handleScroll() {
            const commit = this.$el.querySelector('.mingpan');
            if (commit) {
                const commitRect = commit.getBoundingClientRect();
                this.shouldShowBackToTop = commitRect.top + commitRect.height < 0;
            }
        },
        scrollToContent() {
            const content = this.$el.querySelector('.content')
            if (content) {
                const contentRect = content.getBoundingClientRect();
                window.scrollTo({
                    top: contentRect.top,
                    behavior: 'smooth'
                })
            }
        },
        onClickChannel(code) {
            this.checkedPayChannelCode = code;
        },
        closePayPopup() {
            this.isShowPayPopup = false;
            this.destroyCountdown();
            if (this.couponFlag == 0) {
                this.couponFlag = 1
            }
        },
        onClaimCoupon() {
            this.couponFlag = 2
            this.openPayPopup()
        },
        openPayPopup() {
            this.isShowPayPopup = true
            if (this.order.expired == null) {
                this.order.expired = new Date().getTime() + (30 * 60 * 1000);
            }
            if (this.countdownID == null) {
                this.countdownID = setInterval(() => { this.showCountdownText(); }, 10);
            }
        },
        onShowPayDialog() {
            this.openPayPopup()
        },
        showCountdownText() {
            let now = new Date().getTime();
            let diffMs = this.order.expired - now;
            if (diffMs > 0) {
                let ms = Math.floor(diffMs / 10) % 100;
                let sec = Math.floor(diffMs / 1000) % 60;
                let min = Math.floor(diffMs / (1000 * 60));
                let minText = "";
                if (min < 10) {
                    minText += "0";
                }
                this.countdownMin = minText + min;
                let secText = "";
                if (sec < 10) {
                    secText += "0";
                }
                this.countdownSec = secText + sec;
                let msText = "";
                if (ms < 10) {
                    msText += "0";
                }
                this.countdownMs = msText + ms;
            } else {
                this.order.expired = null
                this.destroyCountdown();
            }
        },
        onPay() {
            let url = this.payChannels[this.checkedPayChannelCode].pay_url
            if (this.couponFlag == 2) {
                url += '&hongbao_amount=' + this.hongbaoAmounts[0]
            }
            window.location.href = url;
            return false;
        },
        onBackInfo() {
            if (this.couponFlag == 0) {
                this.couponFlag = -1
            }
            this.$router.push('info')
        },
        loopCheckPay() {
            this.checkPayID = setInterval(() => { this.checkPay() }, 3000)
        },
        destroyCountdown() {
            clearInterval(this.countdownID)
            this.countdownID = null
        },
        destroyCheckPay() {
            clearInterval(this.checkPayID)
            this.checkPayID = null
        },
        hongbaoAmount() {
            if (this.hongbaoAmounts != null && this.hongbaoAmounts.length > 0) {
                return this.hongbaoAmounts[0]
            }
            return null
        },
        discountAmount() {
            if (this.hongbaoAmounts != null && this.hongbaoAmounts.length > 0) {
                return (this.order.money - this.hongbaoAmounts[0]).toFixed(2)
            }
            return this.order.money
        },
        orderAmount() {
            let money = parseFloat(this.order.money);
            if (this.hongbaoAmounts != null && this.hongbaoAmounts.length > 0 && this.couponFlag == 2) {
                money -= this.hongbaoAmounts[0]
            }
            return money.toFixed(2)
        },
        checkPay() {
            let params = {
                oid: this.$route.query.oid
            }
            let that = this;
            this.$api.post('order/pay_state', params).then(function (res) {
                if (res.data.success) {
                    that.order.status = res.data.data.status
                    if (res.data.data.status) {
                        window.location.href = res.data.data.redirect_url;
                        that.destroyCheckPay()
                    }
                } else if (res.data.err_code) {
                    console.log(res.data.err_msg);
                }
            }).catch(function (res) {
                console.log(res);
            })
        },
        initUI() {
            let html, xing;
            for (let i = 0; i < this.order.data.pan.length; i++) {
                this.$refs['name' + i].innerHTML = this.order.data.pan[i].gong;
                this.$refs['tgdz' + i].innerHTML = this.order.data.pan[i].tianGan + this.order.data.pan[i].diZhi;
                html = '';
                for (let j = 0; j < this.order.data.pan[i].stars.length; j++) {
                    xing = this.order.data.pan[i].stars[j];
                    html += '<li>' + xing['name'];
                    if (xing['siHua'] != undefined) {
                        html += '<u>' + xing['siHua'] + '</u>';
                    }
                    html += '</li>';
                }
                this.$refs['stars' + i].innerHTML = html;
            }
            let oid = localStorage.getItem("oid");
            if (oid != this.$route.query.oid) {
                localStorage.setItem("oid", this.$route.query.oid);
                this.showID = setInterval(() => { this.showItem() }, 300)
            } else {
                this.showAll()
            }
        },
        getX(index) {
            let x;
            if (index >= 2 && index < 6) {
                x = -5.16;
            } else if (index == 1 || index == 6) {
                x = -1.1;
            } else if (index == 0 || index == 7) {
                x = 3.1;
            } else if (index >= 8 && index < 12) {
                x = 7.4;
            }
            return x;
        },
        getY(index) {
            let y;
            if (index >= 5 && index < 9) {
                y = -8;
            } else if (index == 4 || index == 9) {
                y = -3.4;
            } else if (index == 3 || index == 10) {
                y = 1.2;
            } else if ((index >= 0 && index < 3) || index == 11) {
                y = 5.7;
            }
            return y;
        },
        setImageXY: function (ref, index) {
            this.$refs[ref].style = "transform: translateX(" + this.getX(index) + "rem) translateY(" + this.getY(index) + "rem) scale(0.15);";
        },
        showAll() {
            for (let index = 0; index <= 56; index++) {
                this.contentShow(index)
            }
        },
        showItem() {
            this.showIndex++;
            this.contentShow(this.showIndex)
            if (this.showIndex >= 56) {
                clearInterval(this.showID)
                this.showID = null
            }
        },
        contentShow: function (currentIndex) {
            let index
            if (currentIndex < 13) {
                this.$refs['gong' + currentIndex].classList.value = "show"
            } else if (currentIndex < 19) {
                if (currentIndex == 13) {
                    this.processText = '1.正在输入命主信息'
                    this.processValue = '0%';
                }
                index = currentIndex % 13
                this.processValue = ((index + 1) / 6 * 100) + '%';
                this.$refs['info' + index].classList.value = "info show"
            } else if (currentIndex < 31) {
                if (currentIndex == 19) {
                    this.processText = '2.正在安命宫'
                    this.processValue = '0%';
                }
                index = (currentIndex - 7) % 12
                this.processValue = ((index + 1) / 12 * 100) + '%';
                this.$refs['name' + ((index + this.order.data.mingGongIndex) % 12)].classList.value = "name show"
            } else if (currentIndex < 43) {
                if (currentIndex == 31) {
                    this.processText = '3.正在定位紫微星与天干四化'
                    this.processValue = '0%';
                }
                index = (currentIndex - 7) % 12
                this.processValue = ((index + 1) / 12 * 100) + '%';
                this.$refs['stars' + ((index + this.order.data.ziweiGongIndex) % 12)].classList.value = "stars show"
            } else if (currentIndex <= 47) {
                if (currentIndex == 44) {
                    this.processText = '4.正在生成报告'
                    this.processValue = '0%';
                    this.$refs['biandong'].classList.value = "badge show"
                }
                if (currentIndex == 47) {
                    this.setImageXY('bianDongImage', this.order.data.bianDongIndex)
                    this.processValue = '30%';
                }
            } else if (currentIndex <= 51) {
                if (currentIndex == 48) {
                    this.$refs['tupo'].classList.value = "badge show"
                }
                if (currentIndex == 51) {
                    this.setImageXY('tuPoImage', this.order.data.tuPoIndex)
                    this.processValue = '60%';
                }
            } else if (currentIndex <= 55) {
                if (currentIndex == 52) {
                    this.$refs['jiyu'].classList.value = "badge show"
                }
                if (currentIndex == 55) {
                    this.setImageXY('jiYuImage', this.order.data.jiYuIndex)
                    this.processValue = '100%';
                }
            } else if (currentIndex <= 56) {
                this.processText = '';
                this.isShowCommitButton = true;
                this.isShowContent = true;
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/bazijp/sass/pay.scss';
</style>