<template>
    <div class="bazi-index">
        <div class="bazi-index__header">
            <div class="pan">
                <img src="@/assets/bazi/images/index_pan1.png" class="pan1">
                <img src="@/assets/bazi/images/index_pan2.png" class="pan2">
                <img src="@/assets/bazi/images/index_pan3.png" class="pan3">
                <img src="@/assets/bazi/images/index_g1.png" class="g1">
                <img src="@/assets/bazi/images/index_g2.png" class="g2">
                <img src="@/assets/bazi/images/index_g3.png" class="g3">
                <img src="@/assets/bazi/images/index_g4.png" class="g4">
            </div>
            <div class="form">
                <div class="form-control">
                    <label for="Name">您的姓名：</label>
                    <input id="Name" v-model="name" type="text" autocomplete="off" placeholder="选填（可匿名）">
                </div>
                <div class="form-control">
                    <label>您的性别：</label>
                    <div class="radio-group">
                        <span :class="{ radio: true, checked: gender == 1 }" @click="handleGender(1)">男</span>
                        <span :class="{ radio: true, checked: gender == 0 }" @click="handleGender(0)">女</span>
                    </div>
                </div>
                <div class="form-control">
                    <label for="Birthday">出生日期：</label>
                    <input ref="birthday" id="Birthday" class="date-picker" type="text" autocomplete="off"
                        placeholder="请选择出生日期" data-confirm="true" data-toid-date="Birthday_Date"
                        data-toid-hour="Birthday_Hour" data-hour="7" readonly>
                </div>
                <input type="hidden" ref="birth_date" id="Birthday_Date">
                <input type="hidden" ref="birth_hour" id="Birthday_Hour">
            </div>
            <div class="form-btn">
                <input type="image" :src="require('@/assets/bazi/images/index_btn.png')" @click="handleSubmit">
            </div>
        </div>
        <div class="images">
            <img src="@/assets/bazi/images/3_02.png" width="100%">
        </div>
        <div class="images">
            <img src="@/assets/bazi/images/3_04.png" width="100%">
            <img src="@/assets/bazi/images/3_05.png" width="100%">
            <img src="@/assets/bazi/images/3_06.png" width="100%">
        </div>
        <div class="images">
            <img src="@/assets/bazi/images/3_08.png" width="100%">
        </div>
        <div class="images">
            <img src="@/assets/bazi/images/3_10.png" width="100%">
            <img src="@/assets/bazi/images/3_11.png" width="100%">
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'BaziIndex',
    computed: {
        ...mapState(['webInfo'])
    },
    data() {
        return {
            name: '',
            gender: 0
        }
    },
    mounted() {
        new window.ruiDatepicker().init('#Birthday')
    },
    methods: {
        handleGender(gender) {
            this.gender = gender
        },
        isValidInput(text) {
            const chineseRegex = /^[\u4e00-\u9fa5]{2,4}$/
            return chineseRegex.test(text)
        },
        delayRedirect(options, delay) {
            setTimeout(() => {
                this.$router.push(options);
            }, delay);
        },
        handleSubmit() {
            if (this.name.trim() !== '' && !this.isValidInput(this.name)) {
                alert('请正确输入姓名')
                return
            }
            const birthday = this.$refs.birthday
            if (birthday.value.trim() === '') {
                alert('请选择出生日期')
                return
            }
            const date = this.$refs.birth_date.value;
            const hour = this.$refs.birth_hour.value;
            let data = window.suanming(date, hour)
            data.username = this.name
            data.gender = this.gender
            if (process.env.NODE_ENV === "development") {
                this.delayRedirect({
                    path: '/bazi/pay',
                    query: {
                        oid: '240911142827617182'
                    }
                }, 200);
            } else {
                this.$api.post('/order/save', {
                    'name': this.name,
                    'gender': this.gender,
                    'date': date,
                    'data': JSON.stringify(data),
                    'logid_url': localStorage.getItem('logid_url'),
                    'type': 'bazi',
                    'return_url': this.$options.filters.getDomain() + '/bazi/pay?oid=__OID__'
                }).then(res => {
                    if (res.data.success) {
                        this.delayRedirect({
                            path: '/bazi/pay',
                            query: {
                                oid: res.data.data.oid
                            }
                        }, 200)
                    } else {
                        alert(res.data.message)
                    }
                }).catch(error => {
                    alert(error)
                }).finally(() => {
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/bazi/sass/index.scss';
</style>