<template>
  <div class="hehun-index">
    <div class="marquee-text" v-if="webInfo.marquee_text">
      <p>
        {{ webInfo.marquee_text }}
      </p>
    </div>
    <div class="hehun-index__header">
      <img width="100%" src="@/assets/hehun/images/index_banner.png" alt="Banner">
      <div class="hehun-index__header-title">2025找到你的命中良缘</div>
      <div v-for="petal in petals" :key="petal.id" :style="petal.style" class="petal">
      </div>
      <img class="hehun-index__header-dot hehun-index__header-dot1" src="@/assets/hehun/images/g1.png"
        alt="Service no.1">
      <img class="hehun-index__header-dot hehun-index__header-dot2" src="@/assets/hehun/images/g2.png"
        alt="Service no.2">
      <img class="hehun-index__header-dot hehun-index__header-dot3" src="@/assets/hehun/images/g3.png"
        alt="Service no.3">
      <img class="hehun-index__header-dot hehun-index__header-dot4" src="@/assets/hehun/images/g4.png"
        alt="Service no.4">
    </div>
    <a class="cs" :href="liveChatUrl" target="_blank">
      <img src="@/assets/hehun/images/cs.png" alt="Online service">客服
    </a>
    <a class="complain" :href="reportIssusUrl" target="_blank">
      <img src="@/assets/hehun/images/complain.png" alt="complain">投诉
    </a>
    <form class="hehun-index__form" @submit.prevent="handleSubmit">
      <div class="hehun-index__form-heading">
        输入信息 立即合婚
      </div>
      <dl class="hehun-index__form-item">
        <dt><img src="@/assets/hehun/images/male_round.png">男<br>方</dt>
        <dd>
          <input type="text" class="form-control" placeholder="请输入姓名" v-model="maleName">
          <input type="text" ref="male_birthday" id="MaleBirthday" class="form-control date-picker" autocomplete="off"
            placeholder="请选择出生日期" data-confirm="true" data-toid-date="MaleBirthday_Date"
            data-toid-hour="MaleBirthday_Hour" data-hour="7" readonly>
        </dd>
        <input type="hidden" ref="male_birth_date" id="MaleBirthday_Date">
        <input type="hidden" ref="male_birth_hour" id="MaleBirthday_Hour">
      </dl>
      <dl class="hehun-index__form-item">
        <dt><img src="@/assets/hehun/images/female_round.png">女<br>方</dt>
        <dd>
          <input type="text" class="form-control" placeholder="请输入姓名" v-model="femaleName">
          <input type="text" ref="female_birthday" id="FemaleBirthday" class="form-control date-picker" data-type="0"
            data-confirm="true" placeholder="请选择出生日期" data-toid-date="FemaleBirthday_Date"
            data-toid-hour="FemaleBirthday_Hour" data-hour="7" readonly />
        </dd>
        <input type="hidden" ref="female_birth_date" id="FemaleBirthday_Date">
        <input type="hidden" ref="female_birth_hour" id="FemaleBirthday_Hour">
      </dl>
      <input type="image" class="btn-form" width="100%" :src="require('@/assets/hehun/images/img_index_button.png')"
        alt="Commit">
      <div class="hehun-index__form-agt">
        已经阅读并同意 <router-link to="/agreement/privacy">《隐私条款》</router-link>
      </div>
      <p class="hehun-index__form-notes">
        已经有 <b>111101人</b> 测算 <router-link :to="{ path: '/', query: { ac: 'history', state: 2 } }">查看历史订单</router-link>
      </p>
    </form>
    <div class="hehun-index__section">
      <div class="hehun-index__section-heading">
        <h3>你的婚前疑虑</h3>
      </div>
      <div class="hehun-index__section-content">
        <img src="@/assets/hehun/images/index_01.jpg">
      </div>
    </div>
    <div class="hehun-index__section">
      <div class="hehun-index__section-heading">
        <h3>为什么要合婚</h3>
      </div>
      <div class="hehun-index__section-content">
        <img src="@/assets/hehun/images/index_02.jpg">
      </div>
    </div>
    <ThreePart :headingImage="require('@/assets/hehun/images/img_index_12.jpg')"
      componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
      :contentImage="require('@/assets/hehun/images/img_index_10.jpg')"
      :footingImage="require('@/assets/hehun/images/img_index_11.jpg')">
      <template #title>
      </template>
      <div class="padding-vertical-l padding-horizontal-l">
        <img width="100%" src="@/assets/hehun/images/img_index_04.jpg" />
        <img width="100%" src="@/assets/hehun/images/img_index_07.jpg" />
        <img width="100%" src="@/assets/hehun/images/img_index_09.jpg" />
        <img width="100%" src="@/assets/hehun/images/img_index_06.jpg" />
      </div>
    </ThreePart>
    <div class="hehun-index__section">
      <div class="hehun-index__section-heading">
        <h3>婚姻解析</h3>
      </div>
      <div class="hehun-index__section-content">
        <img src="@/assets/hehun/images/index_03.jpg">
      </div>
    </div>
    <div class="hehun-index__section">
      <div class="hehun-index__section-heading">
        <h3>老师亲算指导</h3>
      </div>
      <div class="hehun-index__section-content">
        <img src="@/assets/hehun/images/index_04.jpg">
      </div>
    </div>
    <div class="site-trust">
      <img src="@/assets/hehun/images/trust-seal.png" alt="可信网站徽标">
      <img src="@/assets/hehun/images/integrity-seal.png" alt="诚信网站徽标">
    </div>
    <div class="copy" v-if="webInfo && webInfo.company_name">
      版权所有 &copy;{{ webInfo.company_name }}<br>
      {{ webInfo.foot_text }}
      <p v-if="webInfo.tel">电话：{{ webInfo.tel }}</p>
      <p v-if="webInfo.address">地址：{{ webInfo.address }}</p>
    </div>
    <div class="submitting" v-if="isSubmitting">
      <div class="submitting-container">
        <div class="submitting-images">
          <img class="mask" src="@/assets/hehun/images/mask.png" alt="Banner">
          <img class="mask1" src="@/assets/hehun/images/mask1.png" alt="Banner">
          <img class="mask2" src="@/assets/hehun/images/mask2.png" alt="Banner">
          <img class="mask3" src="@/assets/hehun/images/mask3.png" alt="Banner">
          <img class="mask4" src="@/assets/hehun/images/mask4.png" alt="Banner">
        </div>
        <h3>正在为您生成专属报告</h3>
      </div>
    </div>
    <div class="floating-bottom" v-if="shouldShowBackToTop">
      <div class="wrapper">
        <input type="image" width="100%" :src="require('@/assets/hehun/images/img_index_button.png')" alt="Commit"
          @click="toForm">
      </div>
    </div>
  </div>
</template>

<script>
import ThreePart from "@/components/ThreePart.vue";


import { mapState } from 'vuex'
export default {
  name: 'HehunIndex',
  ThreePart,
  components: {

    ThreePart,
  },
  computed: {
    ...mapState(['webInfo'])
  },
  data() {
    return {
      isSubmitting: false,
      maleName: '',
      femaleName: '',
      petals: [],
      shouldShowBackToTop: false,
      liveChatUrl: process.env.VUE_APP_LIVE_CHAT_URL,
      reportIssusUrl: process.env.VUE_APP_REPORT_ISSUS_URL,
    }
  },
  methods: {
    createPetal() {
      const startTop = Math.random() * 2
      const petalStyle = {
        left: `${(Math.random() * 30 + 60)}%`,
        '--x': `-${(Math.random() * 10)}rem`,
        top: `${startTop}rem`,
        '--y': `${(startTop + Math.random() * 10)}rem`,
        width: `${Math.random() * 0.8 + 0.2}rem`,
        height: `${Math.random() * 0.8 + 0.2}rem`,
        '--rotation': `${Math.random() * 360}deg`,
        animationDuration: `${Math.random() * 3 + 2}s`,
        '--initial-opacity': `${Math.random()}`
      }
      this.petals.push({
        id: Date.now(),
        style: petalStyle,
      })
    },
    isValidInput(text) {
      const chineseRegex = /^[\u4e00-\u9fa5]{2,4}$/
      return chineseRegex.test(text)
    },
    handleSubmit() {
      if (this.maleName.trim() !== '' && !this.isValidInput(this.maleName.trim())) {
        alert('请正确输入男方姓名')
        return
      }
      const male_birthday = this.$refs.male_birthday
      if (male_birthday.value.trim() === '') {
        alert('请选择男方出生日期')
        return
      }
      if (this.femaleName.trim() !== '' && !this.isValidInput(this.femaleName.trim())) {
        alert('请正确输入女方姓名')
        return
      }
      const female_birthday = this.$refs.female_birthday
      if (female_birthday.value.trim() === '') {
        alert('请选择女方出生日期')
        return
      }
      this.isSubmitting = true
      const male_birth_date = this.$refs.male_birth_date
      const male_birth_hour = this.$refs.male_birth_hour
      const female_birth_date = this.$refs.female_birth_date
      const female_birth_hour = this.$refs.female_birth_hour
      const data = {
        male_name: this.maleName,
        male_birth_date: male_birth_date.value,
        male_birth_hour: male_birth_hour.value,
        female_name: this.femaleName,
        female_birth_date: female_birth_date.value,
        female_birth_hour: female_birth_hour.value,
        logid_url: localStorage.getItem('logid_url'),
        type: 'hehun',
        return_url: this.$options.filters.getDomain() + '/hehun/pay?oid=__OID__'
      }
      if (process.env.NODE_ENV === "development") {
        this.delayRedirect({
          path: '/hehun/pay',
          query: {
            oid: '240909122654659598'
          }
        }, 2000);
      } else {
        this.$api.post('/order/save', data).then(res => {
          if (res.data.success) {
            this.delayRedirect({
              path: '/hehun/pay',
              query: {
                oid: res.data.data.oid
              }
            }, 2000)
          } else {
            this.isSubmitting = false
            alert(res.data.message)
          }
        }).catch(error => {
          this.isSubmitting = false
          alert(error)
        }).finally(() => {
        })
      }
    },
    delayRedirect(options, delay) {
      setTimeout(() => {
        this.isSubmitting = false;
        this.$router.push(options);
      }, delay);
    },
    handleScroll() {
      const commit = this.$el.querySelector('.btn-form');
      if (commit) {
        const commitRect = commit.getBoundingClientRect();
        this.shouldShowBackToTop = commitRect.top + commitRect.height < 0;
      }
    },
    toForm() {
      window.scroll(0, 0)
    }
  },
  mounted() {
    new window.ruiDatepicker().init('#MaleBirthday')
    new window.ruiDatepicker().init('#FemaleBirthday')
    for (let i = 0; i < 20; i++) {
      this.createPetal();
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/hehun/sass/index.scss';
</style>