<template>
  <div class="hehun-pay">
    <div class="loading" v-if="isLoading">
      <div class="loading__header">
        <img width="100%" src="@/assets/hehun/images/pay_header.png" alt="Banner">
      </div>
      <div class="loading__content">
        <div class="loading__progress">
          <div class="loading__tabs">
            <ul>
              <li :class="{ active: loadingTabIndex == 0 }">生辰信息<u></u></li>
              <li :class="{ active: loadingTabIndex == 1 }">感情运数<u></u></li>
              <li :class="{ active: loadingTabIndex == 2 }">原局姻缘<u></u></li>
            </ul>
          </div>
          <div class="loading__items">
            <ul>
              <li v-for="(item, index) in loadingItems" :key="index">
                <strong>{{ item.name }}</strong>
                <div class="progress-bar"><i :style="{ width: `${item.progress}%` }"></i></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="hehun-pay__container" v-else>
      <div class="hehun-pay__header">
        <img width="100%" src="@/assets/hehun/images/pay_header.png" alt="Banner">
      </div>
      <div class="hehun-pay__content">
        <div class="pay-info">
          <div class="bbb">
            <div class="aaa">
              <img src="@/assets/hehun/images/male.png" alt="Banner">
              <strong>{{ order.data.username ? order.data.username : '男方' }}</strong>
              <small>{{ order.data.year }}年{{ order.data.month }}月{{ order.data.day }}日</small>
            </div>
            <div class="aaa">
              <img src="@/assets/hehun/images/female.png" alt="Banner">
              <strong>{{ order.data.girl_username ? order.data.girl_username : '女方' }}</strong>
              <small>{{ order.data.girl_year }}年{{ order.data.girl_month }}月{{ order.data.girl_day }}日</small>
            </div>
          </div>
          <div class="order-no">订单编号：{{ $route.query.oid }}</div>
          <div class="price">
            <div class="money">
              <dfn>吉时特价</dfn>
              <span class="amount"><b>&yen;{{ order.money }}</b></span>
              <del class="original">原价: 59.9元</del>
            </div>
            <div class="countdown">
              <span class="time">{{ countdown[0] }}</span>
              <dfn>:</dfn>
              <span class="time">{{ countdown[1] }}</span>
              <dfn>:</dfn>
              <span class="time">{{ countdown[2] }}</span>
            </div>
          </div>
          <div class="unlock">如需解锁两人详细合婚报告，请付费查看</div>
          <div class="pay">
            <a v-for="(item, code) in pay_channels" :key="code" :class="`pay-item ${code}`" :href="item.pay_url">
              <img width="100%" :src="require(`@/assets/yinyuancs/images/${code}_dark.png`)" class="icon"
                :alt="`${code} payment`">
              {{ item.name }}
              <img width="100%" src="@/assets/yinyuancs/images/tj.png" class="tj" alt="tj" v-if="code == 'Alipay'">
            </a>
          </div>
          <p ref="pay_tips">
            <strong>已有<b>68989</b>人查看结果，你也来试一试</strong>
            <small>报告纯属娱乐结果仅供参考无科学依据</small>
            <dfn>已通过工业和信息化部ICP备案，答案仅自己可见</dfn>
          </p>
        </div>
        <div class="section">
          <div class="section__heading">
            <h3>生辰合婚稳定系数</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、你们在一起的婚姻稳定吗? <br>
                2、你未来的感情幸福还是痛苦? <br>
                3、哪些年份容易遇到对的人
              </div>
            </div>
            <div class="section__button">
              <button type="button" @click="handlePay">立即解锁</button>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section__heading">
            <h3>生辰合婚幸福指数</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、你们的婚姻是幸福还是痛苦？<br>
                2、你们的婚姻是否是感情的归宿？<br>
                3、你们的爱情观念是否相符？
              </div>
            </div>
            <div class="section__button">
              <button type="button" @click="handlePay">立即解锁</button>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section__heading">
            <h3>您和ta的互旺指数</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、你会旺ta吗？<br>
                2、ta会旺你吗？<br>
                3、你们是对方的天使合适恶魔？
              </div>
            </div>
            <div class="section__button">
              <button type="button" @click="handlePay">立即解锁</button>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section__heading">
            <h3>您和ta的子女运</h3>
          </div>
          <div class="section__content">
            <div class="section__lock">
              <div class="section__notes">
                1、你们会有多少小孩？<br>
                2、孩子是否会让你们更加幸福？<br>
                3、孩子是否是你们感情的粘合剂？
              </div>
            </div>
            <div class="section__button">
              <button type="button" @click="handlePay">立即解锁</button>
            </div>
          </div>
        </div>
        <div class="site-trust">
          <img src="@/assets/hehun/images/trust-seal.png" alt="可信网站徽标">
          <img src="@/assets/hehun/images/integrity-seal.png" alt="诚信网站徽标">
        </div>
      </div>
      <div class="hongbao-container" v-if="hongbaoNo == 0">
        <div class="wrapper">
          <div class="hongbao1">
            <div class="amount">
              &yen;<b>{{ getHongbaoAmount(0) }}</b>元
            </div>
            <div class="pay_channels">
              <div v-for="(item, code) in pay_channels" :key="code"
                :class="{ 'pay_channel': true, active: code == payChannelCode }" @click="handlePayChannel(code)">
                <img :src="require(`@/assets/hehun/images/floating_bottom_${code}.png`)" class="icon"
                  :alt="`${code} payment`">
                {{ item.name }}
              </div>
            </div>
            <button type="button">立即解锁</button>
            <p class="notes">仅需{{ getHongbaoPayAmount(0) }}元即可解锁报告</p>
          </div>
          <div class="close">
            <img src="@/assets/yinyuancs/images/close.png" alt="Close" @click="closeHongbao(0)">
          </div>
        </div>
      </div>
      <div class="hongbao-container" v-else-if="hongbaoNo == 1">
        <div class="wrapper">
          <div class="hongbao2">
            <div class="amount">
              &yen;<b>{{ getHongbaoAmount(1) }}</b>元
            </div>
            <p class="notes">仅需{{ getHongbaoPayAmount(1) }}元即可解锁报告</p>
            <div class="pay_channels">
              <div v-for="(item, code) in pay_channels" :key="code"
                :class="{ 'pay_channel': true, active: code == payChannelCode }" @click="handlePayChannel(code)">
                <img :src="require(`@/assets/hehun/images/floating_bottom_${code}.png`)" class="icon"
                  :alt="`${code} payment`">
                {{ item.name }}
              </div>
            </div>
            <button type="button">立即解锁</button>
          </div>
          <div class="close">
            <img src="@/assets/yinyuancs/images/close.png" alt="Close" @click="closeHongbao(1)">
          </div>
        </div>
      </div>
      <div class="hongbao-container" v-else-if="hongbaoNo == 2">
        <div class="wrapper">
          <div class="hongbao3">
            <div class="amount">
              &yen;<b>{{ getHongbaoAmount(2) }}</b>
            </div>
            <p class="notes">仅需{{ getHongbaoPayAmount(2) }}元即可解锁报告</p>
            <div class="pay_channels">
              <div v-for="(item, code) in pay_channels" :key="code"
                :class="{ 'pay_channel': true, active: code == payChannelCode }" @click="handlePayChannel(code)">
                <img :src="require(`@/assets/hehun/images/floating_bottom_${code}.png`)" class="icon"
                  :alt="`${code} payment`">
                {{ item.name }}
              </div>
            </div>
            <input type="image" :src="require('@/assets/hehun/images/hongbao3_btn.png')">
          </div>
          <div class="close">
            <img src="@/assets/yinyuancs/images/close.png" alt="Close" @click="closeHongbao(2)">
          </div>
        </div>
      </div>
      <div class="floating-bottom" v-if="isFloatingBottom">
        <div class="floating-bottom__container wrapper">
          <div class="floating-bottom__border">
            <img src="@/assets/hehun/images/pay_floating_bottom_master.png" class="master">
            <h3>你和ta的合婚报告已生成</h3>
            <strong>姻缘薄显示：二位婚姻有重要情况！</strong>
            <div class="money">
              <dfn>吉时特价</dfn>
              <span class="amount"><b>&yen;{{ order.money }}</b></span>
            </div>
            <div class="countdown">
              <del class="original">原价: 59.9元</del>
              <span class="time">{{ countdown[0] }}</span>
              <dfn>:</dfn>
              <span class="time">{{ countdown[1] }}</span>
              <dfn>:</dfn>
              <span class="time">{{ countdown[2] }}</span>
            </div>
            <div class="tags">
              <span class="tag">婚姻特点</span>
              <span class="tag">感情运势</span>
              <span class="tag">子女缘分</span>
              <span class="tag">老师建议</span>
            </div>
            <div class="pay_channels">
              <div v-for="(item, code) in pay_channels" :key="code"
                :class="{ 'pay_channel': true, active: code == payChannelCode }" @click="handlePayChannel(code)">
                <img :src="require(`@/assets/hehun/images/floating_bottom_${code}.png`)" class="icon"
                  :alt="`${code} payment`">
                {{ item.name }}
              </div>
            </div>
            <button type="button" @click="handlePay">立即解锁</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HehunPay',
  data() {
    return {
      countdown: [29, 59, 99],
      isLoading: false,
      loadingTabIndex: -1,
      loadingItems: [],
      loadingIntervalId: null,
      pay_channels: [],
      petals: [],
      isFloatingBottom: false,
      hongbaoAmounts: [5, 10, 20],
      hongbaoNo: -1,
      order: {
        data: {}
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true)
    this.destroyLoadingInterval();
    this.destroyCheckPayState();
  },
  beforeRouteLeave(to, from, next) {
    if (this.hongbaoNo < this.getHongbaoCount() - 1) {
      this.hongbaoNo++
      next(false)
    } else {
      next()
    }
  },
  methods: {
    startLoading() {
      this.isLoading = true
      this.loadingIntervalId = setInterval(() => {
        if (this.loadingItems.length == 0) {
          this.loadingTabIndex++
          if (this.loadingTabIndex == 0) {
            this.loadingItems = [
              { name: '正在录入生辰信息', progress: 0 },
              { name: '正在写入生辰信息', progress: 0 }
            ]
          } else if (this.loadingTabIndex == 1) {
            this.loadingItems = [
              { name: '正在检测感情状况', progress: 0 },
              { name: '正在分析你的正桃花、烂桃花', progress: 0 },
              { name: '正在计算你的感情指数', progress: 0 }
            ]
          } else if (this.loadingTabIndex == 2) {
            this.loadingItems = [
              { name: '正在检测你的婚前、配偶性格', progress: 0 },
              { name: '正在分析你的姻缘状况', progress: 0 },
              { name: '正在分析你的婚配情况', progress: 0 }
            ]
          }
        } else {
          for (let i = 0; i < this.loadingItems.length; i++) {
            if (this.loadingItems[i].progress < 100) {
              let progress = this.loadingItems[i].progress + Math.floor(Math.random() * 40) + 1
              if (progress > 100) {
                progress = 100
              }
              this.loadingItems[i].progress = progress
              break
            }
          }
          if (this.loadingItems[this.loadingItems.length - 1].progress == 100) {
            this.loadingItems = []
            if (this.loadingTabIndex == 2) {
              localStorage.setItem('oid', this.order.oid)
              this.destroyLoadingInterval()
              this.isLoading = false
              this.startCheckPayState()
            }
          }
        }
      }, 200)
    },
    destroyLoadingInterval() {
      if (this.loadingIntervalId) {
        clearInterval(this.loadingIntervalId)
      }
    },
    fetchData() {
      this.$api.post('/order/info', { oid: this.$route.query.oid }).then(res => {
        if (res.data.success) {
          this.order = res.data.data.order
          this.pay_channels = res.data.data.pay_channels
          this.hongbaoAmounts = res.data.data.hongbao_amounts
          this.payChannelCode = Object.keys(this.pay_channels)[0]
          if (localStorage.getItem("oid") == this.order.oid) {
            this.startCheckPayState()
          } else {
            this.startLoading()
          }
        } else {
          alert(res.data.message)
        }
      }).catch(error => {
        alert(error)
      }).finally(() => {
      })
    },
    startCheckPayState() {
      this.isLoading = false
      window.addEventListener("scroll", this.handleScroll, true)
      this.startCountdown()
      this.payStateIntervalId = setInterval(() => {
        this.fetchPayState();
      }, 3000)
    },
    destroyCheckPayState() {
      if (this.payStateIntervalId) {
        clearInterval(this.payStateIntervalId)
      }
    },
    fetchPayState() {
      this.$api.post('/order/pay_state', { oid: this.$route.query.oid }).then(res => {
        if (res.data.success) {
          if (res.data.data.status) {
            window.location.href = res.data.data.redirect_url;
            this.destroyCheckPayState()
          }
        } else {
          alert(res.data.message)
        }
      }).catch(error => {
        alert(error)
      }).finally(() => {
      })
    },
    startCountdown() {
      this.countdownId = setInterval(() => {
        if (this.countdown[2] > 0) {
          this.$set(this.countdown, 2, this.countdown[2] - 1);
        } else if (this.countdown[1] > 0) {
          this.$set(this.countdown, 1, this.countdown[1] - 1);
          this.$set(this.countdown, 2, 99);
        } else if (this.countdown[0] > 0) {
          this.$set(this.countdown, 0, this.countdown[0] - 1);
          this.$set(this.countdown, 1, 59);
          this.$set(this.countdown, 2, 99);
        }
      }, 10);
    },
    destroyCountdown() {
      if (this.countdownId) {
        clearInterval(this.countdownId)
      }
    },
    handleScroll() {
      0 > this.$refs.pay_tips.getBoundingClientRect().top ? (this.isFloatingBottom = true) : (this.isFloatingBottom = false)
    },
    handlePay() {
      location.href = this.pay_channels[this.payChannelCode].pay_url
    },
    getHongbaoPayAmount(index) {
      var ret_val = this.order.money - this.hongbaoAmounts[index]
      if (ret_val <= 0) {
        ret_val = 0.01
      }
      return ret_val.toFixed(2)
    },
    getHongbaoAmount(index) {
      return this.hongbaoAmounts[index]
    },
    closeHongbao(num) {
      if (num >= this.getHongbaoCount() - 1) {
        this.hongbaoNo = 3
      } else {
        this.hongbaoNo = num + 1
      }
    },
    hongbaoPay(hongbaoIndex) {
      location.href = this.pay_channels[this.payChannelCode].pay_url + '&hongbao_amount=' + this.hongbaoAmounts[hongbaoIndex];
    },
    getHongbaoCount() {
      if (this.hongbaoAmounts && this.hongbaoAmounts.length > 0) {
        return this.hongbaoAmounts.length
      }
      return 0
    },
    handlePayChannel(code) {
      this.payChannelCode = code
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/hehun/sass/pay.scss';
</style>