<template>
  <div
    div
    class="page page--shadow page--hehun3-index"
    :style="{ backgroundColor: '#f48bb4' }"
  >
    <MarqueeText />
    <div class="hehun3-index__header">
      <div class="hehun3-index__banner">
        <img
          width="100%"
          src="@/assets/hehun3/images/img_index_04.jpg"
          alt="Image01"
        />
      </div>
      <div class="padding-l">
        <MarqueeScore />
        <HehunForm
          :buttonImage="require('@/assets/hehun3/images/btn.f63299ec.png')" form-type="hehun3" return-url="/hehun3/pay?oid=__OID__"
          @saveSuccess="handelSaveSuccess"
        />
      </div>
    </div>
    <FixedLinks />
    <div class="hehun3-index__intro">
      <ThreePart
        :headingImage="require('@/assets/hehun3/images/img_index_01.jpg')"
        componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
        :contentImage="require('@/assets/hehun3/images/img_index_02.jpg')"
        :footingImage="require('@/assets/hehun3/images/img_index_03.jpg')"
      >
        <template #title>
          <h3 class="padding-top-m text-size-m" :style="{ color: '#ffffff' }">
            专业老师分析
          </h3>
        </template>
        <div class="padding-l">
          <img
            width="100%"
            src="@/assets/hehun3/images/img_index_06.jpg"
            alt="Image01"
          />
          <img
            width="100%"
            src="@/assets/hehun3/images/img_index_07.jpg"
            alt="Image02"
          />
        </div>
      </ThreePart>
      <ThreePart
        :headingImage="require('@/assets/hehun3/images/img_index_01.jpg')"
        componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
        :contentImage="require('@/assets/hehun3/images/img_index_02.jpg')"
        :footingImage="require('@/assets/hehun3/images/img_index_03.jpg')"
      >
        <template #title>
          <h3 class="padding-top-m text-size-m" :style="{ color: '#ffffff' }">
            哪些人需要看姻缘
          </h3>
        </template>
        <div class="padding-l">
          <img
            width="100%"
            src="@/assets/hehun3/images/img_index_08.jpg"
            alt="Image02"
          />
        </div>
      </ThreePart>
      <ThreePart
        :headingImage="require('@/assets/hehun3/images/img_index_01.jpg')"
        componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
        :contentImage="require('@/assets/hehun3/images/img_index_02.jpg')"
        :footingImage="require('@/assets/hehun3/images/img_index_03.jpg')"
      >
        <template #title>
          <h3 class="padding-top-m text-size-m" :style="{ color: '#ffffff' }">
            绍禹老师介绍
          </h3>
        </template>
        <div class="padding-l">
          <img
            width="100%"
            src="@/assets/hehun3/images/img_index_09.jpg"
            alt="Image02"
          />
        </div>
      </ThreePart>
      <ThreePart
        :headingImage="require('@/assets/hehun3/images/img_index_01.jpg')"
        componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
        :contentImage="require('@/assets/hehun3/images/img_index_02.jpg')"
        :footingImage="require('@/assets/hehun3/images/img_index_03.jpg')"
      >
        <template #title>
          <h3 class="padding-top-m text-size-m" :style="{ color: '#ffffff' }">
            看穿你和他的缘分深浅
          </h3>
        </template>
        <div class="padding-l">
          <img
            width="100%"
            src="@/assets/hehun3/images/img_index_10.jpg"
            alt="Image02"
          />
        </div>
      </ThreePart>
      <ThreePart
        :headingImage="require('@/assets/hehun3/images/img_index_01.jpg')"
        componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
        :contentImage="require('@/assets/hehun3/images/img_index_02.jpg')"
        :footingImage="require('@/assets/hehun3/images/img_index_03.jpg')"
      >
        <template #title>
          <h3 class="padding-top-m text-size-m" :style="{ color: '#ffffff' }">
            引经据典 易学大师倾心之作
          </h3>
        </template>
        <div class="padding-l">
          <img
            width="100%"
            src="@/assets/hehun3/images/img_index_12.jpg"
            alt="Image02"
          />
        </div>
      </ThreePart>
    </div>
    <Comments />
    <Copyright />
  </div>
</template>

<script>
import ThreePart from "@/components/ThreePart.vue";

// 浮动链接
import FixedLinks from "@/components/FixedLinks.vue";

// 顶部滚动提示
import MarqueeText from "@/components/MarqueeText.vue";

// 评论打分
import MarqueeScore from "@/components/MarqueeScore.vue";

// 提交表单
import HehunForm from "@/components/HehunForm.vue";

// 评论
import Comments from "@/components/Comments.vue";
import Copyright from "@/components/Copyright.vue";

export default {
  name: "Hehun3Index",
  components: {
    FixedLinks,
    MarqueeText,
    MarqueeScore,
    HehunForm,
    Comments,
    Copyright,
    ThreePart,
  },
  methods: {
    handelSaveSuccess(oid) {
      this.$router.push({
        path: "/hehun3/pay",
        query: {
          oid: oid,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/hehun3/sass/index.scss";
</style>
