<template>
    <div class="copyright" v-if="webInfo && webInfo.company_name" :style="{ color: textColor }">
        版权所有 &copy; {{ webInfo.company_name }}<br>
        {{ webInfo.foot_text }}
        <p v-if="webInfo.tel">电话：{{ webInfo.tel }}</p>
        <p v-if="webInfo.address">地址：{{ webInfo.address }}</p>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Copyright',
    props: {
        textColor: {
            type: String,
            default: "rgba(255, 255, 255, 0.8)"
        }
    },
    computed: {
        ...mapState(['webInfo'])
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/copyright.scss';
</style>