<template>
  <div class="page page--shadow page--scys1-index">
    <div class="scys1-index__intro">
     <img
        width="100%"
        src="@/assets/scys/images/img_index_11.jpg"
        alt="Image01"
      />
    <HongbaoDialog ref="hongbaoDialog" />
    <div class="padding-vertical-s padding-horizontal-l">
      <div class="padding-horizontal-l">

      <PriceWithCountdown
        :orderInfo="orderInfo"
        />
    </div>
  </div>

 <div class="padding-vertical-s padding-horizontal-l">
  <div class="padding-vertical-s padding-horizontal-l">

<PayChannels @orderInfo="handleOrderInfo" />
    </div>
    </div>
  </div>

    <ThreePart
      :headingImage="require('@/assets/scys/images/img_index_17.jpg')"
      componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
      :contentImage="require('@/assets/scys/images/img_index_18.jpg')"
      :footingImage="require('@/assets/scys/images/img_index_19.jpg')"
    >
      <template #title>
        <h3 class="three-part-title" :style="{ color: '#ffc95c' }">
          购买测算后你将获得
        </h3>
      </template>
      <div class="padding-vertical-l padding-horizontal-l">

        <img width="100%" src="@/assets/scys/images/img_index_12.jpg" />
        <img width="100%" src="@/assets/scys/images/img_index_13.jpg" />
        <img width="100%" src="@/assets/scys/images/img_index_14.jpg" />
        <img width="100%" src="@/assets/scys/images/img_index_15.jpg" />
        <img width="100%" src="@/assets/scys/images/img_index_16.jpg" />
      </div>
    </ThreePart>
  </div>
</template>

<script>
import ThreePart from "@/components/ThreePart.vue";
import PriceWithCountdown from "@/components/PriceWithCountdown.vue";
import PayChannels from "@/components/PayChannels.vue";
import HongbaoDialog from "@/components/HongbaoDialog.vue";

export default {
  name: "Scys1Pay",
  components: {
    ThreePart,
    PriceWithCountdown,
    PayChannels,
    HongbaoDialog,
  },
  data() {
    return {
      orderInfo: {},
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.hongbaoDialog.getCanLeave()) {
      next(); // 允许离开
    } else {
      next(false); // 阻止离开
    }
  },
  methods: {
    handleOrderInfo(data) {
      this.orderInfo = data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scys/sass/pay.scss";
</style>
