<template>
    <div class="bazi-pay">
        <div class="bazi-pay__header">
            <img src="@/assets/bazi/images/pay_top.png" class="top_image">
            <p class="desc">
                {{ order.data.username }}的流年运势报告<br>
                欠缺五行：五行缺土<br>
                姻缘财禄贵荣，尽在终生运势报告
            </p>
            <div class="pay-info">
                <h3>{{ order.data.username }} 购买后你将获得</h3>
                <img src="@/assets/bazi/images/icon_group.png" width="100%" class="icon_group">
                <p class="intro">
                    2025年为时代转折之年，属相为马的你行运 已有变动之相，命局中将有几个关键时间点值得注意...
                </p>
                <div class="money">
                    <div class="price">
                        <del class="original">原价：59.9 元</del>
                        <span class="amount">&yen; <b>{{ order.money }}</b> 元</span>
                    </div>
                    <div class="countdown">
                        <div class="countdown__heading">限时优惠倒计时</div>
                        <div class="countdown__content">
                            <span class="time">{{ countdown[0] }}</span>
                            <dfn>:</dfn>
                            <span class="time">{{ countdown[1] }}</span>
                            <dfn>:</dfn>
                            <span class="time">{{ countdown[2] }}</span>
                        </div>
                    </div>
                </div>
                <div class="pay">
                    <a v-for="(item, code) in pay_channels" :key="code" :class="`pay-item ${code}`"
                        :href="item.pay_url">
                        <img width="100%" :src="require(`@/assets/yinyuancs/images/${code}_dark.png`)" class="icon"
                            :alt="`${code} payment`">
                        {{ item.name }}
                        <img width="100%" src="@/assets/yinyuancs/images/tj.png" class="tj" alt="tj"
                            v-if="code == 'Alipay'">
                    </a>
                </div>
                <p ref="pay_tips">
                    <strong>已有<b>68989</b>人查看结果，你也来试一试</strong>
                    <small>报告纯属娱乐结果仅供参考无科学依据</small>
                    <dfn>已通过工业和信息化部ICP备案，答案仅自己可见</dfn>
                </p>
            </div>
        </div>

        <div class="images">
            <img src="@/assets/bazi/images/pay_02.png" width="100%">
            <img src="@/assets/bazi/images/pay_03.png" width="100%">
            <img src="@/assets/bazi/images/pay_04.png" width="100%">
        </div>
    </div>
</template>

<script>
export default {
    name: 'HehunPay',
    data() {
        return {
            countdown: [29, 59, 99],
            isLoading: false,
            loadingTabIndex: -1,
            loadingItems: [],
            loadingIntervalId: null,
            pay_channels: [],
            petals: [],
            isFloatingBottom: false,
            hongbaoAmounts: [5, 10, 20],
            hongbaoNo: -1,
            order: {
                data: {}
            }
        }
    },
    mounted() {
        this.fetchData()
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll, true)
        this.destroyLoadingInterval();
        this.destroyCheckPayState();
    },
    beforeRouteLeave(to, from, next) {
        if (this.hongbaoNo < this.getHongbaoCount() - 1) {
            this.hongbaoNo++
            next(false)
        } else {
            next()
        }
    },
    methods: {
        startLoading() {
            this.isLoading = true
            this.loadingIntervalId = setInterval(() => {
                if (this.loadingItems.length == 0) {
                    this.loadingTabIndex++
                    if (this.loadingTabIndex == 0) {
                        this.loadingItems = [
                            { name: '正在录入生辰信息', progress: 0 },
                            { name: '正在写入生辰信息', progress: 0 }
                        ]
                    } else if (this.loadingTabIndex == 1) {
                        this.loadingItems = [
                            { name: '正在检测感情状况', progress: 0 },
                            { name: '正在分析你的正桃花、烂桃花', progress: 0 },
                            { name: '正在计算你的感情指数', progress: 0 }
                        ]
                    } else if (this.loadingTabIndex == 2) {
                        this.loadingItems = [
                            { name: '正在检测你的婚前、配偶性格', progress: 0 },
                            { name: '正在分析你的姻缘状况', progress: 0 },
                            { name: '正在分析你的婚配情况', progress: 0 }
                        ]
                    }
                } else {
                    for (let i = 0; i < this.loadingItems.length; i++) {
                        if (this.loadingItems[i].progress < 100) {
                            let progress = this.loadingItems[i].progress + Math.floor(Math.random() * 40) + 1
                            if (progress > 100) {
                                progress = 100
                            }
                            this.loadingItems[i].progress = progress
                            break
                        }
                    }
                    if (this.loadingItems[this.loadingItems.length - 1].progress == 100) {
                        this.loadingItems = []
                        if (this.loadingTabIndex == 2) {
                            localStorage.setItem('oid', this.order.oid)
                            this.destroyLoadingInterval()
                            this.isLoading = false
                            this.startCheckPayState()
                        }
                    }
                }
            }, 200)
        },
        destroyLoadingInterval() {
            if (this.loadingIntervalId) {
                clearInterval(this.loadingIntervalId)
            }
        },
        fetchData() {
            this.$api.post('/order/info', { oid: this.$route.query.oid }).then(res => {
                if (res.data.success) {
                    this.order = res.data.data.order
                    this.pay_channels = res.data.data.pay_channels
                    this.hongbaoAmounts = res.data.data.hongbao_amounts
                    this.payChannelCode = Object.keys(this.pay_channels)[0]
                    if (localStorage.getItem("oid") == this.order.oid) {
                        this.startCheckPayState()
                    } else {
                        this.startLoading()
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        startCheckPayState() {
            this.isLoading = false
            window.addEventListener("scroll", this.handleScroll, true)
            this.startCountdown()
            this.payStateIntervalId = setInterval(() => {
                this.fetchPayState();
            }, 3000)
        },
        destroyCheckPayState() {
            if (this.payStateIntervalId) {
                clearInterval(this.payStateIntervalId)
            }
        },
        fetchPayState() {
            this.$api.post('/order/pay_state', { oid: this.$route.query.oid }).then(res => {
                if (res.data.success) {
                    if (res.data.data.status) {
                        window.location.href = res.data.data.redirect_url;
                        this.destroyCheckPayState()
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        startCountdown() {
            this.countdownId = setInterval(() => {
                if (this.countdown[2] > 0) {
                    this.$set(this.countdown, 2, this.countdown[2] - 1);
                } else if (this.countdown[1] > 0) {
                    this.$set(this.countdown, 1, this.countdown[1] - 1);
                    this.$set(this.countdown, 2, 99);
                } else if (this.countdown[0] > 0) {
                    this.$set(this.countdown, 0, this.countdown[0] - 1);
                    this.$set(this.countdown, 1, 59);
                    this.$set(this.countdown, 2, 99);
                }
            }, 10);
        },
        destroyCountdown() {
            if (this.countdownId) {
                clearInterval(this.countdownId)
            }
        },
        handleScroll() {
            0 > this.$refs.pay_tips.getBoundingClientRect().top ? (this.isFloatingBottom = true) : (this.isFloatingBottom = false)
        },
        handlePay() {
            location.href = this.pay_channels[this.payChannelCode].pay_url
        },
        getHongbaoPayAmount(index) {
            var ret_val = this.order.money - this.hongbaoAmounts[index]
            if (ret_val <= 0) {
                ret_val = 0.01
            }
            return ret_val.toFixed(2)
        },
        getHongbaoAmount(index) {
            return this.hongbaoAmounts[index]
        },
        closeHongbao(num) {
            if (num >= this.getHongbaoCount() - 1) {
                this.hongbaoNo = 3
            } else {
                this.hongbaoNo = num + 1
            }
        },
        hongbaoPay(hongbaoIndex) {
            location.href = this.pay_channels[this.payChannelCode].pay_url + '&hongbao_amount=' + this.hongbaoAmounts[hongbaoIndex];
        },
        getHongbaoCount() {
            if (this.hongbaoAmounts && this.hongbaoAmounts.length > 0) {
                return this.hongbaoAmounts.length
            }
            return 0
        },
        handlePayChannel(code) {
            this.payChannelCode = code
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/bazi/sass/pay.scss';
</style>