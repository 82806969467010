<template>
    <form class="hehun-form" @submit.prevent="handleSubmit">
        <dl class="hehun-index__form-item">
            <dt><img src="@/assets/hehun/images/male_round.png">男<br>方</dt>
            <dd>
                <input type="text" class="form-control" placeholder="请输入姓名" v-model="maleName">
                <input type="text" ref="male_birthday" id="MaleBirthday" class="form-control date-picker"
                    autocomplete="off" placeholder="请选择出生日期" data-confirm="true" data-toid-date="MaleBirthday_Date"
                    data-toid-hour="MaleBirthday_Hour" data-hour="7" readonly>
            </dd>
            <input type="hidden" ref="male_birth_date" id="MaleBirthday_Date">
            <input type="hidden" ref="male_birth_hour" id="MaleBirthday_Hour">
        </dl>
        <dl class="hehun-index__form-item">
            <dt><img src="@/assets/hehun/images/female_round.png">女<br>方</dt>
            <dd>
                <input type="text" class="form-control" placeholder="请输入姓名" v-model="femaleName">
                <input type="text" ref="female_birthday" id="FemaleBirthday" class="form-control date-picker"
                    data-type="0" data-confirm="true" placeholder="请选择出生日期" data-toid-date="FemaleBirthday_Date"
                    data-toid-hour="FemaleBirthday_Hour" data-hour="7" readonly />
            </dd>
            <input type="hidden" ref="female_birth_date" id="FemaleBirthday_Date">
            <input type="hidden" ref="female_birth_hour" id="FemaleBirthday_Hour">
        </dl>
        <input type="image" class="btn btn-zoom" width="100%"
            :src="buttonImage">
        <CheckBoxAgreement />
    </form>
</template>

<script>
import CheckBoxAgreement from '@/components/CheckboxAgreement.vue'

export default {
    name: 'HehunForm',
    props: {
        formType: {
            type: String,
            default: "hehun"
        },
        textColor: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        bgColor: {
            type: String,
            default: "rgba(0, 0, 0, 0.5)"
        },
        buttonImage: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        returnUrl: {
            type: String,
            default: "/hehun/pay?oid=__OID__"
        }
    },
    components: {
        CheckBoxAgreement
    },
    data() {
        return {
            maleName: '',
            femaleName: ''
        }
    },
    mounted() {
        new window.ruiDatepicker().init('#MaleBirthday')
        new window.ruiDatepicker().init('#FemaleBirthday')
    },
    methods: {
        isValidInput(text) {
            const chineseRegex = /^[\u4e00-\u9fa5]{2,4}$/
            return chineseRegex.test(text)
        },
        handleSubmit() {
            if (this.maleName.trim() !== '' && !this.isValidInput(this.maleName.trim())) {
                alert('请正确输入男方姓名')
                return false
            }
            const male_birthday = this.$refs.male_birthday
            if (male_birthday.value.trim() === '') {
                alert('请选择男方出生日期')
                return false
            }
            if (this.femaleName.trim() !== '' && !this.isValidInput(this.femaleName.trim())) {
                alert('请正确输入女方姓名')
                return false
            }
            const female_birthday = this.$refs.female_birthday
            if (female_birthday.value.trim() === '') {
                alert('请选择女方出生日期')
                return false
            }
            const male_birth_date = this.$refs.male_birth_date
            const male_birth_hour = this.$refs.male_birth_hour
            const female_birth_date = this.$refs.female_birth_date
            const female_birth_hour = this.$refs.female_birth_hour
            const data = {
                male_name: this.maleName,
                male_birth_date: male_birth_date.value,
                male_birth_hour: male_birth_hour.value,
                female_name: this.femaleName,
                female_birth_date: female_birth_date.value,
                female_birth_hour: female_birth_hour.value,
                logid_url: localStorage.getItem('logid_url'),
                type: this.formType,
                return_url: this.$options.filters.getDomain() + this.returnUrl
            }
            if (process.env.NODE_ENV === "development") {
                this.$emit("saveSuccess", '240909122654659598')
            } else {
                this.$api.post('/order/save', data).then(res => {
                    if (res.data.success) {
                        this.$emit("saveSuccess", res.data.data.oid)
                    } else {
                        alert(res.data.message)
                    }
                }).catch(error => {
                    alert(error)
                }).finally(() => {
                })
            }
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/hehun-form.scss';
</style>
