<template>
    <div :class="['three-part', componentClass]">
        <div class="three-part__heading">
            <img :src="headingImage" width="100%">
            <div class="three-part__heading-title">
                <slot name="title"></slot>
            </div>
        </div>
        <div class="three-part__content-container" :style="{ backgroundImage: `url(${contentImage})` }">
            <div class="three-part__content">
                <slot>内容</slot>
            </div>
        </div>
        <img :src="footingImage" width="100%">
    </div>
</template>

<script>
export default {
    name: 'ThreePart',
    props: {
        headingImage: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        contentImage: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        footingImage: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        componentClass: String
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/three-part.scss';
</style>
