<template>
    <div class="marquee-text" v-if="webInfo.marquee_text" :style="{ backgroundColor: bgColor }">
        <p :style="{ color: textColor }">
            {{ webInfo.marquee_text }}
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'MarqueeText',
    props: {
        textColor: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        bgColor: {
            type: String,
            default: "rgba(0, 0, 0, 0.5)"
        }
    },
    computed: {
        ...mapState(['webInfo'])
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/marquee-text.scss';
</style>
