import { render, staticRenderFns } from "./PriceWithCountdown.vue?vue&type=template&id=11b00107&scoped=true"
import script from "./PriceWithCountdown.vue?vue&type=script&lang=js"
export * from "./PriceWithCountdown.vue?vue&type=script&lang=js"
import style0 from "./PriceWithCountdown.vue?vue&type=style&index=0&id=11b00107&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b00107",
  null
  
)

export default component.exports