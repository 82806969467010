<template>
    <form class="scxp-result wrapper" method="get" @submit.prevent="handleSubmit">
        <div class="top-half">
            为了给您更好的体验，<dfn>我们为每一位购买测算报告的用户都分配了一位专属老师</dfn>，任何疑问●化解●布局●调理都可以在线咨询您的专属老师。
            <button>老师人工解答 <img :src="require('@/assets/scxp/images/mweixin.png')"></button>
        </div>
        <div class="result-main">
            <div class="header">
                <div class="report-heading">以下为您的报告内容</div>
                <div class="user-info">
                    <dl>
                        <dt>姓名:</dt>
                        <dd>{{ order.data.username }}</dd>
                        <dt>性别:</dt>
                        <dd>{{ data.data.gender | gender }}</dd>
                        <dt>生辰:</dt>
                        <dd>{{ order.data.lDate }}</dd>
                        <dt>八字:</dt>
                        <dd>{{ order.data_extend.return.user.bazi[0] }}{{ order.data_extend.return.user.bazi[1] }}
                            {{ order.data_extend.return.user.bazi[2] }}{{ order.data_extend.return.user.bazi[3] }}
                            {{ order.data_extend.return.user.bazi[4] }}{{ order.data_extend.return.user.bazi[5] }}
                            {{ order.data_extend.return.user.bazi[6] }}{{ order.data_extend.return.user.bazi[7] }}</dd>
                    </dl>
                    <strong>本命属<dfn>{{ order.data_extend.return.user.sx }}，{{
                        data.data_extend.pp.nayin1
                            }}命</dfn>；日主天干为{{ order.data_extend.return.user.bazi[4] }}{{ order.data_extend.cookies.wh[4]
                        }}，生于{{
                            data.data_extend.return.user.siji
                        }}季。</strong>
                </div>
            </div>
            <div class="result-content">
                <img :src="require('@/assets/scxp/images/bazi_title1.png')" width="100%">
                <table class="pan">
                    <tr>
                        <th></th>
                        <th>年柱</th>
                        <th>月柱</th>
                        <th>日柱</th>
                        <th>时柱</th>
                    </tr>
                    <tr>
                        <th>天干</th>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[0], data.data_extend.pp.shishen1)">
                        </td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[2], data.data_extend.pp.shishen2)">
                        </td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[4], '[日主]')"></td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[6], data.data_extend.pp.shishen4)">
                        </td>
                    </tr>
                    <tr>
                        <th>地支</th>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[1], data.data_extend.pp.z_shishen1)">
                        </td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[3], data.data_extend.pp.z_shishen2)">
                        </td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[5], data.data_extend.pp.z_shishen3)">
                        </td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[7], data.data_extend.pp.z_shishen4)">
                        </td>
                    </tr>
                    <tr>
                        <th>藏干</th>
                        <td>
                            {{ order.data_extend.pp.zanggan1 }}
                        </td>
                        <td>
                            {{ order.data_extend.pp.zanggan2 }}
                        </td>
                        <td>
                            {{ order.data_extend.pp.zanggan3 }}
                        </td>
                        <td>
                            {{ order.data_extend.pp.zanggan4 }}
                        </td>
                    </tr>
                    <tr>
                        <th>命宫</th>
                        <td colspan="4">
                            {{ order.data_extend.pp.minggong }}
                        </td>
                    </tr>
                    <tr>
                        <th>胎元</th>
                        <td colspan="4">
                            {{ order.data_extend.pp.taiyuan }}
                        </td>
                    </tr>
                    <tr>
                        <th>胎息</th>
                        <td colspan="4">
                            {{ order.data_extend.return.user.bazi[6] }}{{ order.data_extend.return.user.bazi[7] }}
                        </td>
                    </tr>
                </table>
                <img :src="require('@/assets/scxp/images/bazi_title2.png')" width="100%">
                <br><br>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ order.data_extend.bzxj }}

                <div class="report-title">
                    你的八字命局中五行是否有缺？阴阳是否平衡?五行旺缺、喜忌对运势有哪些关键作用？如何调节五行， 达到平衡？
                </div>
                <table class="bazi">
                    <tr>
                        <th></th>
                        <th>年柱</th>
                        <th>月柱</th>
                        <th>日柱</th>
                        <th>时柱</th>
                    </tr>
                    <tr>
                        <td>八字</td>
                        <td>{{ order.data_extend.return.user.bazi[0] }}{{ order.data_extend.return.user.bazi[1] }}
                        </td>
                        <td>{{ order.data_extend.return.user.bazi[2] }}{{ order.data_extend.return.user.bazi[3] }}
                        </td>
                        <td>{{ order.data_extend.return.user.bazi[4] }}{{ order.data_extend.return.user.bazi[5] }}
                        </td>
                        <td>{{ order.data_extend.return.user.bazi[6] }}{{ order.data_extend.return.user.bazi[7] }}
                        </td>
                    </tr>
                    <tr>
                        <td>五行</td>
                        <td>{{ order.data_extend.cookies.wh[0] }}{{ order.data_extend.cookies.wh[1] }}</td>
                        <td>{{ order.data_extend.cookies.wh[2] }}{{ order.data_extend.cookies.wh[3] }}</td>
                        <td>{{ order.data_extend.cookies.wh[4] }}{{ order.data_extend.cookies.wh[5] }}</td>
                        <td>{{ order.data_extend.cookies.wh[6] }}{{ order.data_extend.cookies.wh[7] }}</td>
                    </tr>
                </table>

                <input type="image" :src="require('@/assets/scxp/images/wuxing.png')" width="100%">

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ order.data_extend.wxzs }}<br>
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宇宙万物皆有五行磁场与属性，我们的生辰就是降生时那一刻的五行状态，五行与自身命格运势息息相关，命格五行失衡，当走到五行受伤的年份，运势就会大幅度缩水，下滑，出现求财困难，求而不得，频繁破财损财，事业受阻，当进不进，犯小人劳而无功，自己的功劳被小人抢走，贵人难遇，关键时候无贵人帮助错失机遇，事业容易变动，奔波，且容易遇到投资失败，心力交瘁的情况，感情也容易守不住出现危机。了解自身五行旺缺与五行生克，知道五行旺缺对自己运势的影响，即可掌握先天运势，可借助后天五行调补先天五行的平衡，趋吉避凶，实现财运事业，健康感情等各方面的称心顺意。五行每个元素对人生都有特定意义，不管缺少哪一个都会产生相应的影响，代表财的五行缺失则与财缘薄，不善理财守财，代表配偶的五行缺失，则感情不顺，难遇良人...且在走到不利流年，则容易引动运势低估，导致霉运连连，<b>下面老师就跟你讲一个五行不平衡的案例吧。</b>

                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以前2023年的时候，有位女士来找我，我问她:你怎么了?她说家里大儿子年近35还是一直不想结婚。拿过她公子的八字一看，我说:你这孩子责任心很强，从小就比较成熟，各方面都比较不错但是就是感情上没有进展?她反问我怎么知道。她继续问道:老师，我年纪也大了，现在就是担心孩子的婚事，实在是太操心了我到底该怎么办呢。<br>
                    <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我说其实并不难，你调整一下。我看了一下，这位孩子先天水比较旺，五行缺火，刚好火是他的配偶星啊，而孩子工作在北方，北方属水，是忌神方位。而且她孩子住哪呢，又是家中西北的房间。听到这里啊，我就明白为什么她的孩子迟迟都没有结婚的念头了<br>
                    <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;这里面是有讲究的，长子住西北叫雷天大壮，这个位置的风水就是孩子早熟懂事，责任心强，事业也不错，但是就是无心婚姻。这们女士特别心急地问我:那我该怎么办呀，怎么调?我让她呢用火来调理一下，因为我克者为财，男命以财为配偶星，所以水以火为财。简单来说就是手上佩戴上五行手链，再多去买一些红色的衣服，袜子内裤子也要买一些红色的。给孩子穿，另外也要从西北方搬出去，这位女士也很听话，回去就给孩子安排上了。还发过来张照片给我，他的整个房间都换成了红色系。<br>
                    <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;大概过了一个月左右，她的孩子就因为一次公司聚餐遇到一个比较心仪的人，对于婚姻也不再说不想结婚了，开始有了转变，其实啊，所有影响人的就是五行力量的生克之患，在你不顺的时候，稍微调整一下你的五行就好了，也没那么复杂。如果你实在不懂，就来<b>找老师，告诉你五行平衡调理、补旺的方法。</b>
                </p>

                <div class="report-title">
                    根据你的八字命局，进行年、月、时柱分析
                </div>
                <b>年柱：</b><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ order.data_extend.ssfx_y }}<br><br>
                <b>月柱：</b><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ order.data_extend.ssfx_m }}<br><br>
                <b>时柱：</b><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ order.data_extend.ssfx_s }}<br>

                <div class="report-title">
                    你的八字命局中感情姻缘如何？
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ order.data_extend.rglm.aqfx }}

                <div class="report-title">
                    根据你的八字命局，哪些行业利于你财运打开和财富提升?
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ order.data_extend.rglm.syfx }}

                <div class="report-title">
                    你八字命局的整体健康如何呢？
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;从五行旺弱来看，你的水太弱，而水在人体中代表肾及生殖泌尿系统，太弱则无力，容易出现相关的健康问题。你的肾脏功能有点弱，可能有易受惊吓、排尿不畅、性能力偏弱等问题，若不注意隐患，还可能出现更多肾水系统类的问题。

                <div class="report-title">
                    根据你的八字命局，你的事业在哪里更易发光发热获得成就？你更适合做什么角色有利于吸财旺财？
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ order.data_extend.shiye }}

                <div class="report-title">
                    三通汇命
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ order.data_extend.sxth.tf1 }}
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ order.data_extend.sxth.tf2 }}


                <div class="report-title">
                    什么是喜用神？喜用神对我们的作用。
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;喜用神是中国传统八字命运学上的术语，喜用神是喜神与用神的合称，命局有“不及”和“太过”等情况，称之为“病”，而“用神”正是针对不同的“病”所下的“药”。“喜神”则是对“用神”能够起到生扶作用的阴阳五行元素。四柱命局以用神为核心，用神健全有力与否，影响人一生的命。一生补救与否，影响人一生的运。凡用神之力不足或缺失，都会导致人生多劳少成，运势欠佳，对财运事业姻缘都会有相应的影响，四柱若是没有用神，可以从后天风水中为自己补足。<b>你的命盘中五行失衡，会导致个人磁场比较弱，当走到不利于你的大运流年运势时就容易感到心力憔悴，建议找师兄为您调理。</b>

                <div class="report-title">
                    你八字命局未来12月的运程转机？
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月运势相对于年运势的影响力有限，往往在好运的年份遇上好运的月份则喜上加喜，在差的年份遇到好运的月份也只能逢凶化吉和催吉避凶，因此月运势对个人的影响只能起到辅助、锦上添花的作用，在好运的月份里做正确的事、催吉避凶，在差的月份静待休养、不乱做决定，这样才是流月运势的正确使用方式。

                <div class="month" v-for="(m, index) in data.data_extend.myq_text" :key="index">
                    <div class="report-title">
                        {{ m.start_date }}～{{ m.end_date }}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ m.content }}
                </div>

                <br>
                <div class="bazi-zongjie">
                    <p class="section">
                        <img :src="require('@/assets/scxp/images/zongjie1.png')" class="img1">
                        <em>第一：</em>{{ zongjie[0][xiyong] }}
                    </p>
                    <div class="section">
                        <img :src="require('@/assets/scxp/images/zongjie2.png')" class="img2">
                        <em>第二：</em><span v-html="getZongjieNo2(data.data_extend.return.user.bazi[7])"></span>
                    </div>
                    <div class="section">
                        <img :src="require('@/assets/scxp/images/zongjie3.png')" class="img1">
                        <em>第三：</em>{{ zongjie[2][data.data_extend.return.user.bazi[5]] }}
                    </div>
                </div>

                <input type="image" :src="require('@/assets/scxp/images/bazijingsuan.png')" width="100%">

                <div class="comments">
                    <div class="comment-heading">测后用户真实反馈</div>
                    <div class="comment-content">
                        <small>
                            测算人数: <dfn>1687898</dfn>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;好评率: <dfn>99.2%</dfn>
                        </small>
                        <div class="comment-list">
                            <div class="comment-wrap">
                                <div class="comment" v-for="(comment, index) in comments" :key="index">
                                    <div class="title">
                                        <img :src="require('@/assets/avatars/' + comment[0])" class="avatar">
                                        <div class="comment-info">
                                            <strong>{{ comment[1] }}</strong>
                                            <i>{{ comment[2] }}</i>
                                        </div>
                                    </div>
                                    <div class="score">打分：<img :src="require('@/assets/scxp/images/comment_star.png')"
                                            class="star"><img :src="require('@/assets/scxp/images/comment_star.png')"
                                            class="star"><img :src="require('@/assets/scxp/images/comment_star.png')"
                                            class="star"><img :src="require('@/assets/scxp/images/comment_star.png')"
                                            class="star"><img :src="require('@/assets/scxp/images/comment_star.png')"
                                            class="star">
                                    </div>
                                    <div class="text">
                                        {{ comment[3] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="floating-bottom">
            <div class="floating-wrap">
                <input type="image" :src="require('@/assets/scxp/images/contact_master.png')" width="96%">
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: 'BzjpResult',
    data() {
        return {
            masterImage: process.env.VUE_APP_JIEGUO_MASTER_IMAGE_PATH,
            footMasterJieduImage: process.env.VUE_APP_JIEGUO_FOOT_MASTER_JIEDU_IMAGE_PATH,
            data: {},
            xiyong: '',
            zongjie: [
                {
                    '金': '你的格局其实无论是感情还是财运上的运势都不算差，但是因为命局中五行金为喜用且不够强旺，导致泄财泄运比较严重，婚姻感情比较容易发生重大转变情况，建议进行补旺五行木的调理，对你的格局来说比较关键。',
                    '木': '你的格局其实无论是感情还是财运上的运势都不算差，但是因为命局中五行木为喜用且不够强旺，导致泄财泄运比较严重，婚姻感情比较容易发生重大转变情况，建议进行补旺五行木的调理，对你的格局来说比较关键。',
                    '水': '你的格局其实无论是感情还是财运上的运势都不算差，但是因为命局中五行水为喜用且不够强旺，导致泄财泄运比较严重，婚姻感情比较容易发生重大转变情况，建议进行补旺五行水的调理，对你的格局来说比较关键。',
                    '火': '你的格局其实无论是感情还是财运上的运势都不算差，但是因为命局中五行火为喜用且不够强旺，导致泄财泄运比较严重，婚姻感情比较容易发生重大转变情况，建议进行补旺五行木的调理，对你的格局来说比较关键。',
                    '土': '你的格局其实无论是感情还是财运上的运势都不算差，但是因为命局中五行土为喜用且不够强旺，导致泄财泄运比较严重，婚姻感情比较容易发生重大转变情况，建议进行补旺五行土的调理，对你的格局来说比较关键。'
                },
                [
                    '你的命局中其实生财并不弱，在求财生财上，若要发财并不容易，要长时间积蓄方有所成。一方面要不断增强自己的体质和实力，这样才有足够的精力去赚取更多钱财。另一方面则还得走大运和流年，大运好的年份可以获得不错的收成，求财机遇才会到来，运势差的年份最好退避三舍，否则付出再多也收不回来。财运提升建议可以补旺财库。这样即便是在差的大运流年也是有机会得到很大改善的，在好的流年更是锦上添花。',
                    '你的命局中虽然财星容易受损，但是在求财生财上，只要能抓住该抓住的时机，获取钱财对你来说是一件相对容易的事情。但是有时候机会过多也容易聪明反被聪明误，掉了陷阱导致破财，财运提升建议可以补旺财库。您事业上的好的运势你才能够抓住。财库不补，财门打不开，容易财来财去，财运方面会不够稳定。格局就这样容易阴差阳错流失掉，或者发挥不起来。',
                    '你的命局中地支受到刑冲，也代表做事不稳，建议可以通过提升自身的技艺来增强财运，因自身的精湛技艺往往会给你带来更对财富。不宜进行风险性过高的投资，否则容易有损失。走好的大运和流年，财运会有好转，把握时机有望得财。身旺财弱犹如能力强但机会少，无法得到财富，因此财运提升建议可以补旺财库。您事业上的好的运势你才能够抓住。财库不补，财门打不开，赚钱辛苦，好的机会都是把握不住的。',
                    '你的命局财官虽有，但是却无根，这代表你的机会是有的，但是总会出现这样那样的意外从而白白流失掉财富，抓不住机会，也有可能因为突如其来的身体健康问题不得不破财消灾。财运提升建议可以补旺财库，财门打不开，容易碰到各种状况导致耗财。就可能总是在求财的关键时候掉链子格局。格局就容易支撑不起来太大的财富。',
                    '你的命局中财无库来收，也代表根基不稳，首先要学会强身，要不断增强自己的体质和实力，体质不好容易生病，则很难担得起财，身体好了才有精力去赚钱，因此改善自身的健康有助于生财。说明有财进，但是财来财去一场空，常常留不下财，被动破财，或者花钱没有节制，财运提升建议可以补旺财库。才可以更好更稳定的担住这些财富。不会散去。',
                    '你的命局中身偏弱，喜帮扶，在求财生财上，若要发财并不容易，要长时间积蓄方有所成。一方面要不断增强自己的体质和实力，这样才有足够的精力去赚取更多钱财。同时也要多社交，遇贵人，财运提升建议可以补旺财库。这样才可以更好地做出决策。财库不补，求财路上容易受到外界各种因素的干扰从而走更多弯路。格局没有发挥出应有的功效。'
                ],
                {
                    '子':'感情婚姻姻缘方面，在格局当中身旺官杀旺，总体还算顺利，但还是容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解。',
                    '丑': '感情婚姻姻缘方面，在格局当中身旺官杀弱，比较坎坷，同时还容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解。',
                    '寅': '感情婚姻姻缘方面，在格局当中虽然婚姻宫被合，总体还算顺利，但还是容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解。',
                    '卯': '感情婚姻姻缘方面，在格局当中身偏旺官杀弱，总体还算顺利，但还是容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解。',
                    '辰': '感情婚姻姻缘方面，在格局当中身偏弱财官不稳，比较坎坷，同时还容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解',
                    '巳': '感情婚姻姻缘方面，在格局当中身旺财也不弱，总体还算顺利，但还是容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解。',
                    '午': '感情婚姻姻缘方面，在格局当中身旺财弱，比较坎坷，同时还容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解。',
                    '未': '感情婚姻姻缘方面，在格局当中身弱财也不弱，总体还算顺利，但还是容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解。',
                    '申': '感情婚姻姻缘方面，在格局当中身弱财旺，总体还算顺利，但还是容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解',
                    '酉': '感情婚姻姻缘方面，在格局当中婚姻宫受伤，可能发生一些变故，但总体还算顺利，但还是容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解',
                    '戌': '感情婚姻姻缘方面，在格局当中劫财入宫，可能发生一些变化，但总体还算顺利，但还是容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解',
                    '亥': '感情婚姻姻缘方面，在格局当中财官不稳，可能会受到一些影响，但总体还算顺利，但还是容易受到受生债的影响，这个受生债对你的事业运也会有影响，建议化解'
                }
            ],
            comments: [
                ["28.jpg", "王*明", "1992年****辰时", "准备去创业，心里又不少疑惑，感谢老师的指点，目前不疑惑了，国学文化，真的博大精深"],
                ["6.jpg", "李*芝", "1885年****申时", "这两年事业总是不顺利，心烦意乱的，找老师看了一下，挺准的，听完心里舒服了很多，可算看到了希望。"],
                ["7.jpg", "张*筱", "1979年****酉时", "看了老公的财运，看的老公是的事业线，挺准的，希望我的老公如大师所说，明年开始爆发"],
                ["8.jpg", "李*", "1995年****子时", "原来真的不是喜欢的就是好缘分，详细问完老师心里也释怀了很多，下面年准备自己过了，加油"],
                ["9.jpg", "刘*华", "1998年****亥时", "毕业4年，工作总感觉力不从心，也没赚到钱，根据我的五行，性格分析，老师给了我许多职业方向的建议，准备今年开始做调整"],
                ["10.jpg", "谭*明", "1972年****午时", "2022有意外都能给我测出来，早知道早咨询多好，也不至于遭这罪了！"],
                ["11.jpg", "杏一*", "1997年****辰时", "通过这次我才知道我的事业运再南方，重新做了职业规划，2025以后我要暴富、暴美！"],
                ["12.jpg", "郑*昌", "2001年****卯时", "希望今年能能顺利出国，把留学的事情办妥了"],
                ["13.jpg", "周*轩", "1996年****巳时", "我的性格和测算中说的一模一样，还是做事中太把控细节，犹豫不决，清晰认知自己，以后要加强改正。"],
                ["14.jpg", "王勇*", "1994年****亥时", "原来我是做销售工作的，工作并不顺心，希望通过老师的建议做职业调后，有好的起色"],
                ["15.jpg", "李*", "1981年****酉时", "蛮准的，我真是的从小就和爸妈疏远，原来命中早已注定，驿马中才有财富，注定要浪迹天涯"],
                ["16.jpg", "杜*", "1987年****戌时", "开了家装公司，总是生意一般，原来是这两年犯小人，看来要踩小人了！"],
                ["17.jpg", "张*妍", "1970年****寅时", "看了孩子和我缘分很深，很是开心，我家孩子真的是来报恩的，一直让我很省心"],
                ["18.jpg", "沈*明", "1985年****未时", "离婚了，很无奈，老师看了，我命中本就2段姻缘，让我平常心看待，说我第二份会比第一段好，终于死会的心有了一点希望"],
                ["19.jpg", "蒋*文", "1994年****卯时", "事业和感情方向都分析的很准，希望我2025能顺利结婚！"],
                ["20.jpg", "陈*", "1997年****卯时", "2022遇到了一个特别喜欢我的男孩子，老师都看出来了，真的挺牛的"],
                ["21.jpg", "赵*炫", "1982年****巳时", "正财旺，且对我好，留得住，看来我要好好经营我的店铺了"],
                ["22.jpg", "吕明*", "1969年****亥时", "孩子的感情一直不顺，帮她看了她适合什么样的类型，我觉得蛮准的。"],
                ["23.jpg", "何瑶*", "1981年****酉时", "老师说我今年10月份起运，很开心，终于苦尽甘来"],
                ["24.jpg", "徐*飞", "1996年****戌时", "测得我的过去发生的事情蛮准的，老师说我最近几个月运势不佳，给我做了调理，配备了调运手镯，希望后面我顺顺利利的。"],
                ["25.jpg", "薛亦*", "1990年****寅时", "和男友合了盘，确实挺准，男友就是那样的性格，给了一些交往指导建议，很受用"]
            ]
        }
    },
    mounted: function () {
        this.fetchData()
    },
    methods: {
        getFetchWeixin() {
            return this.data.fetch_weixin;
        },
        handleSubmit() {
            window.location.href = this.getFetchWeixin()
            return false
        },
        setXiyong(wh) {
            var wuxing = {
                '金': 0,
                '木': 0,
                '水': 0,
                '火': 0,
                '土': 0
            }
            for (var i = 0; i < wh.length; i++) {
                wuxing[wh[i]] += 1
            }
            var wuxingSorted = Object.keys(wuxing).sort(function (a, b) {
                return wuxing[a] - wuxing[b];
            })
            this.xiyong = wuxingSorted[0]
        },
        getZongjieNo2(dz) {
            var dizhi = '子丑寅卯辰巳午未申酉戌亥'
            return this.zongjie[1][dizhi.indexOf(dz) % 6]
        },
        getBaziHtml(bazi, shishen) {
            let html = bazi + '/';
            let shishenVals = shishen.split("\n");
            for (let i in shishenVals) {
                if (i > 0) {
                    html += '<br>'
                }
                html += "<span class='shishen'>";
                if (i > 0) {
                    html += '　 '
                }
                html += shishenVals[i].replaceAll(/\[|\]/g, '') + "</span>";
            }
            return html;
        },
        getXgfxHtml(content) {
            let items = content.split("\n");
            let html = "";
            for (let item of items) {
                html += item.replace(/(\d+)\./g, '');
            }
            return html;
        },
        fetchData() {
            this.$api.post('order/result', { oid: this.$route.query.oid }).then(res => {
                if (res.data.success) {
                    this.data = res.data.data
                    this.setXiyong(this.data.data_extend.cookies.wh)
                } else {
                    console.log(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scxp/sass/result.scss';
</style>