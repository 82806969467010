import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import axios from 'axios'
import * as filters from './filters'

Vue.use(Vuex)
Vue.use(VueRouter)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

const store = new Vuex.Store({
  state: {
    webInfo: {},
    orderInfo: null,
    payChannels: {},
    payChannelCode: null,
    hongbaoAmounts: [5, 10, 15]
  },
  mutations: {
    setWebInfo(state, webInfo) {
      state.webInfo = webInfo
    },
    setOrderInfo(state, orderInfo) {
      state.orderInfo = orderInfo
    },
    setPayChannels(state, payChannels) {
      state.payChannels = payChannels
    },
    setPayChannelCode(state, payChannelCode) {
      state.payChannelCode = payChannelCode
    },
    setHongbaoAmounts(state, hongbaoAmounts) {
      state.hongbaoAmounts = hongbaoAmounts
    }
  }
})

const API = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 1000 * 120
});
Vue.prototype.$api = API

import App from './App.vue'
import Index from './pages/Index.vue'
import YinyuancsIndex from './pages/yinyuancs/Index.vue'
import YinyuancsPay from './pages/yinyuancs/Pay.vue'

import ZiweiIndex from './pages/ziwei/Index.vue'
import ZiweiInfo from './pages/ziwei/Info.vue'
import ZiweiPay from './pages/ziwei/Pay.vue'
import ScxpIndex from './pages/scxp/Index.vue'
import ScxpInfo from './pages/scxp/Info.vue'
import ScxpPay from './pages/scxp/Pay.vue'
import ScxpResult from './pages/scxp/Result.vue'
import BazijpIndex from './pages/bazijp/Index.vue'
import BazijpInfo from './pages/bazijp/Info.vue'
import BazijpPay from './pages/bazijp/Pay.vue'
import BazijpResult from './pages/bazijp/Result.vue'
import HehunPay from './pages/hehun/Pay.vue'
import BaziPay from './pages/bazi/Pay.vue'
import Bazi2Pay from './pages/bazi2/Pay.vue'

import Hehun2Pay from './pages/hehun2/Pay.vue'
import Hehun2Generating from './pages/hehun2/Generating.vue'
import Hehun3Pay from './pages/hehun3/Pay.vue'

import ScysPay from './pages/scys/Pay.vue'

import AgreementPrivacy from './pages/agreement/Privacy.vue'
import HistoryIndex from './pages/history/Index.vue'
const routes = [
  { path: '/', component: Index, meta: { title: '生辰综合测算' } },
  { path: '/yinyuancs/index', component: YinyuancsIndex, meta: { title: '个人姻缘' } },
  { path: '/yinyuancs/pay', component: YinyuancsPay, meta: { title: '支付' } },

  { path: '/zwds/index', component: ZiweiIndex, meta: { title: '紫薇斗数' } },
  { path: '/ziwei/index', component: ZiweiIndex, meta: { title: '紫薇斗数' } },
  { path: '/ziwei/info', component: ZiweiInfo, meta: { title: '订单信息' } },
  { path: '/ziwei/pay', component: ZiweiPay, meta: { title: '支付' } },

  { path: '/scxp/index', component: ScxpIndex, meta: { title: '生辰详批' } },
  { path: '/scxp/info', component: ScxpInfo, meta: { title: '订单信息' } },
  { path: '/scxp/pay', component: ScxpPay, meta: { title: '支付' } },
  { path: '/scxp/result', component: ScxpResult },

  { path: '/bzjp/index', component: BazijpIndex, meta: { title: '八字精批' } },
  { path: '/bazijp/index', component: BazijpIndex, meta: { title: '八字精批' } },
  { path: '/bazijp/info', component: BazijpInfo, meta: { title: '订单信息' } },
  { path: '/bazijp/pay', component: BazijpPay, meta: { title: '支付' } },
  { path: '/bazijp/result', component: BazijpResult },

  { path: '/hehun/pay', component: HehunPay, meta: { title: '支付' } },

  { path: '/hehun2/generating', component: Hehun2Generating, meta: { title: '报告生成中..' } },
  { path: '/hehun2/pay', component: Hehun2Pay, meta: { title: '支付' } },

  { path: '/hehun3/pay', component: Hehun3Pay, meta: { title: '支付' } },
  { path: '/bazi2/pay', component: Bazi2Pay, meta: { title: '支付' } },

  { path: '/scys/pay', component: ScysPay, meta: { title: '支付' } },

  { path: '/bazi/pay', component: BaziPay, meta: { title: '支付' } },

  { path: '/agreement/privacy', component: AgreementPrivacy, meta: { title: '隐私条款' } },
  { path: '/history/index', component: HistoryIndex, meta: { title: '我的订单' } }
]

const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (window._hmt) {
    if (to.path) {
      // 如果是 history 模式
      window._hmt.push(['_trackPageview', to.fullPath]);
      // 如果是 hash 模式
      // window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  next()
})
router.afterEach(() => {
  window.scroll(0, 0)
})

Vue.config.productionTip = false

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')
