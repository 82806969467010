<template>
    <div class="ziwei-pay wrapper">
        <div class="header">
            <img src="@/assets/ziwei/images/natal_title.33760978.png">
        </div>
        <div class="mingpan">
            <table>
                <tr>
                    <td ref="gong5">
                        <ul ref="stars5" class="stars"></ul>
                        <div ref="tgdz5" class="tgdz"></div>
                        <div ref="name5" class="name"></div>
                    </td>
                    <td ref="gong6">
                        <ul ref="stars6" class="stars"></ul>
                        <div ref="tgdz6" class="tgdz"></div>
                        <div ref="name6" class="name"></div>
                    </td>
                    <td ref="gong7">
                        <ul ref="stars7" class="stars"></ul>
                        <div ref="tgdz7" class="tgdz"></div>
                        <div ref="name7" class="name"></div>
                    </td>
                    <td ref="gong8">
                        <ul ref="stars8" class="stars"></ul>
                        <div ref="tgdz8" class="tgdz"></div>
                        <div ref="name8" class="name"></div>
                    </td>
                </tr>
                <tr>
                    <td ref="gong4">
                        <ul ref="stars4" class="stars"></ul>
                        <div ref="tgdz4" class="tgdz"></div>
                        <div ref="name4" class="name"></div>
                    </td>
                    <th rowspan="2" colspan="2" class="middle" ref="info">
                        <div ref="info0" class="info"><i>姓名：</i>{{ order.data.username }}</div>
                        <div ref="info1" class="info"><i>性别：</i>{{ order.data.gender | gender }}</div>
                        <div ref="info2" class="info"><i>出生日期：</i><br>{{ order.data.lDate }}</div>
                        <div class="more">
                            <div ref="info3" class="info"><i>命局：</i><u>{{ order.data.wuXingJu }}</u></div>
                            <div ref="info4" class="info"><i>命主：</i><u>{{ order.data.mingZhu }}</u></div>
                            <div ref="info5" class="info"><i>身主：</i><u>{{ order.data.shenZhu }}</u></div>
                        </div>
                    </th>
                    <td ref="gong9">
                        <ul ref="stars9" class="stars"></ul>
                        <div ref="tgdz9" class="tgdz"></div>
                        <div ref="name9" class="name"></div>
                    </td>
                </tr>
                <tr>
                    <td ref="gong3">
                        <ul ref="stars3" class="stars"></ul>
                        <div ref="tgdz3" class="tgdz"></div>
                        <div ref="name3" class="name"></div>
                    </td>
                    <td ref="gong10">
                        <ul ref="stars10" class="stars"></ul>
                        <div ref="tgdz10" class="tgdz"></div>
                        <div ref="name10" class="name"></div>
                    </td>
                </tr>
                <tr>
                    <td ref="gong2">
                        <ul ref="stars2" class="stars"></ul>
                        <div ref="tgdz2" class="tgdz"></div>
                        <div ref="name2" class="name"></div>
                    </td>
                    <td ref="gong1">
                        <ul ref="stars1" class="stars"></ul>
                        <div ref="tgdz1" class="tgdz"></div>
                        <div ref="name1" class="name"></div>
                    </td>
                    <td ref="gong0">
                        <ul ref="stars0" class="stars"></ul>
                        <div ref="tgdz0" class="tgdz"></div>
                        <div ref="name0" class="name"></div>
                    </td>
                    <td ref="gong11">
                        <ul ref="stars11" class="stars"></ul>
                        <div ref="tgdz11" class="tgdz"></div>
                        <div ref="name11" class="name"></div>
                    </td>
                </tr>
            </table>
            <div ref="bianDong" class="badge"><img ref="bianDongImage"
                    src="@/assets/ziwei/images/badge_biandong.dc27c17e.png"></div>
            <div ref="tuPo" class="badge"><img ref="tuPoImage" src="@/assets/ziwei/images/badge_tupo.9c40ecce.png">
            </div>
            <div ref="jiYu" class="badge"><img ref="jiYuImage" src="@/assets/ziwei/images/badge_jiyu.88639a65.png">
            </div>
        </div>
        <div class="process-box" v-if="isShowProcess">
            <strong>{{ processText }}</strong>
            <div class="process-out">
                <div class="process-in" v-bind:style="{ width: processValue }"></div>
            </div>
        </div>
        <div class="button" v-if="isShowPayButton">
            <div class="tips">根据命盘显示，你2025年的感情财富事业会出现重要情况</div>
            <input type="image" :src="require('@/assets/ziwei/images/pay_btn.cb1eeb98.png')" @click="onClick()">
        </div>
        <div class="mask" ref="mask" v-if="isShowPayPopup" @click="closePayPopup()"></div>
        <div class="popup-wrap" v-if="isShowPayPopup">
            <form class="popup" method="get" @submit.prevent="onPay()">
                <div class="title">
                    <div class="back" @click="onBack()">
                        <img src="@/assets/ziwei/images/return_arrow.png">
                    </div>
                    <img class="name" src="@/assets/ziwei/images/pay_title.629a15aa.png">
                    <div class="close" @click="closePayPopup()">
                        <img src="@/assets/ziwei/images/close.png">
                    </div>
                </div>
                <div class="intro">
                    <img class="image" :src="require('../../assets/images/master.png')">
                    <div class="order-info">
                        <p>
                            <u>{{ masterName }}</u>大数据根据你的生日盘面分析，<u>{{tipsText}}</u>，这里为你量身定制了你的专属报告，并附有对应解决方案！
                        </p>
                        <ul>
                            <li>姓名：{{ order.data.username }}</li>
                            <li>性别：{{ order.data.gender | gender }}</li>
                            <li>生日：{{ order.data.lDate }}</li>
                        </ul>
                    </div>
                </div>
                <div class="pricing">
                    <div class="row">
                        <u class="original">原价：¥138</u>
                        <div class="countdown-title">限时优惠倒计时</div>
                    </div>
                    <div class="row">
                        <div class="price"><dfn>结缘价</dfn><i>&yen;</i><b>{{ order.money }}</b></div>
                        <div class="countdown">{{ countdownText }}</div>
                    </div>
                </div>
                <div class="channels">
                    <div class="channel" v-for="(item, code) in payChannels" :key="code"
                        @click="onClickChannel(code)">
                        <img class="icon" src="@/assets/ziwei/images/weixin.png" v-if="code == 'Weixin'">
                        <img class="icon" src="@/assets/ziwei/images/alipay.png" v-else-if="code == 'Alipay'">
                        <div class="checkbox">
                            <img src="@/assets/ziwei/images/pay_checked.png"
                                v-if="code == checkedPayChannelCode">
                            <img src="@/assets/ziwei/images/pay_unchecked.png" v-else>
                        </div>
                        <div class="name">{{ item.name }}</div>
                    </div>
                </div>
                <div class="commit">
                    <input type="image" class="submit" :src="require('@/assets/ziwei/images/pay_sec_btn.fff93622.png')" @click="onPay()">
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ZwdsPay',
    props: {
        msg: String
    },
    methods: {
        onClickChannel: function (code) {
            this.checkedPayChannelCode = code;
        },
        onBack: function () {
            this.$router.back()
        },
        onPay: function () {
            window.location.href = this.payChannels[this.checkedPayChannelCode].pay_url;
            return false
        },
        destroyInterval: function () {
            clearInterval(this.intervalID);
            this.intervalID = null;
        },
        destroyCheckPay: function () {
            clearTimeout(this.checkPayID);
            this.checkPayID = null;
        },
        onClick: function () {
            if (this.order.expired == null) {
                this.order.expired = new Date().getTime() + (30 * 60 * 1000);
            }
            if (this.intervalID == null) {
                this.intervalID = setInterval(() => { this.showCountdownText(); }, 10);
            }
            this.isShowPayPopup = true;
        },
        closePayPopup: function () {
            this.isShowPayPopup = false;
            this.destroyInterval();
        },
        play: function () {
            this.tgdzShow();
        },
        tgdzShow: function () {
            this.$refs['gong' + this.current].classList.value = "show";
            this.current++;
            if (this.current == 12) {
                this.current = 0;
                this.$refs['info'].classList.value = "show";
                this.showProcess('1.正在输入命主信息');
                setTimeout(() => { this.infoShow(); }, this.ms);
                return;
            }
            setTimeout(() => { this.tgdzShow(); }, this.ms);
        },
        infoShow: function () {
            this.$refs['info' + this.current].classList.value = "info show";
            this.current++;
            this.setProcessValue(this.current / 6);
            if (this.current == 6) {
                this.current = 0;
                this.showProcess('2.正在安命宫');
                setTimeout(() => { this.nameShow(); }, this.ms);
                return;
            }
            setTimeout(() => { this.infoShow(); }, this.ms);
        },
        nameShow: function () {
            this.$refs['name' + ((this.current + this.order.data.mingGongIndex) % 12)].classList.value = "name show";
            this.current++;
            this.setProcessValue(this.current / 12);
            if (this.current == 12) {
                this.current = 0;
                this.showProcess('3.正在定位紫微星与天干四化');
                setTimeout(() => { this.starshow(); }, this.ms);
                return;
            }
            setTimeout(() => { this.nameShow(); }, this.ms);
        },
        starshow: function () {
            this.$refs['stars' + ((this.current + this.order.data.ziweiGongIndex) % 12)].classList.value = "stars show";
            this.current++;
            this.setProcessValue(this.current / 12);
            if (this.current == 12) {
                this.current = 0;
                this.showProcess('4.正在生成报告');
                setTimeout(() => { this.bianDongShow(); }, this.ms);
                return;
            }
            setTimeout(() => { this.starshow(); }, this.ms);
        },
        getX(index) {
            let x;
            if (index >= 2 && index < 6) {
                x = -5.16;
            } else if (index == 1 || index == 6) {
                x = -1.1;
            } else if (index == 0 || index == 7) {
                x = 3.1;
            } else if (index >= 8 && index < 12) {
                x = 7.4;
            }
            return x;
        },
        getY(index) {
            let y;
            if (index >= 5 && index < 9) {
                y = -8;
            } else if (index == 4 || index == 9) {
                y = -3.4;
            } else if (index == 3 || index == 10) {
                y = 1.2;
            } else if ((index >= 0 && index < 3) || index == 11) {
                y = 5.7;
            }
            return y;
        },
        bianDongShow: function () {
            this.current++;
            this.setProcessValue(this.current / 6);
            this.$refs['bianDong'].classList.value = "badge show";
            setTimeout(() => { this.bianDongImageShow(); }, this.badgeMs);
        },
        bianDongImageShow: function () {
            this.current++;
            this.setProcessValue(this.current / 6);
            this.setImageXY('bianDongImage', this.order.data.bianDongIndex);
            setTimeout(() => { this.tuPoShow(); }, this.ms);
        },
        setImageXY: function (ref, index) {
            this.$refs[ref].style = "transform: translateX(" + this.getX(index) + "rem) translateY(" + this.getY(index) + "rem) scale(0.15);";
        },
        tuPoShow: function () {
            this.current++;
            this.setProcessValue(this.current / 6);
            this.$refs['tuPo'].classList.value = "badge show";
            setTimeout(() => { this.tuPoImageShow(); }, this.badgeMs);
        },
        tuPoImageShow: function () {
            this.current++;
            this.setProcessValue(this.current / 6);
            this.setImageXY('tuPoImage', this.order.data.tuPoIndex);
            setTimeout(() => { this.jiYuShow(); }, this.ms);
        },
        jiYuShow: function () {
            this.current++;
            this.setProcessValue(this.current / 6);
            this.$refs['jiYu'].classList.value = "badge show";
            setTimeout(() => { this.jiYuImageShow(); }, this.badgeMs);
        },
        jiYuImageShow: function () {
            this.current++;
            this.setProcessValue(this.current / 6);
            this.setImageXY('jiYuImage', this.order.data.jiYuIndex);
            this.hideProcess(false);
            this.showPayButton();
        },
        show: function () {
            this.$refs['info'].classList.value = "show";
            for (let i = 0; i < 6; i++) {
                this.$refs['info' + i].classList.value = "show";
            }
            for (let i = 0; i < this.order.data.pan.length; i++) {
                this.$refs['gong' + i].classList.value = "show";
                this.$refs['name' + i].classList.value = "name show";
                this.$refs['stars' + i].classList.value = "stars show";
            }
            this.$refs['bianDong'].classList.value = "badge show";
            this.setImageXY('bianDongImage', this.order.data.bianDongIndex);
            this.$refs['tuPo'].classList.value = "badge show";
            this.setImageXY('tuPoImage', this.order.data.tuPoIndex);
            this.$refs['jiYu'].classList.value = "badge show";
            this.setImageXY('jiYuImage', this.order.data.jiYuIndex);
            this.showPayButton();
        },
        showPayButton: function () {
            this.isShowPayButton = true;
            this.checkPayID = setTimeout(() => { this.checkPay(); }, 3000);
        },
        checkPay: function () {
            let params = {
                oid: this.$route.query.oid
            }
            let that = this;
            this.$api.post('order/checkPay', params).then(function (res) {
                if (res.data.success) {
                    let data = res.data.data
                    if (data.is_paid) {
                        window.location.href = data.redirect_url;
                    } else {
                        that.checkPayID = setTimeout(() => { that.checkPay(); }, 3000);
                    }
                } else if (res.data.err_code) {
                    alert(res.data.err_msg);
                    that.checkPayID = setTimeout(() => { that.checkPay(); }, 3000);
                }
            }).catch(function (res) {
                console.log(res);
                that.checkPayID = setTimeout(() => { that.checkPay(); }, 3000);
            })
        },
        initUI() {
            let html, xing;
            for (let i = 0; i < this.order.data.pan.length; i++) {
                this.$refs['name' + i].innerHTML = this.order.data.pan[i].gong;
                this.$refs['tgdz' + i].innerHTML = this.order.data.pan[i].tianGan + this.order.data.pan[i].diZhi;
                html = '';
                for (let j = 0; j < this.order.data.pan[i].stars.length; j++) {
                    xing = this.order.data.pan[i].stars[j];
                    html += '<li>' + xing['name'];
                    if (xing['siHua'] != undefined) {
                        html += '<u>' + xing['siHua'] + '</u>';
                    }
                    html += '</li>';
                }
                this.$refs['stars' + i].innerHTML = html;
            }
            let oid = localStorage.getItem("oid");
            if (oid != this.$route.query.oid) {
                localStorage.setItem("oid", this.$route.query.oid);
                this.play();
            } else {
                this.show();
            }
        },
        showProcess: function (text) {
            this.isShowProcess = true;
            this.processText = text;
            this.processValue = '0%';
        },
        setProcessValue: function (value) {
            this.processValue = (value * 100) + '%';
        },
        hideProcess: function () {
            this.isShowProcess = false;
        },
        showCountdownText: function () {
            let now = new Date().getTime();
            let diffMs = this.order.expired - now;
            if (diffMs < 0) {
                diffMs = 0;
            }
            let ms = Math.floor(diffMs / 10) % 100;
            let sec = Math.floor(diffMs / 1000) % 60;
            let min = Math.floor(diffMs / (1000 * 60));
            let text = "";
            if (min < 10) {
                text += "0";
            }
            text += min + ":";
            if (sec < 10) {
                text += "0";
            }
            text += sec + ":";
            if (ms < 10) {
                text += "0";
            }
            text += ms;
            this.countdownText = text;
            if (diffMs == 0) {
                this.destroyInterval();
            }
        }
    },
    destroyed: function () {
        this.destroyCheckPay();
        this.destroyInterval();
    },
    mounted() {
        document.title = '生辰综合详批';
        let params = {
            oid: this.$route.query.oid
        }
        let that = this;
        this.$api.post('order/info', params).then(function (res) {
            if (res.data.success) {
                that.order = res.data.data.order
                that.payChannels = res.data.data.pay_channels
                that.checkedPayChannelCode = Object.keys(res.data.data.pay_channels)[0]
                that.tipsText = that.tips[res.data.data.order.data.d % 14]
                that.initUI()
            } else if (res.data.err_code) {
                alert(res.data.err_msg);
            }
        }).catch(function (res) {
            console.log(res);
        })
    },
    data() {
        return {
            ms: 300,
            badgeMs: 1200,
            order: {
                data: {}
            },
            current: 0,
            paytype: 1,
            isShowProcess: false,
            processText: '',
            processValue: '',
            isShowPayButton: false,
            isShowPayPopup: false,
            expired: null,
            countdownText: '00:00:00',
            intervalID: null,
            checkPayID: null,
            masterName: process.env.VUE_APP_MASTER_NAME,
            masterImage: process.env.VUE_APP_MASTER_IMAGE_PATH,
            tips: [
                "发现你敢想敢干有冲劲，朋友多重义气，易因错误信任朋友兄弟而产生事业问题。",
                "发现你有才华，思维活跃头脑灵活，但是易行动力弱，易流于空想而错失好的机遇。",
                "发现你有魄力，责任心强，桃花较旺，易因桃花或经济方面而事业问题",
                "发现你有冲劲敢想敢干，一生易起落大，易因性格和人际问题而产生事业阻碍。",
                "发现你行动力强，有好胜心，易因冲动决策或亲友等方面问题而导致事业失利。",
                "发现你事业心强，性格偏犹豫谨慎，考虑问题周到细致，但是内心缺乏安全感，易迟疑犹豫而错失好的事业机遇。",
                "发现你事业有好胜心易有起落，倔强易冲动，易因人际方面问题而产生事业受阻。",
                "发现你朋友人缘不错，容易成为好好先生，易因错误信任朋友而导致事业阻碍。",
                "发现你偏内向保守谨慎，事业心虽强但是机遇不足贵人不足，易因迟疑犹豫而错失良机。",
                "发现你情感细腻，想法天马行空，但是却易流于空想，易因迟疑拖延行动力弱等问题而错失好的事业机遇。",
                "发现你朋友人缘不错，往往吃软不吃硬，易因错误相信他人而产生事业问题。",
                "发现你为人心善，贵人人缘运佳，但是易因朋友或家庭方面问题而影响财富发展。",
                "发现你为人心善有同情心，事业适合稳定，易受到家庭或者环境影响。",
                "发现你求财方式多样，小财不断，大财不易留住，易因情感或者身体问题影响事业发展。"
            ],
        };
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/ziwei/sass/pay.scss';
</style>