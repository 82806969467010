<template>
  <component :is="currentComponent"></component>
</template>

<script>
import YinyuancsIndex from './yinyuancs/Index.vue';
import BazijpIndex from './bazijp/Index.vue';
import Bazi2Index from './bazi2/Index.vue';
import ZiweiIndex from './ziwei/Index.vue';
import ScxpIndex from './scxp/Index.vue';
import HehunIndex from './hehun/Index.vue';
import BaziIndex from './bazi/Index.vue';
import HistoryIndex from './history/Index.vue';
import Hehun2Index from './hehun2/Index.vue';
import Hehun3Index from './hehun3/Index.vue';
import ScysIndex from './scys/Index.vue';

export default {
  name: 'Index',
  data() {
    return {
      currentComponent: null
    };
  },
  mounted() {
    this.loadComponentFromQuery()
  },
  methods: {
    loadComponentFromQuery() {
      const queryParams = this.$route.query
      const componentKey = queryParams.ac
      switch (componentKey) {
        case 'scxp':
          this.currentComponent = ScxpIndex
          break
        case 'ziwei':
          this.currentComponent = ZiweiIndex
          break
        case 'bazijp':
          this.currentComponent = BazijpIndex
          break
        case 'yinyuancs':
          this.currentComponent = YinyuancsIndex
          break
        case 'hehun':
          this.currentComponent = HehunIndex
          break
        case 'bazi':
          this.currentComponent = BaziIndex
          break
        case 'bazi2':
          this.currentComponent = Bazi2Index
          break
        case 'history':
          this.currentComponent = HistoryIndex
          break
        case 'hehun2':
          this.currentComponent = Hehun2Index
          break
        case 'hehun3':
          this.currentComponent = Hehun3Index
          break
        case 'scys':
          this.currentComponent = ScysIndex
          break
        default:
          this.currentComponent = null
      }
    },
  },
  watch: {
    '$route'() {
      this.loadComponentFromQuery()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
</style>