<template>
    <div div class="page page--shadow page--bazi2-index">
        <HongbaoDialog ref="hongbaoDialog" />
        <ThreePart :headingImage="require('@/assets/bazi2/images/img_index_02.jpg')"
            componentClass="margin-top-l margin-bottom-m margin-horizontal-m"
            :contentImage="require('@/assets/bazi2/images/img_index_03.jpg')"
            :footingImage="require('@/assets/bazi2/images/img_index_04.jpg')">
            <template #title>
                <h3 class="three-part-title" :style="{ color: '#8D3301' }">购买测算后你将获得</h3>
            </template>
            <div class="padding-vertical-l padding-horizontal-l">
                <img width="100%" src="@/assets/bazi2/images/img_index_09.png">
                <p class="padding-vertical-l">2025年为时代转折之年，属相为马的你行运 已有变动之相，命局中将有几个关键时间点值得注意...
                </p>
                <PriceWithCountdown :orderInfo="orderInfo" componentClass="margin-bottom-l" />
                <PayChannels @orderInfo="handleOrderInfo" />
            </div>
        </ThreePart>
        <ThreePart :headingImage="require('@/assets/bazi2/images/img_index_02.jpg')"
            componentClass="margin-top-l margin-bottom-m margin-horizontal-m" 
            :contentImage="require('@/assets/bazi2/images/img_index_03.jpg')"
            :footingImage="require('@/assets/bazi2/images/img_index_04.jpg')">
            <template #title>
                <h3 class="three-part-title" :style="{ color: '#8D3301' }">快人一步，流年运势抢先知！</h3>
            </template>
            <div class="padding-vertical-l padding-horizontal-l">
                <img width="100%" src="@/assets/bazi2/images/img_index_10.jpg">
                <img width="100%" src="@/assets/bazi2/images/img_index_11.jpg">
                <img width="100%" src="@/assets/bazi2/images/img_index_12.jpg">
                <img width="100%" src="@/assets/bazi2/images/img_index_13.jpg">
                <img width="100%" src="@/assets/bazi2/images/img_index_14.jpg">

            </div>
        </ThreePart>
    </div>
</template>

<script>
import ThreePart from '@/components/ThreePart.vue'
import PriceWithCountdown from '@/components/PriceWithCountdown.vue'
import PayChannels from '@/components/PayChannels.vue'
import HongbaoDialog from '@/components/HongbaoDialog.vue'

export default {
    name: 'Bazi3Pay',
    components: {
        ThreePart,
        PriceWithCountdown,
        PayChannels,
        HongbaoDialog
    },
    data() {
        return {
            orderInfo: {}
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.$refs.hongbaoDialog.getCanLeave()) {
            next(); // 允许离开
        } else {
            next(false); // 阻止离开
        }
    },
    methods: {
        handleOrderInfo(data) {
            this.orderInfo = data
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/bazi2/sass/pay.scss';
</style>