<template>
  <div
    div
    class="page page--shadow page--hehun3-pay padding-bottom-l"
    :style="{ backgroundColor: '#f48bb4' }"
  >
    <HongbaoDialog ref="hongbaoDialog" />
    <ThreePart
      :headingImage="require('@/assets/hehun3/images/img_index_01.jpg')"
      componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
      :contentImage="require('@/assets/hehun3/images/img_index_02.jpg')"
      :footingImage="require('@/assets/hehun3/images/img_index_03.jpg')"
    >
      <template #title>
        <h3 class="padding-top-m text-size-m" :style="{ color: '#ffffff' }">
          你们的合婚书已生成
        </h3>
      </template>
      <div class="padding-vertical-l padding-horizontal-l">
        <img width="100%" src="@/assets/hehun3/images/img_index_19.jpg" class="margin-bottom-m" />
        <div class="padding-horizontal-m">
          <PriceWithCountdown :orderInfo="orderInfo" componentClass="margin-bottom-l" />
          <PayChannels @orderInfo="handleOrderInfo" />
        </div>
      </div>
    </ThreePart>
    <ThreePart
      :headingImage="require('@/assets/hehun3/images/img_index_01.jpg')"
      componentClass="margin-top-l margin-bottom-l margin-horizontal-l"
      :contentImage="require('@/assets/hehun3/images/img_index_02.jpg')"
      :footingImage="require('@/assets/hehun3/images/img_index_03.jpg')"
    >
      <template #title>
        <h3 class="padding-top-m text-size-m" :style="{ color: '#ffffff' }">
          解锁后将获得以下内容
        </h3>
      </template>
      <div class="padding-vertical-l padding-horizontal-l">
        <img width="100%" src="@/assets/hehun3/images/img_index_20.jpg" />
        <img width="100%" src="@/assets/hehun3/images/img_index_21.jpg" />
        <img width="100%" src="@/assets/hehun3/images/img_index_22.jpg" />
        <img width="100%" src="@/assets/hehun3/images/img_index_23.jpg" />
      </div>
    </ThreePart>
  </div>
</template>

<script>
import ThreePart from "@/components/ThreePart.vue";
import PriceWithCountdown from "@/components/PriceWithCountdown.vue";
import PayChannels from "@/components/PayChannels.vue";
import HongbaoDialog from "@/components/HongbaoDialog.vue";

export default {
  name: "Hehun3Pay",
  components: {
    ThreePart,
    PriceWithCountdown,
    PayChannels,
    HongbaoDialog,
  },
  data() {
    return {
      orderInfo: {},
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.hongbaoDialog.getCanLeave()) {
      next(); // 允许离开
    } else {
      next(false); // 阻止离开
    }
  },
  methods: {
    handleOrderInfo(data) {
      this.orderInfo = data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/hehun3/sass/pay.scss";
</style>
