<template>
    <div class="hongbao-dialog">
        <div class="hongbao-container" v-if="hongbaoIndex == 0">
            <div class="wrapper">
                <div class="hongbao1">
                    <div class="amount">
                        &yen;<b>{{ getHongbaoAmount(0) }}</b>元
                    </div>
                    <div class="pay_channels">
                        <div v-for="(item, code) in payChannels" :key="code"
                            :class="{ 'pay_channel': true, active: code == payChannelCode }"
                            @click="handlePayChannel(code)">
                            <img :src="require(`@/assets/hehun/images/floating_bottom_${code}.png`)" class="icon"
                                :alt="`${code} payment`">
                            {{ item.name }}
                        </div>
                    </div>
                    <button type="button" @click="hongbaoPay(0)">立即解锁</button>
                    <p class="notes">仅需{{ getHongbaoPayAmount(0) }}元即可解锁报告</p>
                </div>
                <div class="close">
                    <img src="@/assets/yinyuancs/images/close.png" alt="Close" @click="closeHongbao(0)">
                </div>
            </div>
        </div>
        <div class="hongbao-container" v-else-if="hongbaoIndex == 1">
            <div class="wrapper">
                <div class="hongbao2">
                    <div class="amount">
                        &yen;<b>{{ getHongbaoAmount(1) }}</b>元
                    </div>
                    <p class="notes">仅需{{ getHongbaoPayAmount(1) }}元即可解锁报告</p>
                    <div class="pay_channels">
                        <div v-for="(item, code) in payChannels" :key="code"
                            :class="{ 'pay_channel': true, active: code == payChannelCode }"
                            @click="handlePayChannel(code)">
                            <img :src="require(`@/assets/hehun/images/floating_bottom_${code}.png`)" class="icon"
                                :alt="`${code} payment`">
                            {{ item.name }}
                        </div>
                    </div>
                    <button type="button" @click="hongbaoPay(1)">立即解锁</button>
                </div>
                <div class="close">
                    <img src="@/assets/yinyuancs/images/close.png" alt="Close" @click="closeHongbao(1)">
                </div>
            </div>
        </div>
        <div class="hongbao-container" v-else-if="hongbaoIndex == 2">
            <div class="wrapper">
                <div class="hongbao3">
                    <div class="amount">
                        &yen;<b>{{ getHongbaoAmount(2) }}</b>
                    </div>
                    <p class="notes">仅需{{ getHongbaoPayAmount(2) }}元即可解锁报告</p>
                    <div class="pay_channels">
                        <div v-for="(item, code) in payChannels" :key="code"
                            :class="{ 'pay_channel': true, active: code == payChannelCode }"
                            @click="handlePayChannel(code)">
                            <img :src="require(`@/assets/hehun/images/floating_bottom_${code}.png`)" class="icon"
                                :alt="`${code} payment`">
                            {{ item.name }}
                        </div>
                    </div>
                    <input type="image" @click="hongbaoPay(2)" :src="require('@/assets/hehun/images/hongbao3_btn.png')">
                </div>
                <div class="close">
                    <img src="@/assets/yinyuancs/images/close.png" alt="Close" @click="closeHongbao(2)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'HongbaoDialog',
    computed: {
        ...mapState(['orderInfo', 'payChannels', 'payChannelCode', 'hongbaoAmounts'])
    },
    data() {
        return {
            hongbaoIndex: -1
        }
    },
    methods: {
        ...mapMutations(['setPayChannelCode']),
        getCanLeave() {
            if (this.hongbaoIndex < this.hongbaoAmounts.length - 1) {
                this.hongbaoIndex++
                return false
            } else {
                return true
            }
        },
        handlePayChannel(code) {
            this.setPayChannelCode(code);
        },
        getHongbaoPayAmount(index) {
            var ret_val = this.orderInfo.money - this.hongbaoAmounts[index]
            if (ret_val <= 0) {
                ret_val = 0.01
            }
            return ret_val.toFixed(2)
        },
        getHongbaoAmount(index) {
            return this.hongbaoAmounts[index]
        },
        closeHongbao(num) {
            if (num >= this.hongbaoAmounts.length - 1) {
                this.hongbaoIndex = this.hongbaoAmounts.length
            } else {
                this.hongbaoIndex = num + 1
            }
        },
        hongbaoPay(hongbaoIndex) {
            location.href = this.payChannels[this.payChannelCode].pay_url + '&hongbao_amount=' + this.hongbaoAmounts[hongbaoIndex];
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/hongbao-dialog.scss';
</style>