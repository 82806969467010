var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page page--shadow page--hehun-generating"},[_c('TabProgress',{attrs:{"tabItems":[
        {
            name: '生辰信息',
            values: ['正在录入生辰信息', '正在写入生辰信息']
        },
        {
            name: '感情运数',
            values: ['正在检测感情状况', '正在分析你的正桃花、烂桃花', '正在计算你的感情指数']
        },
        {
            name: '原局姻缘',
            values: ['正在检测你的婚前、配偶性格', '正在分析你的姻缘状况', '正在分析你的婚配情况']
        }
    ]},on:{"progressFinish":_vm.handleTabProgressFinish}},[_c('img',{attrs:{"width":"100%","src":require("@/assets/hehun/images/pay_header.png"),"alt":"Banner"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }