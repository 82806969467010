<template>
    <div class="fixed-links">
        <a class="link" :href="liveChatUrl" target="_blank">
            <img src="@/assets/yinyuancs/images/cs.png" alt="Online service">客服
        </a>
        <a class="link" :href="reportIssusUrl" target="_blank">
            <img src="@/assets/yinyuancs/images/complain.png" alt="complain">投诉
        </a>
    </div>
</template>

<script>
export default {
    name: 'FixedLinks',
    props: {
        textColor: {
            type: String,
            default: "rgba(255, 255, 255, 0.5)"
        },
        bgColor: {
            type: String,
            default: "rgba(0, 0, 0, 0.5)"
        }
    },
    data() {
        return {
            liveChatUrl: process.env.VUE_APP_LIVE_CHAT_URL,
            reportIssusUrl: process.env.VUE_APP_REPORT_ISSUS_URL,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/fixed-links.scss';
</style>
